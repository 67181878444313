import {GridColDef, GridOverlay, GridToolbar} from "@mui/x-data-grid";
import {TextField, Tooltip, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {formatter} from "../../../constants/Constants";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import React, {useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {DataGridPro} from "@mui/x-data-grid-pro";
import { StylesContext } from "../../../providers/StylesProvider";
import {ShipmentType} from "../../../types/ShipmentType";
import TreeSourceLoad from "../../Loaders/TreeSourceLoad";
import {useHistory} from "react-router";
import Axios from "axios";
import {datadogLogs} from "@datadog/browser-logs";
import {CustomerContext} from "../../../providers/CustomerProvider";

export type ShipmentsGridProps = {
    showSearchBar: boolean;
    acceptableStatuses: string[];
}

const ShipmentsGrid = (props: ShipmentsGridProps) => {
    const { acceptableStatuses } = props;
    const { isDesktop } = useContext(StylesContext);
    const { parentCustomerName } = useContext(CustomerContext);
    const history = useHistory();

    const [ordersLoading, setOrdersLoading] = React.useState<boolean>(true);
    const [ordersError, setOrdersError] = React.useState<boolean>(false);
    const [orderErrorMessage, setOrderErrorMessage] = React.useState<string>("");

    const [searchString, setSearchString] = React.useState<string>("");
    const [shipments, setShipments] = React.useState<ShipmentType[]>([]);
    const [filteredShipments, setFilteredShipments] = React.useState<ShipmentType[]>([]);
    const mountedRef = useRef(true);

    const view = props.acceptableStatuses.length === 0 ? "all" : "in-progress";

    const toolTipStatusMessage = (status: string) => {
        switch (status) {
            case "COMMITTED":
                return "On sales order.";
            case "PULLING":
                return "Currently being loaded on a delivery truck.";
            case "SHIPPED":
                return "The order has left our dock.";
            case "OPEN":
                return "This is a quote/estimate.";
            case "INVOICED":
                return "This order is complete.";
            default:
                return "";
        }
    };

    const getShipments = useCallback(async () => {
        if(!parentCustomerName  || !history) {
            return ; //wait for the parent customer name to be loaded
        }

        const statusString = acceptableStatuses.map(status => `status=${status}`).join("&");
        const prefacedStatusString = statusString ? `&${statusString}` : "";

        try {
            const config = {
                headers: {
                    authorization: localStorage.getItem("token"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Cache-Control": "no-store",
                },
            };

            let encodedCustomerName = encodeURIComponent(parentCustomerName ?? "");

            let res = await Axios.get(
                `/api/shipments?selectedCustomerName=${encodedCustomerName}${prefacedStatusString}`,
                config
            );
            let formattedShipments = res.data.map((x: any) => {
                let newDate = x.landDate ? new Date(x.landDate) : null;
                let fullId = x.orderID + "-" + x.shipmentID;
                return { ...x, landDate: newDate, fullId: fullId };
            });

            if (!mountedRef.current) {
                return null;
            }

            datadogLogs.logger.info("User loaded the order management page.");

            setShipments(formattedShipments);
            setFilteredShipments(formattedShipments);
            setOrdersLoading(false);
        } catch (error: any) {
            if (!mountedRef) return null;

            datadogLogs.logger.info(
                "We ran into an error while loading the order list",
                { errorData: error.response.data }
            );

            setOrdersLoading(false);
            setOrdersError(true);
            setOrderErrorMessage(
                error.response.data && typeof error.response.data === "string"
                    ? error.response.data
                    : "Error Loading Data"
            );
            if (
                error.response.data ===
                "The token was expected to have 3 parts, but got 1."
            ) {
                history.go(0);
            }
            return error;
        }
    }, [history, acceptableStatuses, parentCustomerName]);

    useEffect(() => {
        mountedRef.current = true;

        getShipments();
        return () => {
            mountedRef.current = false;
        };

    }, [getShipments]);


    const shipmentColumns: GridColDef[] = useMemo(() => [
        {
            field: "id",
            headerName: "Order #",
            width: 125,
            renderCell: (params) => {
                return (
                    <Tooltip title="Order # and Shipment #">
                        <Link
                            to={`/order-management/order/${params.row.orderID}/shipment/${params.row.shipmentID}`}
                        >
                            {params.row.orderID + "-" + params.row.shipmentID}
                        </Link>
                    </Tooltip>
                );
            },
        },
        {
            field: "jobLine",
            headerName: "Job Line",
            width: 200,
        },

        {
            field: "jobName",
            headerName: "Job Name",
            width: 250,
        },
        {
            field: "grandTotal",
            headerName: "Total Cost",
            width: 150,
            renderCell: (params) => {
                return <div>{formatter.format(params.row.grandTotal)}</div>;
            },
        },
        {
            field: "shipmentBalance",
            headerName: "Balance Due",
            width: 150,
            renderCell: (params) => {
                let shipmentDoc = params.row;
                let hasBalance = shipmentDoc.shipmentBalance > 0;
                let dueDate = shipmentDoc.dueDate
                    ? Date.parse(shipmentDoc.dueDate)
                    : null;
                let today = new Date().getTime();
                let pastDue = hasBalance && dueDate !== null && dueDate < today;

                if (shipmentDoc.shipmentBalance && pastDue) {
                    return (
                        <div
                            style={{
                                border: "red",
                                borderStyle: "solid",
                                padding: "6px",
                                borderRadius: "7px",
                                borderWidth: "thin",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <WarningIcon color="error" />
                            <Typography variant="body2" style={{ color: "red" }}>
                                {formatter.format(params.row.shipmentBalance)}
                            </Typography>
                        </div>
                    );
                } else if (shipmentDoc.shipmentBalance) {
                    return (
                        <div
                            style={{
                                border: "orange",
                                borderStyle: "solid",
                                padding: "6px",
                                borderRadius: "7px",
                                borderWidth: "thin",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <WarningIcon color="warning" />
                            <Typography variant="body2" style={{ color: "black" }}>
                                {formatter.format(params.row.shipmentBalance)}
                            </Typography>
                        </div>
                    );
                } else if ("INVOICED" === shipmentDoc.status && shipmentDoc.dueDate) {
                    return (
                        <div
                            style={{
                                border: "green",
                                borderStyle: "solid",
                                padding: "6px",
                                borderRadius: "7px",
                                borderWidth: "thin",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <DoneIcon color="success" />
                            <Typography variant="body2" style={{ color: "green" }}>
                                Paid
                            </Typography>
                        </div>
                    );
                } else if ("INVOICED" === shipmentDoc.status && !shipmentDoc.dueDate) {
                    return <div>Syncing...</div>;
                } else {
                    return <div>N/A</div>;
                }
            },
        },
        {
            field: "dueDate",
            headerName: "Due Date",
            width: 125,
            type: "date",
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: (params) => {
                return (
                    <Tooltip title={toolTipStatusMessage(params.row.status)}>
                        <span>{params.row.status}</span>
                    </Tooltip>
                );
            },
        },
        {
            field: "landDate",
            headerName: "Land Date",
            width: 125,
            type: "date",
        },
    ], []);

    const NoOrdersMessage = () => {
        if(view === "in-progress") {
            return (
                <GridOverlay >
                    <div style={{ padding: 10, textAlign: "center" }}>
                        <Typography variant="h6">When you commit a shipment, it will show up here.</Typography>
                    </div>
                </GridOverlay>
            );
        } else {
            return (
                <GridOverlay >
                    <div style={{ padding: 10, textAlign: "center" }}>
                        {/*DEV NOTE: the <a> tag doesn't actually appear to do anything for us. However, it gives the user a hint that it's clickable.
                when they click on it, it's actually the onClick attribute of the div containing the data grid that runs. But from the users' perspective 'it worked' */}
                        <Typography variant="h6">It looks like you haven't ordered anything yet.
                            Check out our <a href={"/products"}>products</a> page to get started!</Typography>
                    </div>
                </GridOverlay>
            );
        }
    };

    const handleSearchSubmit = (value: string) => {
        setSearchString(value);

        value = value.toLowerCase();
        const foo = shipments.filter(
            (x) =>
                x?.fullId?.includes(value) ||
                x?.jobLine?.toLowerCase().includes(value) ||
                x?.jobName?.toLowerCase().includes(value) ||
                x?.status?.toLowerCase().includes(value) ||
                x?.grandTotal?.toString().includes(value) ||
                x?.shipmentBalance?.toString().includes(value)
        );
        setFilteredShipments(foo);
    };

    const renderSearchBar = () => {
        if(props.showSearchBar) {
            return <div style={{margin: 10, width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%"}}>
                    <TextField
                        label="Search for an order"
                        onChange={(event: any) => handleSearchSubmit(event.target.value)}
                        value={searchString}
                        id="standard-basic"
                        variant="outlined"
                        style={{width: "100%", marginRight: 20}}
                    />
                </div>
            </div>
        } else {
            return <></>
        }
    }

    const renderShipments = () => {
        if (ordersLoading) {
            return <TreeSourceLoad message="Loading Orders" />;
        }
        if (ordersError) {
            return <h1>{orderErrorMessage}</h1>;
        }

        return (
            <div onClick={() => filteredShipments.length === 0 ? history.push("/products") : {}}>
                <DataGridPro
                    style={{ height: view === "all" ? "600px" : "215px" }}
                    rows={filteredShipments}
                    columns={shipmentColumns}
                    components={{
                        Toolbar: view === "all" ? GridToolbar : undefined,
                        NoRowsOverlay: NoOrdersMessage,
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                jobLine: false,
                            },
                        },
                    }}
                    onRowClick={(x) => {
                        history.push(
                            `/order-management/order/${x.row.orderID}/shipment/${x.row.shipmentID}`
                        );
                    }}
                    hideFooter={view === "in-progress"}
                />
            </div>
        );
    };

    return (
        <>
            {renderSearchBar()}
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                <div style={{ flexGrow: isDesktop ? 1 : 2, margin: isDesktop ? 10 : 0}} >
                    {renderShipments()}
                </div>
            </div>
        </>
    );
}

export default ShipmentsGrid