import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from 'react';
import {Box, Card, CardHeader, Checkbox, Container, TextField, Typography,} from '@mui/material';
import {StylesContext} from "../../../providers/StylesProvider";
import {useHistory, useParams} from "react-router";
import {DEFAULT_ORDER_INFO, OrderEO} from "./OrderInformation";

import SpecificOrderSnackbar from "./SpecificOrderSnackbar";

import Alert from "@mui/material/Alert";
import PaymentMethodDropDown from "../../guiComponents/payments/PaymentMethodDropDown";
import AccountsReceivableInfo, {RACHEL_SHOCKEY} from "../../guiComponents/payments/AccountsReceivableSpecialist";
import {
    BankAccountWithNickname,
    CardWithNickname,
    PaymentMethod,
    TYPE_TERMS_ACCOUNT
} from "../../../types/CardDataType";
import {NEW_BANK_ACCOUNT, NEW_CARD} from "../../guiComponents/payments/PaymentCardConstants";
import ShipmentSummaryCard from "./checkout/ShipmentSummaryCard";
import {ShipmentEO} from "./ShipmentEO";
import PaymentSummaryCard from "../../guiComponents/payments/PaymentSummaryCard";
import NewCreditCard from "../../guiComponents/payments/NewCreditCard";
import NewBankAccount from "../../guiComponents/payments/NewBankAccount";
import ReviewOrderInfoCard from "./checkout/ReviewOrderInfoCard";
import useOrderAPI from "../../../hooks/useOrderAPI";
import usePaymentsAPI from '../../../hooks/usePaymentsAPI';
import {CustomerContext} from "../../../providers/CustomerProvider";
import {AccountEvent, TYPE_INVOICE} from "../../guiComponents/payments/PaySpecificInvoicesCard";
import {LOGISTICS_WILL_CALL} from "../../../constants/Constants";
import {LandDateResponse} from "./checkout/LandDateResponse";
import BetaTag from "../../guiComponents/BetaTag";
import {OrderContext} from "../../../providers/OrderProvider";
import {datadogLogs} from "@datadog/browser-logs";
import {PaymentReceipt} from "../../guiComponents/payments/ThankYouPage";
import CheckoutValidator from "../../authComponents/registration/validators/CheckoutValidator";
import {camelCaseToSplitWords} from "../../authComponents/registration/validators/ValidationRule";

export type OrderParams = {
    orderId: string,
}

const Checkout = () => {
    const { navBarHeight, isDesktop } = useContext(StylesContext);
    const { savePaymentMethod, parentCustomerName } = useContext(CustomerContext);
    const { removeOrderFromAddToOptions } = useContext(OrderContext);
    const { getOrderDetails, postShipmentForFreight, getEarliestLandDate } = useOrderAPI()
    const { makePaymentRequest } = usePaymentsAPI();
    const history = useHistory();

    const { orderId } = useParams<OrderParams>()

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({...NEW_CARD, id: "Loading"});
    const [orderInformation, setOrderInformation] = useState<OrderEO>(DEFAULT_ORDER_INFO);
    const [otherInformation, setOtherInformation] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [movedOutLandDate, setMovedOutLandDate] = useState(false);

    const [processingCheckout, setProcessingCheckout] = useState(false);
    const [savePayMethod, setSavePayMethod] = useState(false);
    const [savingPaymentMethod, setSavingPaymentMethod] = useState(false);

    const [validator] = useState<CheckoutValidator>(new CheckoutValidator());

    const getErrorMessage = (fieldName: string) => {
        return validator.validateFieldIfTouched(orderInformation, fieldName);
    }

    const isInvalid = (fieldName: string) => {
        return getErrorMessage(fieldName) !== '';
    }

    const asAccountEvents = (shipments: ShipmentEO[]): AccountEvent[] => {
        //to be sure, these are thin events.... but they are sufficient for the payment processor :)
        return shipments.map((shipment) => {
            return {
                type: TYPE_INVOICE,
                dueDate: '',
                refNumber: `${orderId}-${shipment.id}`,
                poNumber: '',
                memo: '',
                balanceRemaining: shipment.total,
                url: ''
            } as AccountEvent;
        });
    }

    const takePayment = (recaptchaToken: string, totalAmount: number, processingFee: number, paymentNotes: string, selectedShipments: ShipmentEO[]) => {
        const shipmentAccountEvents = asAccountEvents(selectedShipments);

        return makePaymentRequest(paymentMethod, recaptchaToken, totalAmount, processingFee, paymentNotes, false, shipmentAccountEvents, setErrorMessage)
    }

    const checkout = (orderInformation: OrderEO, notes: string) => {
        return fetch(`/api/orders/${orderId}/commit`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({
                note: notes,
                otherInformation: otherInformation,
                ...orderInformation
            }),
        })
        .then((res) => {
            if (!res.ok) {
                return false;
            } else {
                return res.json();
            }
        })
    }

    const handleCheckoutResponse = (selectedShipments: ShipmentEO[], paymentResponse: PaymentReceipt | undefined, checkoutResponse: ShipmentEO[]) => {
        //if all uncommitted shipments were selected, lets assume all deliveries are now committed
        //I would like if we encouraged users to create new orders rather than add to a fully committed one
        const openOrNoAutoAddShipments = orderInformation.deliveries.filter((shipment) => shipment.status === "OPEN" || shipment.status === "NO_AUTO_ADD");
        if(selectedShipments.length === openOrNoAutoAddShipments.length) {
            removeOrderFromAddToOptions(Number(orderId));
        }

        if(paymentResponse as PaymentReceipt) {
            history.push({
                pathname: `/payments/thank-you`,
                state: { data: paymentResponse as PaymentReceipt }
            });
        } else {
            history.push({
                pathname: `/checkout/thank-you`,
                state: { data: checkoutResponse }
            });
        }
    }

    const handleSubmit = (recaptchaToken: string, totalAmount: number, processingFee: number, paymentNotes: string) => {
        const errors = validator.validateAll(orderInformation);

        if(Object.keys(errors).length > 0) {
            const invalidFields = Object.keys(errors).map((fieldName, index, array) => {
                const field = camelCaseToSplitWords(fieldName);
                return index === array.length - 1 && array.length > 1 ? `and ${field}` : field;
            }).join(", ");
            setErrorMessage(`Please supply the following fields: ${invalidFields}.`);
            return
        }

        setProcessingCheckout(true)

        const selectedShipments = orderInformation.deliveries.filter((shipment) => shipment.selected);
        const orderToCheckout: OrderEO = {
            ...orderInformation,
            deliveries: selectedShipments
        }

        if(savePayMethod && savePaymentMethod) {
            setSavingPaymentMethod(true);
            savePaymentMethod(paymentMethod)
                .then(() => {
                    setSavingPaymentMethod(false);
                });
        }

        if(paymentMethod.type === TYPE_TERMS_ACCOUNT) {
            checkout(orderToCheckout, paymentNotes)
                .then((data) => {
                    if(data as ShipmentEO[]) {
                        handleCheckoutResponse(selectedShipments, undefined, data as ShipmentEO[]);
                    } else {
                        setErrorMessage("We failed to commit your order! Please reach out it the problem persists.");
                    }
                }).finally(() => {
                    setProcessingCheckout(false);
                });
        } else {
            takePayment(recaptchaToken, totalAmount, processingFee, paymentNotes, selectedShipments)
                .then((paymentResponse) => {
                    //if payments was successful proceed to checkout
                    if(paymentResponse as PaymentReceipt) {
                        return checkout(orderToCheckout, paymentNotes)
                            .then((checkoutResponse) => {
                                console.log("Checkout response", checkoutResponse);

                                if(checkoutResponse as ShipmentEO[]) {
                                    console.log("Order committed successfully");
                                    handleCheckoutResponse(selectedShipments, paymentResponse, checkoutResponse);
                                } else {
                                    console.log("Failed to commit order");

                                    setErrorMessage("Your payment was successful, but we failed to commit your order. Please immediately reach out to Rachel or your rep!");
                                    return;
                                }
                            });
                    } else {
                        return Promise.resolve();
                    }
                }).finally(() => {
                    setProcessingCheckout(false);
                });
        }
    }

    const updateOrderInfo = (e: ChangeEvent<HTMLInputElement>) => {
        //this requires the element's name attribute to match the key in the OrderEO object
        const newOrderInfo = {...orderInformation, [e.target.name]: e.target.value};

        if(orderInformation.useDefaultLogistics) {
            const newDeliveries = newOrderInfo.deliveries.map((shipment) => {
                return {...shipment, landDate: newOrderInfo.defaultShippingDate, destination: newOrderInfo.defaultShippingAddress} as ShipmentEO;
            })
            setOrderInformation({...newOrderInfo, deliveries: newDeliveries});
        } else {
            setOrderInformation(newOrderInfo);
        }
    }

    const updateShipmentInfo = (id: number, newShipment: ShipmentEO) => {
        const newDeliveries = orderInformation.deliveries.map((shipment) => {
            if(shipment.id === id) {
                return {...newShipment, selected: newShipment.selected === undefined ? shipment.selected : newShipment.selected};
            }
            return shipment;
        });
        setOrderInformation({...orderInformation, deliveries: newDeliveries});
    }

    const renderShipments = () => {
        return orderInformation.deliveries.filter(s => s.status === "OPEN" || s.status === "NO_AUTO_ADD").map((shipment, index) => {
            return <ShipmentSummaryCard key={index}
                                        orderId={orderId}
                                        shipment={shipment}
                                        setShipmentSelected={updateShipmentInfo}
                                        useDefaultLogistics={orderInformation.useDefaultLogistics ?? true}
            />
        });
    }

    const getSubtotal = () => {
        return orderInformation.deliveries
                    .filter((shipment) => shipment.selected)
                    .reduce((acc, shipment) => {
                        return acc + shipment.total;
                    }, 0);
    }

    const postFreight = useCallback((updatedOrderInfo: OrderEO, updatedDefaultLogisticsFlag: boolean) => {
        if(updatedDefaultLogisticsFlag && parentCustomerName) {
            updatedOrderInfo.deliveries.forEach((shipment) => {
                if(shipment.type !== LOGISTICS_WILL_CALL && shipment.status === "OPEN") {
                    postShipmentForFreight(Number(orderId), shipment, parentCustomerName ?? "", false)
                        .then((response) => {
                            const newDeliveries = updatedOrderInfo.deliveries.map((delivery) => {
                                if (delivery.id === shipment.id) {
                                    return {...response, selected: delivery.selected};
                                }
                                return delivery;
                            });

                            setOrderInformation(priorValue => {
                                return {...priorValue, deliveries: newDeliveries};
                            });
                        })
                        .catch((error) => {
                            datadogLogs.logger.log("Failed to calculate freight for shipment", shipment, error);

                            const newDeliveries = updatedOrderInfo.deliveries.map((delivery) => {
                                if (delivery.id === shipment.id) {
                                    //basically this triggers the "Est. Pending" message bc our calculation failed
                                    return {...delivery, freight: 0};
                                }
                                return delivery;
                            });

                            setOrderInformation(priorValue => {
                                return {...priorValue, deliveries: newDeliveries};
                            });
                        });
                }
            });
        }
    }, [orderId, parentCustomerName, postShipmentForFreight]);

    const handleMinLandDate = useCallback((inMemOrder: OrderEO, landDateResponse: LandDateResponse) => {
        const minDate = new Date(landDateResponse.landDate);

        const shipDate = inMemOrder.defaultShippingDate ? new Date(inMemOrder.defaultShippingDate) : new Date();

        if(shipDate < minDate || !inMemOrder.defaultShippingDate) {
            setMovedOutLandDate(true);
            const formattedDate = minDate.toISOString().split("T")[0];

            //warn: don't delete this... it updates the in mem version that we use to post to the freight calculator
            inMemOrder.defaultShippingDate = formattedDate;
            inMemOrder.deliveries.filter(s => s.status === "OPEN" || s.status === "NO_AUTO_ADD").map((shipment) => {
                return shipment.landDate = formattedDate;
            })

            setOrderInformation(prevState => {
                const newDeliveries = prevState.deliveries.map((shipment) => {
                    return {...shipment, landDate: formattedDate} as ShipmentEO;
                });

                return {...prevState, defaultShippingDate: formattedDate, deliveries: newDeliveries};
            });
        }

    }, [setOrderInformation, setMovedOutLandDate]);

    useEffect(() => {
        if(!parentCustomerName || !orderId || !getOrderDetails || !postFreight || !getEarliestLandDate || !handleMinLandDate) {
            return;
        }

        getOrderDetails(orderId).then(
            (data) => {
                const newDeliveries = data.deliveries
                    .filter((shipment) => shipment.status === "OPEN" || shipment.status === "NO_AUTO_ADD")
                    .map((shipment) => {
                        return { ...shipment, landDate: data.defaultShippingDate, destination: data.defaultShippingAddress } as ShipmentEO;
                    });

                const nonWillCalls = newDeliveries.filter((shipment) => shipment.type !== LOGISTICS_WILL_CALL);
                const onlyWillCalls = nonWillCalls.length === 0;
                const defaultShipAddress = onlyWillCalls && newDeliveries.length > 0 ? "Will Call @ " + newDeliveries[0].vendor : data.defaultShippingAddress;

                const inMemOrder = {...data,
                    deliveries: newDeliveries,
                    onSiteContactName: "",
                    onSiteContactPhone: "",
                    useDefaultLogistics: true,
                    defaultShippingAddress: defaultShipAddress
                };
                setOrderInformation(inMemOrder);

                getEarliestLandDate(orderId, parentCustomerName ?? "")
                    .then((landDateResponse) => {
                        handleMinLandDate(inMemOrder, landDateResponse);
                        postFreight(inMemOrder, true);
                    });
            }
        );
    }, [getOrderDetails, parentCustomerName, postFreight, orderId, getEarliestLandDate, handleMinLandDate]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);

    useEffect(() => {
        setSavePayMethod(false);
    }, [paymentMethod.id]);

    const renderLandDateWarning = () => {
        if(movedOutLandDate) {
            return <Alert style={{marginTop: 10}} severity={"warning"}>We've updated the default land date to the next available time based on logistics.
                Please reach out to your rep with any questions.  </Alert>
        } else {
            return "";
        }
    }

    return (
            <Container style={{ paddingTop: navBarHeight }}>
                <h2>{`Checkout Order #${orderId} `}<BetaTag/></h2>
                <Alert severity={"info"}>
                    This is a new idea we're trying. If you have a simple order, you can checkout at your convenience.
                    We'll reach out within a business day to confirm we got your order and that everything is ready to go.
                    If your order is more complex, your sales rep will always be there to help you.
                </Alert>

                {renderLandDateWarning()}

                <ReviewOrderInfoCard orderInformation={orderInformation}
                                     updateOrderInfo={updateOrderInfo}
                                     orderId={Number(orderId)}
                                     useDefaultLogistics={orderInformation.useDefaultLogistics ?? true}
                                     setOrderInformation={setOrderInformation}
                                     postFreight={postFreight}
                                     isInvalid={isInvalid}
                                     getErrorMessage={getErrorMessage}
                />
                <Card style={{marginTop: 15}}>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <CardHeader title={"Which open shipments are you ready to commit?"}/>
                        <div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10, marginTop: 15}}>
                                <Typography variant={"body1"}>Use Default Logistics</Typography>
                                <Checkbox
                                    checked={orderInformation.useDefaultLogistics ?? true}
                                    onChange={(e) => {
                                        setOrderInformation(priorValue => {
                                            const newDeliveries = priorValue.deliveries.map((shipment) => {
                                                if(shipment.type === LOGISTICS_WILL_CALL) {
                                                    return {...shipment, landDate: orderInformation.defaultShippingDate} as ShipmentEO
                                                } else {
                                                    return {...shipment, landDate: orderInformation.defaultShippingDate, destination: orderInformation.defaultShippingAddress} as ShipmentEO;
                                                }

                                            })

                                            //timing :/
                                            const updatedOrderInfo = {...priorValue, deliveries: newDeliveries, useDefaultLogistics: e.target.checked};
                                            postFreight(updatedOrderInfo, e.target.checked);

                                            return updatedOrderInfo
                                        });
                                    }}
                                    name="myCheckbox"
                                    color="primary"
                                />
                            </div>
                        </div>
                    </div>

                    <Box style={{overflow: 'auto', maxHeight: 500, marginTop: 0, margin: 13}}>
                        {renderShipments()}
                    </Box>

                </Card>

                <Card style={{marginTop: 15}}>
                    <CardHeader title={"Is there anything else we should know?"}/>
                    <div style={{marginLeft: 15, marginRight: 15, marginBottom: 15}}>
                        <TextField multiline
                                   fullWidth
                                   rows={4}
                                   value={otherInformation}
                                   onChange={e => setOtherInformation(e.target.value)}
                        />
                    </div>
                </Card>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                    <div style={{width: "49%"}}>
                        <Card style={{height: "90%"}}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",

                                marginRight: "10px",
                                marginBottom: "10px",
                                width: "100%",
                                paddingBottom: "30px",

                            }}>
                                <PaymentMethodDropDown paymentMethod={paymentMethod}
                                                       setPaymentMethod={setPaymentMethod}
                                                       desktopWidth={"100%"}
                                                       includeTermsAccount={true}
                                />
                            </div>
                        </Card>
                    </div>
                    <div style={{width: "49%"}}>
                        <AccountsReceivableInfo padded={false} arSpecialist={RACHEL_SHOCKEY} title={"Need Help? Ask Me!"}/>
                    </div>
                </div>

                {paymentMethod.id === NEW_CARD.id ? <NewCreditCard setPaymentMethod={setPaymentMethod}
                                                                   paymentMethod={paymentMethod as CardWithNickname}
                                                                   saveCard={savePayMethod}
                                                                   setSaveCard={setSavePayMethod}
                                                                   padded={false} /> : ""}

                {paymentMethod.id === NEW_BANK_ACCOUNT.id ? <NewBankAccount setPaymentMethod={setPaymentMethod}
                                                                            paymentMethod={paymentMethod as BankAccountWithNickname}
                                                                            saveAccount={savePayMethod}
                                                                            setSaveAccount={setSavePayMethod}
                                                                            padded={false}/> : ""}

                <PaymentSummaryCard subtotal={getSubtotal()}
                                    credits={0}
                                    paymentMethod={paymentMethod}
                                    submitPayment={handleSubmit}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    loading={processingCheckout || savingPaymentMethod}
                                    padded={false}
                />

                <SpecificOrderSnackbar/>
                <div style={{margin: isDesktop ? 20 : 40}}></div>
            </Container>
    );
};

export default Checkout;