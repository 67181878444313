import {ShipmentEO} from "../ShipmentEO";
import {Card, Checkbox, TextField, Typography} from "@mui/material";
import {BoldTypography} from "../../../KeyValue";
import useDateUtil from "../../../../hooks/useDateUtil";
import {formatter, LOGISTICS_WILL_CALL} from "../../../../constants/Constants";
import React, {ChangeEvent, useContext} from "react";
import {CustomerContext} from "../../../../providers/CustomerProvider";
import useOrderAPI from "../../../../hooks/useOrderAPI";

export type ShipmentSummaryCardProps = {
    orderId: string;
    shipment: ShipmentEO;
    setShipmentSelected: (shipmentId: number, selected: ShipmentEO) => void;
    useDefaultLogistics: boolean;
}
const ShipmentSummaryCard = (props: ShipmentSummaryCardProps) => {
    const { shipment, orderId, useDefaultLogistics } = props;
    const { parentCustomerName } = useContext(CustomerContext);
    const { asDate } = useDateUtil();
    const orderAPI = useOrderAPI();

    const displayAddress = (shipment: ShipmentEO) => {
        if(shipment.type === LOGISTICS_WILL_CALL) {
            return `Will Call @ ${shipment.vendor}`;
        }

        return shipment.destination;
    }

    const displayFreight = (shipment: ShipmentEO) => {
        if(shipment.type === LOGISTICS_WILL_CALL) {
            return "Will Call";
        } else if(shipment.freight === 0) {
            return "Est. Pending";
        }
        return formatter.format(shipment.freight);
    }

    const postFreight = () => {
        if (shipment.type !== LOGISTICS_WILL_CALL) {
            orderAPI.postShipmentForFreight(Number(orderId), shipment, parentCustomerName ?? "", false)
                .then((updatedShipment) => {
                    props.setShipmentSelected(shipment.id, updatedShipment);
                });
        }


    }

    return <>
        <Card style={{ marginLeft: 2, marginRight: 2, marginBottom: '10px', paddingTop: 5, padding: 15 }}>
            <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                <div style={{display: "flex", flexDirection: "column", width: "30%"}}>
                    {/*promote style?*/}
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Checkbox
                            style={{padding: 0, marginRight: 8}}
                            checked={shipment.selected}
                            onChange={(e) => {
                                const newShipment = {...shipment, selected: e.target.checked};
                                props.setShipmentSelected(shipment.id, newShipment);
                            }}
                            name="shipmentSelectedChkbox"
                            color="primary"
                        />
                        <Typography variant={"body1"}>{`${orderId}-${shipment.id}`}</Typography>
                    </div>
                    <div style={{display: "flex", marginTop: 5,  alignContent: "center"}}>
                        <span style={{width: 32}}/>
                        <BoldTypography>Name:</BoldTypography><Typography variant={"body1"}>{shipment.name}</Typography>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "13%"}}>
                    <TextField
                        label="Requested Land Date"
                        name="landDate"
                        type="date"
                        variant={"standard"}
                        disabled={useDefaultLogistics}
                        value={asDate(shipment?.landDate) || ""}
                        InputProps={{
                            inputProps: {
                                min: new Date().toISOString().split("T")[0],
                                max: new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString().split("T")[0]
                            }
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const newDate = e.target.value;
                            const newShipment = {...shipment, landDate: newDate};

                            props.setShipmentSelected(shipment.id, newShipment);
                        }}
                        InputLabelProps={{shrink: true}}
                    />
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "20%"}}>
                    <TextField
                        variant="standard"
                        required
                        fullWidth
                        name="destination"
                        label="Shipping Address"
                        disabled={useDefaultLogistics || shipment.type === LOGISTICS_WILL_CALL}
                        value={displayAddress(shipment)}
                        onBlur={postFreight}
                        onChange={(e) => {
                            if(shipment.type !== LOGISTICS_WILL_CALL) {
                                const newShipment = {...shipment, destination: e.target.value};
                                props.setShipmentSelected(shipment.id, newShipment);
                            }
                        }}
                        multiline
                        rows={4}
                    />
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "20%"}}>
                    <div style={{display: "flex", marginTop: 5,  justifyContent: "space-between"}}>
                        <BoldTypography>Subtotal:</BoldTypography>
                        <Typography variant={"body1"}>{formatter.format(shipment.subtotal)}</Typography>
                    </div>
                    <div style={{display: "flex", marginTop: 5, justifyContent: "space-between"}}>
                        <BoldTypography>Freight:</BoldTypography>
                        <Typography variant={"body1"}>{displayFreight(shipment)}</Typography>
                    </div>
                    <div style={{display: "flex", marginTop: 5, justifyContent: "space-between"}}>
                        <BoldTypography>Tax:</BoldTypography>
                        <Typography variant={"body1"}>{formatter.format(shipment.salesTax)}</Typography>
                    </div>
                    <div style={{display: "flex", marginTop: 5, justifyContent: "space-between"}}>
                        <BoldTypography>Total:</BoldTypography>
                        <Typography variant={"body1"}>{formatter.format(shipment.total)}</Typography>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>

            </div>

        </Card>
    </>
}

export default ShipmentSummaryCard