import {CUSTOMER_NAME, formatter, LOGISTICS_WILL_CALL} from "../../../constants/Constants";
import IconButton from "@mui/material/IconButton";
import {PictureAsPdf} from "@mui/icons-material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import React from "react";
import {ShipmentEO} from "./ShipmentEO";
import {useHistory} from "react-router";
import usePDFDownloader from "../../../hooks/usePDFDownloader";
import DeleteIcon from "@mui/icons-material/Delete";
import {Tooltip} from "@mui/material";
import CancelShipmentDialog from "./CancelShipmentDialog";

export type ShipmentTableProps = {
    orderId: number,
    deliveries: ShipmentEO[],
    shipmentTotals: {[key: string]: number}
    onShipmentCanceled: (shipmentId: number) => void,
    maxStatusForLineItemsInDelivery: (deliveryId: number) => string,
}

const DeliveriesTable = (props: ShipmentTableProps) => {
    const {orderId, deliveries, shipmentTotals} = props;
    const { downloadPDF } = usePDFDownloader();
    const history = useHistory();

    const [shipmentIdToCancel, setShipmentIdToCancel] = React.useState<number | null>(null);
    const [cancelShipmentDialogOpen, setCancelShipmentDialogOpen] = React.useState(false);

    const customerFinalStatuses = new Set(["COMMITTED","PULLING", "SHIPPED", "INVOICED" ]);

    const handleShipmentSelection = (params: any) => {
        history.push(`/order-management/order/${orderId}/shipment/${params.row.id}`);
    }

    return (
        <>
            <DataGridPro
                rows={deliveries}
                columns={[
                    {field: 'id', headerName: 'ID', flex: .2,},
                    {field: 'vendor', headerName: 'Origin', flex: .6},
                    {field: 'status', headerName: 'Status', flex: .6},
                    {field: 'name', headerName: 'Name', flex: 1},
                    {
                        field: 'destination',
                        headerName: 'Destination',
                        flex: 1.5,
                        renderCell: (params) => {
                            if(params.row.type === LOGISTICS_WILL_CALL) {
                                return <div>Will Call @ {params.row.vendor}</div>
                            } else {
                                return <div>{params.value}</div>
                            }
                        }
                    },
                    {
                        field: 'landDate',
                        headerName: 'Land Date',
                        flex: .5,
                    },
                    {
                        field: 'subtotal',
                        headerName: 'Sub Total',
                        flex: .5,
                        align: "right",
                        headerAlign: "center",
                        renderCell: (params) => {
                            const total = shipmentTotals[params.row.id] ?? 0;
                            return formatter.format(total);
                        }
                    },
                    {
                        field: 'pdf',
                        headerName: 'PDF',
                        width: 40,
                        renderCell: (params) => {
                            const encodedCustomerName = encodeURIComponent(localStorage.getItem(CUSTOMER_NAME) as string);
                            const url = `/api/orders/${orderId}/shipment/${params.row.id}?customerName=${encodedCustomerName}`;
                            const refId = `${orderId}-${params.row.id}`;

                            return (<span>
                                    <IconButton color="primary" onClick={() => downloadPDF(url, refId)} >
                                        <PictureAsPdf/>
                                    </IconButton>
                                </span>
                            );
                        },
                    },
                    {
                        field: 'delete',
                        headerName: 'Cancel',
                        width: 70,
                        align: "right",
                        renderCell: (params) => {
                            const maxStatus = props.maxStatusForLineItemsInDelivery(params.row.id);
                            const disabled = customerFinalStatuses.has(maxStatus);
                            const tooltip = disabled ? "This shipment can no longer be canceled. Please reach out to your sales rep for help." : "";

                            return (<Tooltip title={tooltip}>
                                <span>
                                    <IconButton disabled={disabled} style={{}} onClick={() => {
                                        setShipmentIdToCancel(params.row.id);
                                        setCancelShipmentDialogOpen(true);
                                    }} >
                                        <DeleteIcon/>
                                    </IconButton>
                                </span>
                                </Tooltip>
                            );
                        },
                    },
                ]}
                style={{height: 180}}
                density={"compact"}
                onRowDoubleClick={handleShipmentSelection}
                hideFooter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
            />
            <CancelShipmentDialog open={cancelShipmentDialogOpen}
                                  orderId={orderId}
                                  shipmentId={shipmentIdToCancel ?? 0}
                                  onSuccessfulCancel={props.onShipmentCanceled}
                                  onClose={() => {
                                    setCancelShipmentDialogOpen(false);
                                    setShipmentIdToCancel(null);
                                }}
            />
        </>

    );
}

export default DeliveriesTable;