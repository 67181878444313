import { WebsiteAvailability } from "../../types/ProductType";
import {getDisplayForMaxPrice} from "../../Utilities/ProductUtils";

const ItemAvailability = ({
  websiteAvailability,
  minimumPrice,
  screen,
}: {
  websiteAvailability: WebsiteAvailability;
  minimumPrice: number | null;
  screen: string;
  setErrorToastMessage: (value: String | string) => void;
  setErrorToastOpen: (value: boolean) => void;
  setSuccessToastOpen: (value: boolean) => void;
}) => {

  const generatePriceDetails = (
      view: string,
      vendor: string,
      qty: string,
      price: string
  ) => {
    if (view === "availabilityList") {
        return <div>{price}</div>
    } else {
      return (
          <div>
            <strong>Vendor: </strong>
            {" " + vendor + " "}
            <strong>Quantity: </strong>
            {" " + qty + " "}
          </div>
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {generatePriceDetails(
          screen,
          websiteAvailability.companyName,
          websiteAvailability.quantity,
          getDisplayForMaxPrice(websiteAvailability.price, minimumPrice)
      )}
    </div>
  );
};

export default ItemAvailability;
