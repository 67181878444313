import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {CustomerContext} from "../../../providers/CustomerProvider";
import {SpecificOrderContext} from "../../../providers/SpecificOrderContext";

export type CancelOrderDialogProps = {
    open: boolean;
    orderId: number;
    onClose: () => void;
    onConfirm: () => void;
}

const CancelOrderDialog = (props: CancelOrderDialogProps) => {
    const { open, onClose, onConfirm } = props;
    const { parentCustomerName } = useContext(CustomerContext);
    const { setMainToastMessage, setMainToastSeverity, setMainToastOpen } = useContext(SpecificOrderContext);

    const [reason, setReason] = useState("")

    const makeDeleteRequest = () => {
        const customerName = parentCustomerName;
        fetch(`/api/orders/${props.orderId}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({reason, customerName})
        }).then((res) => {
            if(res.ok) {
                onConfirm();
            } else {
                res.json().then((data) => {
                    setMainToastMessage(data.message ?? "An error occurred. Please reach out if the problem persists.");
                    setMainToastSeverity("error");
                    setMainToastOpen(true);

                    onClose();
                });
            }
        })
    }

    useEffect(() => {
        //clear reason on close
        if(!open) {
            setReason("");
        }
    }, [open]);

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>Cancel Order</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Would you tell us how we could improve?
                <TextField rows={3} multiline fullWidth value={reason} onChange={e => {
                    setReason(e.target.value)
                }}/>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{marginBottom: 10, marginRight: 15}}>
                <Button style={{marginRight: 10}} onClick={onClose}>Go back</Button>
                <Button variant={"contained"} color={"secondary"} onClick={makeDeleteRequest}>Proceed</Button>
            </div>

        </DialogActions>
    </Dialog>
}

export default CancelOrderDialog;