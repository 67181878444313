import "./App.css";
import { Route, Switch } from "react-router-dom";
import NoMatch from "./components/global/NoMatch";
import NavBar from "./components/global/NavBar";
import Register from "./components/authComponents/registration/Register";
import Login from "./components/authComponents/Login";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/authComponents/ProtectedRoute";
import User from "./components/LoggedIn/User";
import Products from "./components/LoggedOut/Products";
import OrderManagement from "./components/LoggedIn/OrderManagement";
import FetchUser from "./components/authComponents/FetchUser";
import { useContext, useEffect } from "react";
import { AuthContext } from "./providers/AuthProvider";
import Home from "./components/LoggedOut/Home";
import AboutUs from "./components/LoggedOut/AboutUs";
import ContactUs from "./components/LoggedOut/ContactUs";
import { createTheme } from "@mui/material";
import {
  ThemeProvider,
  StyledEngineProvider,
  Theme,
} from "@mui/material/styles";
import SpecificOrder from "./components/LoggedIn/orders/SpecificOrder";
import VerifyEmailAddress from "./components/LoggedIn/VerifyEmailAddress";
import SetUserPassword from "./components/authComponents/SetPassword";
import LinkSent from "./components/LoggedIn/LinkSent";
import ForgottenPassword from "./components/LoggedOut/ForgottenPassword";
import { Product } from "./components/LoggedOut/Product";
import { ReachOutForm } from "./components/internal/ReachOutForm";
import { datadogLogs } from "@datadog/browser-logs";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { useQuery } from "./hooks/useQuery";
import { BranchContext } from "./providers/BranchProvider";
import { SHOW_ABOUT_US_PAGE } from "./constants/Constants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CycleCount } from "./components/internal/CycleCount";
import InternalRoute from "./components/authComponents/InternalRoute";
import {LoyaltyProgram} from "./components/LoggedOut/LoyaltyProgram";
import PlantPhotoBooth from "./components/internal/PlantPhotoBooth";
import PaymentPage from "./components/LoggedIn/PaymentPage";
import ManagePaymentMethods from "./components/guiComponents/payments/ManagePaymentMethods";
import ThankYouPage from "./components/guiComponents/payments/ThankYouPage";
import JobLineInfoCard from "./components/LoggedIn/JobLineInfoCard";
import NewTermsCard from "./components/LoggedIn/NewTermsCard";
import Checkout from "./components/LoggedIn/orders/Checkout";
import NoPaymentThankYouPage from "./components/LoggedIn/orders/checkout/NoPaymentThankYouPage";
import OrderNotFoundPage from "./components/LoggedIn/orders/OrderNotFoundPage";


declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

datadogLogs.init({
  clientToken: "pub16168ed5fd6a98d9a61e42fb8e36b624",
  site: "us5.datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: "customer-portal-fe",
});

LicenseInfo.setLicenseKey(
  "a61f50bf5b42e3e3d673da4a7945e70bTz0xMDEzOTAsRT0xNzYyNTQ2ODAzMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI="
);

// CHATRA
(function (d: any, w: any, c: any) {
  w.ChatraID = "gpQbBBrCREq5Gtaoj";
  var s = d.createElement("script");
  w[c] =
    w[c] ||
    function () {
      (w[c].q = w[c].q || []).push(arguments);
    };
  s.async = true;
  s.src = "https://call.chatra.io/chatra.js";
  if (d.head) d.head.appendChild(s);
})(document, window, "Chatra");

const theme = createTheme();

const App: React.FC = () => {
  const auth = useContext(AuthContext);
  const branch = useContext(BranchContext);

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: !!auth.authenticated,
    authenticationPath: "/login",
  };

  let query = useQuery();
  let location = query.get("location") || "";
  let acceptableLocation = branch.branches?.map((x) => x?.entityName);

  useEffect(() => {
    let urlString = window.location.href;
    let guidString = urlString.split("?")[1];
    let urlParams = new URLSearchParams(guidString);
    let guid = urlParams.get("GUID");
    if (auth.setUserGuid) {
      auth.setUserGuid(guid);
    }
    if (auth.setFromPath) {
      auth.setFromPath(window.location.pathname + window.location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.getInternalUserPermissions) {
      auth.getInternalUserPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user]);

  useEffect(() => {
    if (auth.setLoggedOutLocation && acceptableLocation?.includes(location)) {
      auth.setLoggedOutLocation(location as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch.branches]);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <FetchUser>
              <NavBar />
              <Switch>
                <Route exact path="/" component={Home} />
                {localStorage.getItem(SHOW_ABOUT_US_PAGE) ? (
                  <Route exact path="/about" component={AboutUs} />
                ) : (
                  ""
                )}
                <Route
                  {...defaultProtectedRouteProps}
                  exact
                  path="/contact"
                  component={ContactUs}
                />
                <Route
                    {...defaultProtectedRouteProps}
                    exact
                    path="/not-found"
                    component={OrderNotFoundPage}
                />

                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/setpassword" component={SetUserPassword} />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  exact
                  path="/user"
                  component={User}
                />
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/jobline"
                    component={JobLineInfoCard}
                />
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/new-terms"
                    component={NewTermsCard}
                />
                <Route exact path="/products" component={Products} />
                <Route exact path="/products/:itemCode" component={Product} />
                <Route exact path="/loyalty-program" component={LoyaltyProgram} />
                <Route
                  exact
                  path="/products/roottype/:roottype/qtytypefilter/:qtytypefilter/catalogformatting/:catalogformatting"
                  component={Products}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  exact
                  path="/order-management/"
                  component={OrderManagement}
                />
                {/*old link style. Deprecated 12/2024*/}
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/order-management/order/:orderId/shipment/:shipmentId/view/:view"
                    component={SpecificOrder}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  exact
                  path="/order-management/order/:orderId/shipment/:shipmentId"
                  component={SpecificOrder}
                />
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/order-management/order/:orderId"
                    component={SpecificOrder}
                />
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/checkout/order/:orderId"
                    component={Checkout}
                />
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/checkout/thank-you"
                    component={NoPaymentThankYouPage}
                />
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/payments/"
                    component={PaymentPage}
                />
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/payments/thank-you"
                    component={ThankYouPage}
                />
                <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/payments/manage"
                    component={ManagePaymentMethods}
                />
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  exact
                  path="/user/reachout"
                  component={ReachOutForm}
                />
                <InternalRoute
                    {...defaultProtectedRouteProps}
                    exact
                    path="/plant-photo-booth"
                    render={(props) => <PlantPhotoBooth {...props} forItemBases={false} title={"Plant Photo Booth"}/> }
                />
                <InternalRoute
                  {...defaultProtectedRouteProps}
                  exact
                  path="/cycle-count"
                  component={CycleCount}
                />
                <Route
                  exact
                  path="/user/emailverification"
                  component={VerifyEmailAddress}
                />
                <Route exact path="/user/sendlink" component={LinkSent} />
                <Route
                  exact
                  path="/user/forgottenpassword"
                  component={ForgottenPassword}
                />
                <Route component={NoMatch} />
              </Switch>
            </FetchUser>
          </ThemeProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
