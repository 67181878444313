import {createContext, useState} from "react";
import {AlertColor} from "@mui/material";

export type SpecificOrderContextOptions = {
    mainToastMessage: string;
    setMainToastMessage: (message: string) => void;
    mainToastOpen: boolean;
    setMainToastOpen: (open: boolean) => void;
    mainToastSeverity: AlertColor;
    setMainToastSeverity: (severity: AlertColor) => void;
}

const DEFAULT_ORDER_CONTEXT_OPTIONS: SpecificOrderContextOptions = {
    mainToastMessage: "",
    setMainToastMessage: () => {},
    mainToastOpen: false,
    setMainToastOpen: () => {},
    mainToastSeverity: "error",
    setMainToastSeverity: () => {},
}

export const SpecificOrderContext = createContext<SpecificOrderContextOptions>({...DEFAULT_ORDER_CONTEXT_OPTIONS});

export const SpecificOrderProvider = (props: any) => {
    const [mainToastOpen, setMainToastOpen] = useState(false);
    const [mainToastSeverity, setMainToastSeverity] = useState<AlertColor>('error');
    const [mainToastMessage, setMainToastMessage] = useState('An error occurred');

    const container: SpecificOrderContextOptions = {
        mainToastOpen,
        setMainToastOpen,
        mainToastSeverity,
        setMainToastSeverity,
        mainToastMessage,
        setMainToastMessage,
    };

    return (
        <SpecificOrderContext.Provider value={container}>
            {props.children}
        </SpecificOrderContext.Provider>
    );
};