import { useHistory } from 'react-router-dom';
import React, {useContext} from "react";
import { Button, Container, Typography } from '@mui/material';
import {StylesContext} from "../../../providers/StylesProvider";

interface OrderNotFoundPageProps {
    backgroundImageUrl: string;
}

const OrderNotFoundPage: React.FC<OrderNotFoundPageProps> = ({ backgroundImageUrl }) => {
    const {navBarHeight} = useContext(StylesContext)
    const adjustedHeight = (navBarHeight ?? 0) + 20;
    const history = useHistory();

    const handleGoBack = () => {
        history.push('/order-management');
    };

    return (
        <Container  style={{ textAlign: 'center', marginTop: adjustedHeight }} >
            <h1>Order Not Found</h1>

            <Typography variant="body1" gutterBottom>
                The order you are looking for does not exist, you do not have permission to view it, or it has been removed.
            </Typography>
            <Button style={{marginTop: 20}} variant="contained" color="primary" onClick={handleGoBack}>
                Go Back to Order Management
            </Button>
        </Container>
    );
};

export default OrderNotFoundPage;