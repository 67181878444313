import React, {ChangeEvent, useEffect, useRef} from "react";
import {TextField} from "@mui/material";
import {camelCaseToSplitWords} from "../authComponents/registration/validators/ValidationRule";

export type  NumberFieldProps = {
    inError?: boolean;
    errorMessage?: () => string;
    name: string;
    label?: string;
    value: number | string; //important bc it allows ""
    updateData: (event: number) => void;
    variant?: "standard" | "outlined" | "filled";
    margin?: "none" | "dense" | "normal";
    size: "small" | "medium" | undefined;
    disableUnderline?: boolean
    autoSelectText?: boolean
    autoSelectTextOnFocus?: boolean
    onEnter?: () => void
    style?: React.CSSProperties
}

const NumberField = (props: NumberFieldProps) => {
    const myLabel = props.label ? props.label : camelCaseToSplitWords(props.name);
    const myVariant = props.variant ? props.variant : "standard";
    const myMargin = props.margin ? props.margin : "dense";
    const selectText = props.autoSelectText ? props.autoSelectText : false;
    const selectTextOnFocus = props.autoSelectTextOnFocus ? props.autoSelectTextOnFocus : false; //like a weaker form of the above since it waits to select until focused
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && selectText) {
            inputRef.current.select();
        }
    }, [selectText]);

    return <TextField
        fullWidth
        required
        type="text"
        margin={myMargin}
        variant={myVariant}
        style={props.style}
        size={props.size}
        name={props.name}
        label={myLabel}
        error={props.inError}
        helperText={props.errorMessage?.()}
        InputProps={props.disableUnderline ? { disableUnderline: true } : {}}
        inputRef={inputRef}
        onFocus={() => {
            if(selectTextOnFocus) {
                inputRef.current?.select();
            }
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value;

            if(value) {
                value = value.replace(/^0+(?=\d)/, ''); //strip leading 0s only if followed by a digit
            }

            const isNumber = !isNaN(Number(value));

            if (isNumber && !value.includes(" ")) {
                const doubleRegex = /^\d*\.?\d{0,2}$/;
                const candidate = Number(value);

                if (value === "" || (doubleRegex.test(value) && candidate >= 0)) {
                    props.updateData(value as unknown as number);
                }
            }
        }}
        value={props.value === 0 ? "" : props.value}
        onKeyDown={(event) => {
            if (event.key === 'Enter' && props.onEnter) {
                props.onEnter();
            }
        }}
    />
}

export default NumberField;