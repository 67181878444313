import NotEmptyRule from "./NotEmptyRule";
import BaseValidator from "./BaseValidator";
import {OrderEO} from "../../../LoggedIn/orders/OrderInformation";
import IfDefaultLogisticsRequired from "./IfDefaultLogistics";

class CheckoutValidator extends BaseValidator<OrderEO> {
    constructor() {
        super();
        this.dataValidations = {
            onSiteContactName: [new NotEmptyRule()],
            onSiteContactPhone: [new NotEmptyRule()],
            defaultShippingDate: [new NotEmptyRule()],
            defaultShippingAddress: [new IfDefaultLogisticsRequired(new NotEmptyRule(), "defaultShippingAddress")],
        };
    }
}

export default CheckoutValidator;