import {useCallback, useMemo} from "react";
import {DEFAULT_PHOTO} from "../components/LoggedOut/PhotoGallery";
import {datadogLogs} from "@datadog/browser-logs";

const PhotoUtil = () => {
    const scaleForPortrait = useCallback((photoUrl: string, title: string) => {
        const encodedTitle = encodeURIComponent(title.replaceAll(",", ""));
        const scaleParams = `b_rgb:000000,bo_5px_solid_rgb:595959,c_lpad,h_800,w_600/c_scale,g_south,h_60,l_black_bar,o_60,w_600,y_5/g_south,l_text:Arial_30_bold:${encodedTitle},co_white,w_550,y_20`;
        // https://res.cloudinary.com/treesource-systems/image/upload/b_rgb:000000,bo_5px_solid_rgb:595959,c_lpad,h_800,w_600/c_scale,g_south,h_60,l_black_bar,o_60,w_600,y_5/g_south,l_text:Arial_30_bold:Pinus%20nigra%20-%20Pine%20Austrian,co_white,y_20/v1717800367/10E3G_c1_2024_06_07T1646.jpg
        return photoUrl.replace("/upload/", `/upload/${scaleParams}/`);
    }, []);

    const urlToFile = useCallback(async (url: string, filename: string, mimeType: string): Promise<File> => {
        let response = await fetch(url.replace("http://", "https://"));

        if(!response.ok) {
            datadogLogs.logger.error("Failed to fetch image", {url: url, status: response.status});
            response = await fetch(DEFAULT_PHOTO.url)
        }

        const data = await response.blob();
        return new File([data], filename, { type: mimeType });
    }, []);

    return useMemo(() => ({
        scaleForPortrait,
        urlToFile
    }), [scaleForPortrait, urlToFile]);
}

export default PhotoUtil;