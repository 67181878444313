import { OBJECT_TYPE, ValidationRule} from "./ValidationRule";
import {OrderEO} from "../../../LoggedIn/orders/OrderInformation";
import {LOGISTICS_WILL_CALL} from "../../../../constants/Constants";

class IfDefaultLogistics implements ValidationRule {
    type = OBJECT_TYPE;

    validationRule: ValidationRule;
    fieldName: string;

    constructor(ValidationRule: ValidationRule, fieldName: string) {
        this.validationRule = ValidationRule;
        this.fieldName = fieldName;
    }

    isValid(orderInfo: OrderEO)  {
        const nonCommittedDeliveries = orderInfo.deliveries.filter(delivery => delivery.status === "OPEN" || delivery.status === "NO_AUTO_ADD");
        const nonWillCalls = nonCommittedDeliveries.filter(delivery => delivery.type !== LOGISTICS_WILL_CALL);

        if(orderInfo.useDefaultLogistics && nonWillCalls.length > 0) {
            // pass the responsibility for validation to the next rule
            return this.validationRule.isValid(orderInfo[this.fieldName]);
        } else {
            return true;
        }
    }

    getErrorMessage(fieldName: string) {
        //take advantage of the fact this is only called when isValid returns false
        //to assume we are a business and need to delegate
        return this.validationRule.getErrorMessage(fieldName);
    }
}

export default IfDefaultLogistics;