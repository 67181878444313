import {useCallback, useMemo} from "react";

const DateUtil = () => {

    const asDate = useCallback((stringVal: string | undefined) => {
        if (stringVal) {
            const date = new Date(stringVal);
            return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
        } else {
            return "";
        }
    }, []);


    return useMemo(() => ({
        asDate
    }), [asDate]);
}

export default DateUtil;