import {Box, Tab, Tabs, TextField, Tooltip,} from "@mui/material";
import {CUSTOMER_NAME, formatter, LOGISTICS_WILL_CALL} from "../../../constants/Constants";
import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from "react";
import useDateUtil from "../../../hooks/useDateUtil";
import {SpecificOrderContext} from "../../../providers/SpecificOrderContext";
import {DEFAULT_SHIPMENT_EO, ShipmentEO } from "./ShipmentEO";
import {useHistory} from "react-router";
import {CustomerContext} from "../../../providers/CustomerProvider";

export type ShipmentParams = {
    orderId: number,
    shipmentId: number,
    shipmentTotals: {[key: string]: number}
}

const ShipmentInformation = (props: ShipmentParams) => {
    const { setMainToastMessage, setMainToastOpen, setMainToastSeverity } = useContext(SpecificOrderContext);
    const { parentCustomerName } = useContext(CustomerContext);
    const { orderId, shipmentId } = props;
    const { asDate } = useDateUtil();
    const history = useHistory();

    //local data
    const [shipmentEO, setShipmentEO] = useState<ShipmentEO>(DEFAULT_SHIPMENT_EO);
    const [backupShipmentEO, setBackupShipmentEO] = useState<ShipmentEO>(DEFAULT_SHIPMENT_EO);
    const inMemSubtotal = props.shipmentTotals[shipmentId] ?? 0;
    const invoiced = shipmentEO.status === "INVOICED";
    const tooltipMessage = invoiced ? "This field is disabled because the shipment is invoiced." : "";

    useEffect(() => {
        if(!parentCustomerName) {
            return; //wait until deps load
        }
        const encodedCustomerName = encodeURIComponent(parentCustomerName as string);
        fetch(`/api/orders/${orderId}/shipment/${shipmentId}/details?customerName=${encodedCustomerName}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        })
            .then(response => {
                if(response.ok) {
                    return response;
                } else if (response.status === 404 || response.status === 401) {
                    history.push('/not-found');
                } else {
                    throw new Error("Failed to retrieve shipment details");
                }
            })
        .then(response => response?.json() ?? DEFAULT_SHIPMENT_EO)
        .then(data => {
            setShipmentEO(data);
        });
    }, [orderId, shipmentId, history, parentCustomerName]);

    const requestBackendUpdate = useCallback(() => {
        const checkpoint = {...shipmentEO};

        if(shipmentEO === backupShipmentEO || shipmentEO === DEFAULT_SHIPMENT_EO) {
            return;
        }

        fetch(`/api/orders/${orderId}/shipment/${shipmentId}/details`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({...checkpoint, customerName: localStorage.getItem(CUSTOMER_NAME)})
        }).then(response => {
            if (!response.ok) {
                setMainToastMessage("Failed to update order information");
                setMainToastSeverity("error");
                setMainToastOpen(true);
                setShipmentEO(backupShipmentEO);
            } else {
                setBackupShipmentEO(checkpoint);
            }
        })


    }, [shipmentEO, backupShipmentEO, orderId, shipmentId, setMainToastMessage, setMainToastSeverity, setMainToastOpen]);

    const updateShipmentInfo = (e: ChangeEvent<HTMLInputElement>) => {
        //this requires the element's name attribute to match the key in the OrderEO object
        setShipmentEO({...shipmentEO, [e.target.name]: e.target.value});
    }

    return <>
        <Tabs aria-label="shipment tabs" value={0}>
            <Tab label="Shipment Information" />
        </Tabs>
        <Box p={1}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{display: "flex", width: "66%", flexDirection: "column"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <TextField
                            style={{width: "32%"}}
                            variant="standard"
                            disabled
                            name="origin"
                            label="Origin"
                            value={shipmentEO?.vendor ?? ""}
                        />
                        <TextField
                            style={{width: "29%"}}
                            variant="standard"
                            disabled
                            name="status"
                            label="Status"
                            value={shipmentEO?.status ?? ""}
                        />
                        <TextField
                            style={{width: "32%"}}
                            variant="standard"
                            disabled
                            name="subtotal"
                            label="Sub Total"
                            value={formatter.format(inMemSubtotal)}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Tooltip title={tooltipMessage}>
                            <TextField
                                style={{width: "65%", marginTop: 20}}
                                variant="standard"
                                name="name"
                                label="Name"
                                disabled={invoiced}
                                value={shipmentEO?.name ?? ""}
                                onChange={updateShipmentInfo}
                                onBlur={() => requestBackendUpdate()}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Tooltip>
                        <Tooltip title={tooltipMessage}>
                            <TextField
                                style={{width: "32%", marginTop: 20}}
                                label="Land Date"
                                name="landDate"
                                type="date"
                                variant={"standard"}
                                disabled={invoiced}
                                value={asDate(shipmentEO?.landDate) || ""}
                                onChange={updateShipmentInfo}
                                onBlur={() => requestBackendUpdate()}
                                InputLabelProps={{shrink: true}}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div style={{display: "flex", width: "31%", flexDirection: "column"}}>
                    <Tooltip title={shipmentEO?.type === LOGISTICS_WILL_CALL ? "This field is disabled because the shipment is a Will Call." : tooltipMessage}>
                        <TextField
                            variant="standard"
                            name="destination"
                            label="Destination"
                            disabled={shipmentEO?.type === LOGISTICS_WILL_CALL || invoiced}
                            value={shipmentEO?.type === LOGISTICS_WILL_CALL ? "Will Call @ " + shipmentEO.vendor : shipmentEO.destination}
                            onChange={updateShipmentInfo}
                            onBlur={() => requestBackendUpdate()}
                            multiline
                            rows={4}
                            inputProps={{ maxLength: 500 }}
                        />
                    </Tooltip>
                </div>

            </div>
        </Box>
    </>
}

export default ShipmentInformation;