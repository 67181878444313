import {NewUserData} from "./Register";
import React, {useContext, useEffect, useState} from "react";
import {Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import SimpleAutoComplete from "../../guiComponents/SimpleAutoComplete";
import {StylesContext} from "../../../providers/StylesProvider";
import AddressInputFields from "../../guiComponents/payments/AddressInputFields";

const isHomeownerChoices = [
    { label: "Homeowner", value: true},
    { label: "Business", value: false}
]

const contactMethods = [
    "Email",
    "Phone",
    "Cell",
    "Text",
    "Fax",
    "Mail",
    "Other"
]

export type BasicSupplementalInfoProps = {
    userData: NewUserData,
    updateUserData: (event: React.ChangeEvent<HTMLInputElement>) => void,
    directDataUpdate: (fieldName: string, value: any) => void,
    isInvalid: (fieldName: string) => boolean
    getErrorMessage: (fieldName: string) => string
    showInstructions?: boolean
}

const BasicSupplementalInfo = (props: BasicSupplementalInfoProps) => {
    //context
    const {userData} = props;
    const {isDesktop} = useContext(StylesContext);

    //local state
    const [useStreetAddressForMailingAddress, setUseStreetAddressForMailingAddress] = useState(true);
    const showInstructions = props.showInstructions ?? true;

    useEffect(() => {
        const element = document.getElementById("preferredContactMethod");
        if (element) {
            element.focus();
        }
    }, []);

    return <>
        <div style={{ width: isDesktop ? "800px" : "100%", flexFlow: "wrap"}}>
            {showInstructions && <Typography variant={"h6"} style={{margin: 10}}>
                Understanding your business will help the team at Tree Source better serve you. Please take a few moments to answer the questions below.
            </Typography>}
            <div style={{ display: "flex", flexDirection: "row"}}>
                <SimpleAutoComplete
                    id={"customerType"}
                    label={"Customer Type"}
                    disabled={true}
                    options={isHomeownerChoices.map((choice) => choice.label)}
                    value={isHomeownerChoices.find(choice => choice.value === userData.isHomeowner)?.label || ""}
                    setValue={(value) => {
                        const isHomeowner = isHomeownerChoices.filter((choice) => choice.label === value)[0]?.value ?? ""

                        props.directDataUpdate("isHomeowner", isHomeowner)
                    }}
                    outlined={false}
                    style={{margin: 10, width: isDesktop ? "50%" : "100%" }}
                />
                <SimpleAutoComplete
                    id={"preferredContactMethod"}
                    label={"Preferred Contact Method"}
                    options={contactMethods}
                    value={userData.preferredContactMethod}
                    setValue={(value) => {
                        props.directDataUpdate("preferredContactMethod", value)
                    }}
                    outlined={false}
                    style={{margin: 10, width: isDesktop ? "50%" : "100%" }}/>
            </div>


            <div style={{ display: "flex", flexDirection: "column"}}>
                <TextField
                    style={{margin: 10}}
                    variant="standard"
                    name="referralMethod"
                    label="How did you hear about us?"
                    error={props.isInvalid("referralMethod")}
                    helperText={props.getErrorMessage("referralMethod")}
                    onChange={props.updateUserData}
                    value={userData.referralMethod}
                />

                <div style={{marginLeft: 10, marginRight: 10 }}>
                    <AddressInputFields label={"Billing Address"}
                                        required={true}
                                        address={userData.billingAddress}
                                        setAddress={address => {
                                            props.directDataUpdate("billingAddress", address) }
                                        }
                                        outlined={false}
                    />
                </div>

                <FormControlLabel
                    style={{marginTop: 10, marginLeft: 0}}
                    control={<Checkbox checked={useStreetAddressForMailingAddress}
                                       onChange={(event) => setUseStreetAddressForMailingAddress(event.target.checked)}/>}
                    label="Use billing address for shipping address"
                />

                {!useStreetAddressForMailingAddress && <div style={{marginLeft: 10, marginRight: 10 }}>
                    <AddressInputFields label={"Shipping Address"}
                                        address={userData.shippingAddress}
                                        setAddress={address => {
                                            props.directDataUpdate("shippingAddress", address)
                                        }}
                                        outlined={false}/>
                </div>}
            </div>
        </div>
    </>
}

export default BasicSupplementalInfo;