import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {Alert, Button, CardContent, CardHeader, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {BoldTypography} from "../../KeyValue";
import treesourceLogo from "../../Loaders/graphic-test3-01.svg";
import {StylesContext} from "../../../providers/StylesProvider";
import {CUSTOMER_NAME, formatter} from "../../../constants/Constants";
import {PaymentMethod, TYPE_CARD} from "../../../types/CardDataType";
import {CustomerContext} from "../../../providers/CustomerProvider";

type PaymentSummaryCardProps = {
    subtotal: number;
    credits: number;
    paymentMethod: PaymentMethod;
    submitPayment: (ReCAPTCHA: string, paymentAmount: number, processingFee: number, notes: string) => void;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    loading: boolean;
    padded?: boolean;
}
const PaymentSummaryCard = (props: PaymentSummaryCardProps) => {
    const { getParentCustomerName } = useContext(CustomerContext);

    //local state
    const [notes, setNotes] = useState<string>("");
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [processingFeeRate, setProcessingFeeRate] = useState<number>(0);
    const [processingFeeWaived, setProcessingFeeWaived] = useState<boolean>(false);
    const [processingFeeFlagOn, setProcessingFeeFlagOn] = useState<boolean>(false);
    const padded = props.padded ?? true;

    function roundToNearestPenny(amount: number): number {
        return Math.round(amount * 100) / 100;
    }

    const getProcessingFee = (method: PaymentMethod, paymentAmount: number) => {
        if(method.type === TYPE_CARD && paymentAmount > 0) {
            return roundToNearestPenny(paymentAmount * processingFeeRate);
        } else {
            return 0;
        }
    }

    const credits = props.credits > props.subtotal ? props.subtotal : props.credits;
    const processingFee = getProcessingFee(props.paymentMethod, props.subtotal - credits);

    const grandTotal = props.subtotal + processingFee - credits;

    //context
    const {isDesktop} = useContext(StylesContext);

    useEffect(() => {
        const fetchProcessingFeeRate = async () => {
            try {
                const customerName = localStorage.getItem(CUSTOMER_NAME) ?? "";
                const parentCustomerName = getParentCustomerName ? getParentCustomerName(customerName) : customerName;
                const encodedCustomerName = encodeURIComponent(parentCustomerName);
                const response = await fetch("/api/payment/processing-fee/rate?parentCustomerName=" + encodedCustomerName,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token") ?? undefined,
                        } as HeadersInit
                    });
                if (!response.ok) {
                    throw new Error("We had an issue with the network. Please try again");
                }
                const data = await response.json();

                if("waived" === data.rate) {
                    setProcessingFeeWaived(true);
                    setProcessingFeeRate(0);
                    setProcessingFeeFlagOn(true);
                    return;
                } else {
                    setProcessingFeeRate(data.rate);
                    // @ts-ignore
                    setProcessingFeeFlagOn(data.rate > 0);
                }

            } catch (error) {
                console.error("Error fetching processing fee rate:", error);
            }
        };

        fetchProcessingFeeRate();
    }, [getParentCustomerName]);

    const loadingLayout = () => {
        return <>
            <PaddedCard style={{margin: padded ? 10 : 0}}>
                <CardHeader title="Finalize"/>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}}>
                    <img style={{width: 200}} src={treesourceLogo} alt="treesource loading spinner"/>
                    <p>Thanks! We're processing your payment...</p>
                </div>
            </PaddedCard>
        </>
    }

    const getProcessingFeeNote = () => {
        if(processingFeeFlagOn) {
            return "On January 1, 2025, Treesource began imposing a convenience fee of 1.5% on all credit card payments."
        } else {
            return "Beginning January 1, 2025, Treesource will start imposing a convenience fee of 1.5% on all credit card payments."
        }
    }

    const renderProcessingFeeAlert = () => {
        return (
            <PaddedCard style={{marginTop: 0, marginLeft: padded ? 10 : 0, marginRight: padded ? 10 : 0}}>
                <Alert severity="info">
                    {getProcessingFeeNote()}
                </Alert>
            </PaddedCard>
        );
    }

    const noFeeDisplayValue = () => {
        if(processingFeeWaived && props.paymentMethod.type === TYPE_CARD) {
            return "Waived By Terms";
        } else {
            return "N/A";
        }
    }

    const renderDesktop = () => {
        return <>
            {renderProcessingFeeAlert()}
            <PaddedCard style={{margin: padded ? 10 : 0}}>
                <CardHeader title="Finalize"/>

                {props.errorMessage && (
                    <CardContent>
                        <Alert onClose={() => props.setErrorMessage("")} severity="error">
                            {props.errorMessage}
                        </Alert>
                    </CardContent>
                )}

                <CardContent>
                    <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "100%"}}>
                        <div style={{width: "26%", marginLeft: 10}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 15,
                                marginBottom: 20
                            }}>
                                <div style={{width: "55%"}}>
                                    <BoldTypography>Subtotal:</BoldTypography>
                                </div>
                                <div style={{width: "45%"}}>
                                    <Typography>{formatter.format(props.subtotal)}</Typography>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                                <div style={{width: "55%"}}>
                                    <BoldTypography>Credit Memos:</BoldTypography>
                                </div>
                                <div style={{width: "45"}}>
                                    <Typography>{credits > 0 ? "- " + formatter.format(credits) : "N/A"}</Typography>
                                </div>
                            </div>
                            { processingFeeFlagOn &&
                                <div
                                    style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                                    <div style={{width: "55%"}}>
                                        <BoldTypography>Credit Card Fee:</BoldTypography>
                                    </div>
                                    <div style={{width: "45%"}}>
                                        <Typography>{processingFee > 0 ? "+ " + formatter.format(processingFee) : noFeeDisplayValue()}</Typography>
                                    </div>
                                </div>
                            }
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                                <div style={{width: "55%"}}>
                                    <BoldTypography>Grand Total:</BoldTypography>
                                </div>
                                <div style={{width: "45%"}}>
                                    <Typography>{formatter.format(grandTotal)}</Typography>
                                </div>
                            </div>
                        </div>

                        <div style={{width: "45%"}}>
                            <div
                                style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                                <div style={{width: "15%"}}>
                                    <BoldTypography>Notes:</BoldTypography>
                                </div>
                                <div style={{width: "85%"}}>
                                    <TextField
                                        inputProps={{
                                            maxLength: 1000,
                                        }}
                                        onChange={(event) => setNotes(event?.target.value)}
                                        value={notes}
                                        error={notes.length > 1000}
                                        helperText={notes.length > 1000 ? "Note length must be less than 1000 characters." : ""}
                                        fullWidth
                                        label="Optional notes for our AR team"
                                    />
                                </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10}}>
                                <div style={{width: "15%"}}>
                                    {/*space left blank for formatting*/}
                                </div>
                                <div style={{width: "85%"}}>
                                    Payment services are provided by Intuit Payments Inc.
                                    <div style={{marginTop: 10}}>
                                        <ReCAPTCHA
                                            sitekey="6Le1uZYeAAAAAETWoZ4IbNCLNZVbfcUPHUqYbWd1"
                                            onChange={(value: any) => {
                                                setRecaptchaToken(value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "25%"}}>
                            <Button
                                id="submitCountButton"
                                type="submit"
                                variant="contained"
                                color="success"
                                disabled={props.subtotal <= 0}  //enable once we have a subtotal
                                style={{marginTop: 10}}
                                onClick={() => props.submitPayment(recaptchaToken, grandTotal, processingFee, notes)}
                            >
                                {credits > 0 && grandTotal === 0 ? "Apply credits!" : "Submit Payment!"}
                            </Button>
                        </div>
                    </div>

                </CardContent>
            </PaddedCard>
        </>
    }

    const renderMobile = () => {
        return <>
            {renderProcessingFeeAlert()}
            <PaddedCard>
                <CardHeader title="Finalize"/>

                {props.errorMessage && (
                    <CardContent>
                        <Alert onClose={() => props.setErrorMessage("")} severity="error">
                            {props.errorMessage}
                        </Alert>
                    </CardContent>
                )}

                <CardContent>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", width: "100%"}}>
                        <div style={{width: "100%", marginBottom: 20}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: 20
                            }}>
                                <div style={{width: "50%"}}>
                                    <BoldTypography>Subtotal:</BoldTypography>
                                </div>
                                <div style={{width: "50%", textAlign: "right"}}>
                                    <Typography>{formatter.format(props.subtotal)}</Typography>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: 20
                            }}>
                                <div style={{width: "50%"}}>
                                    <BoldTypography>Credit Memos:</BoldTypography>
                                </div>
                                <div style={{width: "50%", textAlign: "right"}}>
                                    <Typography>{credits > 0 ? "- " + formatter.format(credits) : "N/A"}</Typography>
                                </div>
                            </div>
                            {processingFeeFlagOn &&
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "stretch",
                                    marginBottom: 20
                                }}>
                                    <div style={{width: "50%"}}>
                                        <BoldTypography>Credit Card Fee:</BoldTypography>
                                    </div>
                                    <div style={{width: "50%", textAlign: "right"}}>
                                        <Typography>{processingFee > 0 ? "+ " + formatter.format(processingFee) : "N/A"}</Typography>
                                    </div>
                                </div>}
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "stretch",
                                marginBottom: 20
                            }}>
                                <div style={{width: "50%"}}>
                                    <BoldTypography>Grand Total:</BoldTypography>
                                </div>
                                <div style={{width: "50%", textAlign: "right"}}>
                                    <Typography>{formatter.format(grandTotal)}</Typography>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                                <BoldTypography>Notes:</BoldTypography>
                                <TextField
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    onChange={(event) => setNotes(event?.target.value)}
                                    value={notes}
                                    error={notes.length > 1000}
                                    helperText={notes.length > 1000 ? "Note length must be less than 1000 characters." : ""}
                                    fullWidth
                                    label="Optional notes for our AR team"
                                />
                            </div>
                        </div>

                        <div style={{width: "100%", marginBottom: 20}}>
                            Payment services are provided by Intuit Payments Inc.
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 10,
                                width: "100%"
                            }}>
                                <ReCAPTCHA
                                    sitekey="6Le1uZYeAAAAAETWoZ4IbNCLNZVbfcUPHUqYbWd1"
                                    onChange={(value: any) => {
                                        setRecaptchaToken(value)
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Button
                                id="submitCountButton"
                                type="submit"
                                variant="contained"
                                color="success"
                                disabled={props.subtotal <= 0}  //enable once we have a subtotal
                                style={{marginTop: 10}}
                                onClick={() => props.submitPayment(recaptchaToken, grandTotal, processingFee, notes)}
                            >
                                {credits > 0 && grandTotal === 0 ? "Apply credits!" : "Submit Payment!"}
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </PaddedCard>
        </>

    }

    if (props.loading) {
        return loadingLayout();
    } else {
        return isDesktop ? renderDesktop() : renderMobile();
    }
}

export default PaymentSummaryCard;