export type OrderForAdd = {
    id: number;
    label: string;
    orderId: number;
    orderName: string;
    uncommittedLineItemCount: number;
};

export const NEW_ORDER: OrderForAdd = {
    id: -1,
    label: "New Order",
    orderId: -1,
    orderName: "New Order",
    uncommittedLineItemCount: 0,
};