import {Button, CardContent, CardHeader, Typography} from "@mui/material";
import React, {useContext} from "react";
import {AuthContext} from "../../providers/AuthProvider";
import {PaddedCard} from "../LoggedOut/PaddedCard";
import {useHistory} from "react-router";
import {StylesContext} from "../../providers/StylesProvider";

const SalesRepInfo = () => {
    const history = useHistory();
    const Auth = useContext(AuthContext);
    const { isDesktop } = useContext(StylesContext);

    const customer = Auth.user?.customer;
    const terms = customer?.termsRef_FullName?.trim();

    const goToNewTerms = () => {
        history.push('/new-terms');
    }

    const formatPhone = (phoneNumber: string | undefined): string => {
        let unformattedPhoneNumber = (phoneNumber || "").replace(/\D/g, "");
        unformattedPhoneNumber = unformattedPhoneNumber || "";

        if (unformattedPhoneNumber.length === 10) {
            return unformattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        } else if (unformattedPhoneNumber.length === 7) {
            return unformattedPhoneNumber.replace(/(\d{3})(\d{4})/, "$1-$2");
        } else {
            return phoneNumber ?? ""; // Return as is if it doesn't match expected lengths
        }
    }

    const renderFact = () => {
        return <>
            <Typography style={{marginTop: 10}} variant="body1" fontStyle={"italic"}>
                {customer?.salesRep.humanizingFact ?? "Coming Soon!"}
            </Typography>
        </>
    }

    const renderDesktop = () => {
        if (customer?.salesRep?.name) {
            return (
                <PaddedCard style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", minHeight: 250}} >
                    <div style={{width: "65%"}}>
                        <CardHeader title="Sales Rep Info" />
                        <CardContent>
                            <Typography variant="body1">
                                <strong>Assigned Sales Rep: </strong> {customer?.salesRep.name}
                            </Typography>
                            <Typography variant="body1">
                                <strong> Email: </strong>
                                <a href={`mailto: ${customer.salesRep.emailAddress}`}>
                                    {customer.salesRep.emailAddress}
                                </a>
                            </Typography>
                            <Typography variant="body1">
                                <strong>Phone: </strong>
                                <a href={`tel: ${customer.salesRep.phoneNumber}`}>
                                    {formatPhone(customer.salesRep.phoneNumber)}
                                </a>
                            </Typography>
                            {renderFact()}
                        </CardContent>
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "32%", marginRight: terms === "COD" ? 10 : 15, marginTop: terms === "COD" ? 8 : 15}}>

                        <img style={{width: terms === "COD" ? 125: 150, height: terms === "COD" ? 125: 150}}
                             src={customer.salesRep.photoUrl ?? "https://res.cloudinary.com/treesource-systems/image/upload/v1668197646/w_193/avatarplaceholder.jpg"}
                             alt={customer.salesRep.name ?? "Your sales rep"}/>
                        <Button
                            style={{marginTop: terms === "COD" ? 7 : 15, width: "100%"}}
                            variant="contained"
                            color="primary"
                            href={`mailto:${customer.salesRep.emailAddress}?subject=Request for quote`}
                        >
                            Send Me A List!
                        </Button>
                        {terms === "COD" &&
                            <Button style={{marginTop: 7, width: "100%"}} onClick={() => goToNewTerms()}
                                    variant="contained" color="primary">
                                Apply For Terms
                            </Button>
                        }

                    </div>
                </PaddedCard>
            );
        } else {
            return <></>
        }
    }

    const renderMobile = () => {
        if (customer?.salesRep?.name) {
            return (
                <PaddedCard style={{ display: "flex", flexDirection: "column", alignItems: "stretch", height: "90%"}} >
                    <CardHeader title={"Sales Rep Info"}  />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "38%",
                            marginRight: 5,
                            marginLeft: 10,
                            marginBottom: 20
                        }}>
                            <img style={{width: 135, height: 135}} src={customer.salesRep.photoUrl} alt={customer.salesRep.name ?? "Your Sales Rep"}/>
                            <Button
                                style={{marginTop: terms === "COD" ? 7 : 15, width: "100%"}}
                                variant="contained"
                                color="primary"
                                href={`mailto:${customer.salesRep.emailAddress}?subject=Request for quote`}
                            >
                                Send A List!
                            </Button>
                            {terms === "COD" &&
                                <Button style={{marginTop: 7, width: "100%"}} onClick={() => goToNewTerms()}
                                        variant="contained" color="primary">
                                    Get Terms
                                </Button>
                            }
                        </div>
                        <div style={{display: "flex", flexDirection: "column", minHeight: 200, width: "55%", marginBottom: 15}}>
                            <div>
                                <Typography variant="body1">
                                    <strong>Name: </strong>
                                </Typography>
                                <Typography variant="body1">
                                    {customer.salesRep.name}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body1">
                                    <strong> Email: </strong>
                                </Typography>
                                <Typography variant="body1">
                                    <a href={`mailto: ${customer.salesRep.emailAddress}"`}>
                                        {customer.salesRep.emailAddress}
                                    </a>
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body1">
                                    <strong>Phone: </strong>
                                </Typography>
                                <Typography variant="body1">
                                    <a href={`tel: ${customer.salesRep.phoneNumber}`}>
                                        {formatPhone(customer.salesRep.phoneNumber)}
                                    </a>
                                </Typography>
                            </div>
                            {renderFact()}
                        </div>

                    </div>
                </PaddedCard>
            );
        } else {
            return <></>
        }
    }

    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}
        </>
    );
};

export default SalesRepInfo;