import NumberField from "../../global/NumberField";
import {Button, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {LOGISTICS_YARD_SHIP, LOGISTICS_WILL_CALL} from "../../../constants/Constants";
import React, {useContext, useEffect, useState} from "react";
import useOrderAPI from "../../../hooks/useOrderAPI";
import {OrderContext} from "../../../providers/OrderProvider";
import {WebsiteAvailability, WebsiteAvailabilityGroup} from "../../../types/ProductType";
import {NEW_ORDER} from "../../../types/OrderForAdd";
import {useHistory} from "react-router";
import {AuthContext} from "../../../providers/AuthProvider";

type AddToOrderFormProps = {
    defaultBranch: string;
    product: WebsiteAvailabilityGroup;
}

const AddToOrderForm = (props: AddToOrderFormProps) => {
    const { product, defaultBranch } = props;
    const { isConfiguredUser } = useContext(AuthContext);

    const { selectedShipmentForAdd, setSelectedShipmentForAdd, setAllAddToOptions } = useContext(OrderContext);
    const { addToOrder } = useOrderAPI();
    const history = useHistory();

    const [orderQty, setOrderQty] = useState(1);
    const [logistics, setLogistics] = useState(LOGISTICS_WILL_CALL);
    const [localAvail, setLocalAvail] = useState<WebsiteAvailability | undefined>();
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        setLocalAvail(product.websiteItemAvailabilities.find((x: any) => x.companyName === defaultBranch));
    }, [product, defaultBranch]);

    const handleAddToCart = (logisticsType: string) => {
        if(hasError()) {
            return;
        }

        document.body.style.cursor = 'wait';
        const cart = selectedShipmentForAdd ?? NEW_ORDER;

        addToOrder(cart, logisticsType, defaultBranch, product.itemCode, orderQty)
            .then((updatedOrder) => {
                document.body.style.cursor = 'auto';

                if(updatedOrder.message) {
                    //this is an error... but I don't want to leak that to the user
                    return;
                }

                setSelectedShipmentForAdd(updatedOrder);
                setAllAddToOptions((prevOptions) => {
                    const existingIndex = prevOptions.findIndex(option => option.orderId === updatedOrder.orderId);
                    if (existingIndex !== -1) {
                        const updatedOptions = [...prevOptions];
                        updatedOptions[existingIndex] = updatedOrder;
                        return updatedOptions;
                    } else {
                        return [...prevOptions, updatedOrder];
                    }
                });
                history.push('/products')
            });
    }

    const getErrorMessage = () => {
        const systemAvailable = localAvail?.systemAvailability ? Number(localAvail.systemAvailability) : 0;

        if(!touched) {
            return "";
        }

        if(orderQty < 1 || orderQty > systemAvailable) {
            return "Range: 1 - " + systemAvailable;
        }

        return "";
    }

    const hasError = () => {
        return getErrorMessage() !== "";
    }

    //function is defined and user is not configured
    if(isConfiguredUser && !isConfiguredUser()) {
        return <></>
    }

    if(Number(product.quantity) === 0) {
        return <></>
    }

    const handleLogisticsChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string ) => {
        if (newAlignment !== null) {
            setLogistics(newAlignment);
        }
    };

    return <div style={{display: "flex", flexDirection: "column"}}>

        <ToggleButtonGroup value={logistics} exclusive onChange={handleLogisticsChange} color={"primary"} >
            <ToggleButton value={LOGISTICS_WILL_CALL}>
                Will Call
            </ToggleButton>

            <ToggleButton value={LOGISTICS_YARD_SHIP} >
                Delivery
            </ToggleButton>
        </ToggleButtonGroup>

        <div style={{display: "flex", marginTop: 10, width: 170, justifyContent:"space-between"}}>
            <div style={{width: "65%"}}>
                <NumberField
                    size="small"
                    autoSelectTextOnFocus={true}
                    inError={hasError()}
                    errorMessage={getErrorMessage}
                    value={orderQty}
                    updateData={(value) => {
                        setTouched(true);
                        setOrderQty(value)
                    }}
                    onEnter={() => handleAddToCart(logistics)}
                    name={"orderQty"}
                    variant="outlined"
                />
            </div>
            <div style={{width: "30%"}}>
                <Button style={{marginTop: 8}}
                        fullWidth
                        variant={"contained"}
                        disabled={!localAvail?.systemAvailability || hasError()}
                        onClick={() => handleAddToCart(logistics)}>
                    Add
                </Button>
            </div>

        </div>
    </div>
}

export default AddToOrderForm;