import { Tooltip, Typography} from "@mui/material";
import {formatCurrencyRoundUp} from "../../../constants/GlobalFunctions";
import React from "react";

export type GiftCardCounterProps = {
    points: number;
}

const GiftCardCounter: React.FC<GiftCardCounterProps> = (props: GiftCardCounterProps) => {
    const getGiftCardValue = (sales: number) => {
        if(sales < 25000) {
            return 0;
        } else if(sales > 250000) {
            return 500;
        } else {
            return sales * .002;
        }
    }

    const cardValue = getGiftCardValue(props.points);
    const eligible = cardValue !== 0;
    const formattedCardValue = formatCurrencyRoundUp(cardValue);

    const message = eligible ? `Earn ${formattedCardValue} worth of cards.` : "Eligibility starts at 25,000 points.";

    return  <Tooltip
        title={"This starts at 25,000 points and scales to 250,000 points. Each point in this range is worth 0.2¢"}
        arrow
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -35],
                        },
                    },
                ],
            },
        }}
    >
        <div style={{marginTop: 10, textAlign: "center"}}>
            <Typography variant={"h6"}>Get Gift Cards For Your Team! </Typography>
            <Typography variant={"body1"}>{message}</Typography>
        </div>
    </Tooltip>
}

export default GiftCardCounter;