import React, {useContext} from "react";
import {OrderSelector} from "./OrderSelector";
import {AuthContext} from "../../providers/AuthProvider";
import {StylesContext} from "../../providers/StylesProvider";

function getNavBarHeight() {
    const navBar = document.getElementById('nav-bar');
    if (navBar) {
        return navBar.getBoundingClientRect().height;
    }
    return 0; // Return 0 if the element is not found
}

export const FloatingShipmentSelector = () => {
    const { isConfiguredUser } = useContext(AuthContext);
    const isDesktop = useContext(StylesContext).isDesktop ?? false;

    const navBarHeight = getNavBarHeight();

    //mobile temporarily disabled
    if(isConfiguredUser && isConfiguredUser() && isDesktop) {
        return (
            <div style={{ position: "fixed", top: navBarHeight, right: 0, backgroundColor: "#8DC63F", zIndex: 1000 }}>
                <div style={{padding: 12}}>
                    <OrderSelector/>
                </div>
            </div>
        );
    } else {
        return <> </>;
    }


}