import {IconButton, TextField, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import {DataGridPro} from "@mui/x-data-grid-pro";
import usePhotoUtil from "../../../hooks/usePhotoUtil";
import {CUSTOMER_NAME, formatter} from "../../../constants/Constants";
import {OrderLineItemType} from "../../../types/OrderLineItemType";
import {DEFAULT_PHOTO} from "../../LoggedOut/PhotoGallery";
import {useHistory} from "react-router";
import {SpecificOrderContext} from "../../../providers/SpecificOrderContext";
import {datadogLogs} from "@datadog/browser-logs";
import NumberField from "../../global/NumberField";
import {OrderContext} from "../../../providers/OrderProvider";

export type OrderLineItemTableProps = {
    orderId: number;
    shipmentId: number;
    orderLineItems: OrderLineItemType[];
    setOrderLineItems: (items: OrderLineItemType[]) => void;
}

const OrderLineItemTable = (props: OrderLineItemTableProps) => {
    const { setSelectedShipmentForAdd } = useContext(OrderContext);
    const { setMainToastMessage, setMainToastOpen, setMainToastSeverity } = useContext(SpecificOrderContext);
    const { orderId, shipmentId, orderLineItems, setOrderLineItems } = props;
    const { urlToFile } = usePhotoUtil();
    const history = useHistory();

    const [loadingTable, setLoadingTable] = useState(true);
    const [shadowOrderLineItems, setShadowOrderLineItems] = useState<{ [key: number]: OrderLineItemType}>({})

    const mapOrderLineItemsById = useCallback((orderLineItems: OrderLineItemType[]): { [key: number]: OrderLineItemType } => {
        return orderLineItems.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {} as { [key: number]: OrderLineItemType });
    }, []);

    useEffect(() => {
        if(!orderId || !urlToFile) {
            return;
        }

        setLoadingTable(true);
        setOrderLineItems([]);
        const shipmentFilter = shipmentId ? `&shipmentId=${shipmentId}` : "";
        const encodedCustomerName = encodeURIComponent(localStorage.getItem(CUSTOMER_NAME) as string);

        fetch(`/api/orders/${orderId}/line-items?customerName=${encodedCustomerName}${shipmentFilter}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        }).then(response => {
            if(!response.ok) {
                return [];
            } else {
                return response.json();
            }
        })
        .then(data => data.filter((item: OrderLineItemType) => item.sourceItemCode !== null))
        .then((data: OrderLineItemType[]) => {
            Promise.all(data.map(lineItem => {
                const url = lineItem.imageFile ? lineItem.imageFile : DEFAULT_PHOTO.url

                return urlToFile(url, "preview_" + lineItem.id, "image/jpeg")
            }))
            .then(files => {
                const newRows: OrderLineItemType[] = files.map((file, index) => {
                    const oldLineItem = data[index];

                    return {...oldLineItem, preview: file};
                });
                setOrderLineItems(newRows);
                setShadowOrderLineItems(mapOrderLineItemsById(newRows));
                setLoadingTable(false);
            });
        });
    }, [urlToFile, orderId, shipmentId, setOrderLineItems, mapOrderLineItemsById, setShadowOrderLineItems]);

    const openProductPage = (itemCode: string) => {
        history.push(`/products/${itemCode}`);
    }

    const handleCellDoubleClick = (params: any) => {
        if (!params.colDef.editable) {
            openProductPage(params.row.sourceItemCode);
        }
    }

    const updateLineItem = (params: any) => {
        // Validate the new value
        if (params.field === 'sourceItemQuantity' && params.value < 0) {
            setMainToastMessage("Quantity cannot be negative.");
            setMainToastSeverity("error");
            setMainToastOpen(true);
            return;
        }

        const originalLine = orderLineItems.find(item => item.id === params.id);
        if(originalLine === undefined) {
            setMainToastMessage("Strange, we can't find that line item. Please refresh the page and try again.");
            setMainToastSeverity("error");
            setMainToastOpen(true);
            setOrderLineItems([...orderLineItems]);
            return;
        }

        let newValue, oldValue: any;
        if(params.field === 'sourceItemQuantity') {
            // bc of shenanigans with how I want to use a NumberField for edits (for consistent validation)
            // I have to store the value in the shadowOrderLineItems object and then update the originalLine object on commit
            newValue = shadowOrderLineItems[params.id].sourceItemQuantity;
            oldValue = params.value;
        }  else {
            newValue = params.value;

            // @ts-ignore
            oldValue = originalLine[params.field];
        }

        // @ts-ignore
        originalLine[params.field] = newValue; //update for local state
        setOrderLineItems([...orderLineItems]); //update for parent.

        // Call off to the backend to update the value
        fetch(`/api/orders/${orderId}/shipment/${originalLine.deliveryId}/line-items/${originalLine.id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({ customerName: localStorage.getItem(CUSTOMER_NAME), [params.field]: newValue }),
        })
        .then(response => {
            if (!response.ok) {
                if(response.status === 401) {
                    //redirect to login
                    history.push("/login");
                } else {
                    return response.json().then(errorData => {
                        const message = errorData.message ? errorData.message : "Oops! Something went wrong. Please reach out if this problem continues.";
                        setMainToastMessage(message);
                        setMainToastSeverity("error");
                        setMainToastOpen(true);

                        const modifiedLine = orderLineItems.find(item => item.id === params.id);

                        // @ts-ignore
                        modifiedLine[params.field] = oldValue;
                        setOrderLineItems([...orderLineItems]);
                    });
                }
            }
        })
        .catch(() => {
            setMainToastMessage("Oops! Something went wrong. Please reach out if this problem continues.");
            setMainToastSeverity("error");
            setMainToastOpen(true);

            const modifiedLine = orderLineItems.find(item => item.id === params.id);

            // @ts-ignore
            modifiedLine[params.field] = oldValue;
            setOrderLineItems([...orderLineItems]);
        });

    }

    const deriveTooltip = (status: string) => {
        switch(status) {
            case "OPEN":
            case "NO_AUTO_ADD":
                return "";
            case "COMMITTED":
                return "This item is in a committed shipment. Please reach out to your sales rep to make changes.";
            case "SHIPPED":
                return "This item is on its way to you!";
            case "INVOICED":
                return "This item has been delivered and is no longer editable.";
            default:
                return "This item is no longer editable. Please reach out to your sales rep with any concerns.";
        }
    }

    const cancelOrderLineItem = (deliveryId: number, lineItemId: number) => {
        const backupOrderLineItems = [...orderLineItems];
        setOrderLineItems(orderLineItems.filter(item => item.id !== lineItemId));

        fetch(`/api/orders/${orderId}/shipment/${deliveryId}/line-items`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({customerName: localStorage.getItem(CUSTOMER_NAME), lineItemId: lineItemId})
        })
        .then(response => {
            if(!response.ok) {
                return response.json().then(errorData => {
                    const message = errorData.message ? errorData.message : "Oops! Something went wrong. Please reach out if this problem continues.";

                    setMainToastMessage(message);
                    setMainToastSeverity("error");
                    setMainToastOpen(true);
                    datadogLogs.logger.error("Failed to cancel order line item - deliveryId " + deliveryId + " id " + lineItemId);

                    setOrderLineItems(backupOrderLineItems);
                });
            } else {
                return response.json().then((lineItemAddedResponse) => {
                    setSelectedShipmentForAdd(prev => {
                        return {...prev, uncommittedLineItemCount: lineItemAddedResponse.uncommittedLineItemCount};
                    })
                })
            }
        })
    }

    const columns = [
        {
            field: 'preview',
            headerName: 'Preview',
            width: 150,
            renderCell: (params: any) => params.value && <img src={URL.createObjectURL(params.value)} alt="preview" width="140"  />,
            editable: false,
        },
        {  field: 'sortOrder', headerName: 'Line #', width: 60 },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.3,
            editable: false,
            renderCell: (params: any) => {
                return <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    <div>{params.row.latinName}</div>
                    <div>{params.row.commonName}</div>
                </div>
            },
        },
        {
            field: 'effectivePrice',
            headerName: 'Price',
            flex: .4,
            editable: false,
            valueFormatter: (params: any) => formatter.format(params.value as number)
        },
        {
            field: 'sourceItemQuantity',
            headerName: 'Qty',
            flex: .3,
            editable: true,
            renderEditCell: (params: any) => {
                return <NumberField name={""}
                                    value={shadowOrderLineItems[params.id].sourceItemQuantity}
                                    disableUnderline={true}
                                    updateData={(value) => {
                                        shadowOrderLineItems[params.id].sourceItemQuantity = value;
                                        setShadowOrderLineItems({...shadowOrderLineItems});
                                    }}
                                    autoSelectText={true}
                                    size="small" />
            }
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: .5,
            editable: false,
            renderCell: (params: any) => {
                const frontEndValue = params.row.sourceItemQuantity * params.row.effectivePrice;

                return <div>
                    {formatter.format(frontEndValue as number)}
                </div>

            }
        },
        {
            field: 'comment',
            headerName: 'Comment',
            flex: 1,
            editable: true,
            renderEditCell: (params: any) => {
                return <div style={{margin: 10}}>
                    <TextField
                        type="text"
                        value={params.value}
                        variant={"standard"}
                        onChange={(event) => {
                            const newValue = event.target.value;
                            if (newValue.length <= 29) {
                                params.api.setEditCellValue({id: params.id, field: params.field, value: newValue});
                            }
                        }}
                        InputProps={{disableUnderline: true, inputProps: {autoFocus: true}}}
                        style={{whiteSpace: 'normal', wordWrap: 'break-word'}}
                        multiline
                    />
                </div>
            },
            renderCell: (params: any) => {
                return <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {params.value}
                </div>
            },
        },
        {
            field: 'deliveryId',
            headerName: 'Delivery',
            width: 70,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 70,
            renderCell: (params: any) => {
                const isDisabled = params.row.status !== "OPEN" && params.row.status !== "NO_AUTO_ADD";
                const tooltipTitle = deriveTooltip(params.row.status);

                return <Tooltip title={tooltipTitle}>
                    <span>
                        <IconButton disabled={isDisabled}
                                    onClick={() => cancelOrderLineItem(params.row.deliveryId, params.row.id)}>
                            <DeleteIcon/>
                        </IconButton>
                    </span>
                </Tooltip>
            }
        },
    ];

    return <>
        <DataGridPro
            rows={orderLineItems}
            autoHeight={true}
            rowHeight={210}
            columns={columns}
            loading={loadingTable}
            onCellDoubleClick={handleCellDoubleClick}
            onCellEditCommit={updateLineItem}
            isCellEditable={(params) => {
                if (params.field === 'sourceItemQuantity') {
                    return params.row.status === "OPEN" || params.row.status === "NO_AUTO_ADD";
                } else if (params.field === 'comment') {
                    return params.row.status !== "INVOICED";
                }
                return false;
            }}
            hideFooter
        />
    </>;
}

export default OrderLineItemTable;

