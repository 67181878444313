import { OrderEO } from "../OrderInformation";
import {Card, CardHeader, TextField} from "@mui/material";
import React, {ChangeEvent} from "react";
import useDateUtil from "../../../../hooks/useDateUtil";
import {LOGISTICS_WILL_CALL} from "../../../../constants/Constants";
import ShipmentEO from "../ShipmentEO";
import NumberField from "../../../global/NumberField";

export type ReviewOrderInfoCardProps = {
    orderId: number;
    useDefaultLogistics: boolean;
    orderInformation: OrderEO;
    setOrderInformation: (order: OrderEO) => void;
    updateOrderInfo: (event: React.ChangeEvent<HTMLInputElement>) => void;
    postFreight: (updatedOrderInfo: OrderEO, updatedDefaultLogisticsFlag: boolean) => void;
    isInvalid: (fieldName: string) => boolean;
    getErrorMessage: (fieldName: string) => string;
}

const ReviewOrderInfoCard: React.FC<ReviewOrderInfoCardProps> = (props: ReviewOrderInfoCardProps) => {
    const { orderInformation, updateOrderInfo, useDefaultLogistics, setOrderInformation } = props;
    const { asDate } = useDateUtil();

    const nonWillCallDeliveries = orderInformation.deliveries.filter((delivery) => delivery.type !== LOGISTICS_WILL_CALL);

    const postFreight = () => {
        if(!useDefaultLogistics) {
            return;
        }

        const newDeliveries = orderInformation.deliveries.map((shipment) => {
            if(shipment.type === LOGISTICS_WILL_CALL) {
                return {...shipment, landDate: orderInformation.defaultShippingDate} as ShipmentEO
            } else {
                return {...shipment, landDate: orderInformation.defaultShippingDate, destination: orderInformation.defaultShippingAddress} as ShipmentEO;
            }

        })

        const updatedOrderInfo = {...orderInformation, deliveries: newDeliveries}
        props.postFreight(updatedOrderInfo, useDefaultLogistics);
    }

    return (
        <Card style={{marginTop: 10}}>
            <CardHeader title={"Please review your order information."}/>
            <div style={{marginLeft: 15, marginRight: 15, marginBottom: 15}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <div style={{display: "flex", width: "66%", flexDirection: "column"}}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <TextField
                                variant="standard"
                                required
                                autoFocus
                                name="onSiteContactName"
                                label="On Site Contact Name"
                                style={{width: "32%"}}
                                onChange={updateOrderInfo}
                                error={props.isInvalid("onSiteContactName")}
                                helperText={props.getErrorMessage("onSiteContactName")}
                                value={orderInformation.onSiteContactName ?? ""}
                            />
                            <NumberField
                                style={{width: "32%"}}
                                margin={"none"}
                                size={"medium"}
                                value={orderInformation.onSiteContactPhone ?? ""}
                                updateData={(value) => setOrderInformation({
                                    ...orderInformation,
                                    onSiteContactPhone: "" + value
                                })}
                                inError={props.isInvalid("onSiteContactPhone")}
                                errorMessage={() => props.getErrorMessage("onSiteContactPhone")}
                                name={"onSiteContactPhone"}
                                label={"On Site Contact Phone"}
                                variant="standard"
                            />
                            <div style={{width: "30%"}}>
                                <TextField
                                    label="Requested Land Date"
                                    name="defaultShippingDate"
                                    type="date"
                                    variant={"standard"}
                                    required
                                    value={asDate(orderInformation.defaultShippingDate) || ""}
                                    error={props.isInvalid("defaultShippingDate")}
                                    helperText={props.getErrorMessage("defaultShippingDate").replace("Default Shipping Date", "Requested Date")}
                                    onChange={updateOrderInfo}
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20}}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name="jobName"
                                label="Job Name"
                                style={{width: "67%"}}
                                onChange={updateOrderInfo}
                                value={orderInformation.jobName ?? ""}
                            />
                            <TextField
                                variant="standard"
                                name="poNumber"
                                label="PO Number"
                                style={{width: "30%"}}
                                onChange={updateOrderInfo}
                                value={orderInformation.poNumber ?? ""}
                            />
                        </div>

                    </div>
                    <div style={{display: "flex", width: "32%", flexDirection: "column"}}>
                        <TextField
                            variant="standard"
                            required
                            fullWidth
                            name="defaultShippingAddress"
                            label="Default Shipping Address"
                            disabled={nonWillCallDeliveries.length === 0}
                            value={orderInformation?.defaultShippingAddress ?? ""}
                            error={props.isInvalid("defaultShippingAddress")}
                            helperText={props.getErrorMessage("defaultShippingAddress")}
                            onChange={updateOrderInfo}
                            onBlur={postFreight}
                            multiline
                            rows={4}
                        />
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ReviewOrderInfoCard;