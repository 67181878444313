
export type ShipmentEO = {
    id: number,
    orderId: number;
    vendor: string,
    status: string,
    name: string,
    type: string,
    destination: string,
    landDate: string,
    subtotal: number,
    freight: number,
    zoneFreightType: string,
    zone: string,
    subZone: string,
    salesTax: number,
    total: number,
    selected: boolean | undefined,
}

export const DEFAULT_SHIPMENT_EO: ShipmentEO = {
    id: -1,
    orderId: -1,
    vendor: "",
    status: "",
    name: "",
    type: "",
    destination: "",
    landDate: "",
    subtotal: 0,
    freight: 0,
    zoneFreightType: "",
    zone: "",
    subZone: "",
    salesTax: 0,
    total: 0,
    selected: false,
}

export default ShipmentEO;