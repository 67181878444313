import { styled } from "@mui/material/styles";
import {
  Container,
  Button,
} from "@mui/material";
import React, {useEffect} from "react";
import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { StylesContext } from "../../providers/StylesProvider";
import AccountBalanceCard from "../guiComponents/payments/AccountBalanceCard";
import SalesRepInfo from "../guiComponents/SalesRepInfo";
import Alert from "@mui/material/Alert";
import ShipmentsGrid from "./orders/ShipmentsGrid";

const PREFIX = "OrderManagement";

const classes = {
  orderManagement: `${PREFIX}-orderManagement`,
  shipments: `${PREFIX}-shipments`,
  orderLineItems: `${PREFIX}-orderLineItems`,
  orderLineItemsPhone: `${PREFIX}-orderLineItemsPhone`,
  searchBar: `${PREFIX}-searchBar`,
  totalBar: `${PREFIX}-totalBar`,
  totalBarPhone: `${PREFIX}-totalBarPhone`,
  headerText: `${PREFIX}-headerText`,
  headerContent: `${PREFIX}-headerContent`,
  headerLine: `${PREFIX}-headerLine`,
  dataGridPhone: `${PREFIX}-dataGridPhone`,
};

const Root = styled("div")({
  [`& .${classes.orderManagement}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  [`& .${classes.shipments}`]: {
    flexGrow: 1,
    margin: 10,
    marginBottom: 30,
    minHeight: "700px",
    maxHeight: "700px",
  },
  [`& .${classes.orderLineItems}`]: {
    flexGrow: 2,
    margin: 10,
    minHeight: "600px",
    maxHeight: "600px",
  },
  [`& .${classes.orderLineItemsPhone}`]: {
    flexGrow: 2,
    minHeight: "500px",
    maxHeight: "500px",
  },
  [`& .${classes.searchBar}`]: {
    margin: 10,
    width: "100%",
  },
  [`& .${classes.totalBar}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  [`& .${classes.totalBarPhone}`]: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    margin: 10,
  },
  [`& .${classes.headerText}`]: {
    fontWeight: "bold",
    fontSize: "12pt",
  },
  [`& .${classes.headerContent}`]: {
    fontSize: "12pt",
    marginRight: "10px",
  },
  [`& .${classes.headerLine}`]: {
    display: "inline-flex",
  },
  [`& .${classes.dataGridPhone}`]: {
    marginRight: 10,
    marginLeft: 10,
  },
});

const OrderManagement = () => {

  const auth = useContext(AuthContext);
  const customer = auth.user?.customer;
  const { navBarHeight, isDesktop } = useContext(StylesContext);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderNotVerified = () => {
    return (
      <Container>
        <div style={{ paddingTop: navBarHeight }}>
          <h1>Order Management</h1>
          {auth.user?.customer?.fullName || auth.userCustomers ? (
            <hr />
          ) : (
              <>
                <Alert severity={"warning"}>Thank you for registering! We're double checking a few things on our end and
                  will link you to the correct customer shortly. Please check back tomorrow.</Alert>
                <br/>
                <Alert severity={"info"}>While you wait, you're welcome to browse <a href={"/products"}>our
                  inventory</a>! The displayed pricing is for basic contractors. If your business qualifies for volume
                  pricing, it will update once your account is linked. </Alert>
              </>
          )}
        </div>
      </Container>
    );
  };

  if (!auth.user?.customer?.fullName && !auth.userCustomers) {
    return renderNotVerified();
  }

  const renderInProgressOrders = () => {
    return (
        <>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <h1>In Progress Orders</h1>

          </div>
          <ShipmentsGrid showSearchBar={false} acceptableStatuses={["COMMITTED", "PULLING", "SHIPPED"]} />
        </>
    )
  }

  const renderOrderList = () => {
    const buttonTxt = isDesktop ? "Start Shopping Now!" : "Shop Now!"

    return <>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <h1>Order Management</h1>
        <div>
          <div>
            <Button onClick={() => { history.push("/products")}} style={{marginRight: 10}} variant="contained" color="primary">
              {buttonTxt}
            </Button>
          </div>

        </div>
      </div>
      <ShipmentsGrid showSearchBar={true} acceptableStatuses={[]} />
    </>
  }

  const renderDesktop = () => {
    return <Container>
      <Root style={{paddingTop: navBarHeight}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "stretch"}}>
          <div style={{flex: 1}}>
            <AccountBalanceCard showPaymentLink={true}/>
          </div>
          <div style={{flex: customer?.salesRep?.name ? 1 : 0}}>
            <SalesRepInfo/>
          </div>
        </div>
        {renderInProgressOrders()}
        {renderOrderList()}
        <div style={{height: 30}}/>
      </Root>
    </Container>
  }

  const renderMobile = () => {
      return <>
        <Root style={{paddingTop: navBarHeight}}>
          <div style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
            <div style={{flex: 1}}>
              <AccountBalanceCard showPaymentLink={true}/>
            </div>
            <div style={{flex: customer?.salesRep?.name ? 1 : 0}}>
              <SalesRepInfo/>
            </div>
          </div>

          <Container>
            {renderInProgressOrders()}
            {renderOrderList()}
            <div style={{height: 70}}/>
          </Container>
        </Root>
      </>
  }

  return <>
    {isDesktop ? renderDesktop() : renderMobile()}
  </>
};

export default OrderManagement;
