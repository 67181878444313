import React, {Dispatch, SetStateAction, useCallback, useContext, useEffect} from "react";
import { useState } from "react";
import Axios from "axios";
import { AuthContext } from "./AuthProvider";
import {PaymentMethod} from "../types/CardDataType";
import {JobLine} from "../components/authComponents/registration/validators/jobLineApplication/JobLine";

export type CustomerPropertiesType = {
  paymentMethodsWithNicknames: PaymentMethod[] | undefined;
  defaultSavedPaymentMethod: PaymentMethod | undefined;
};

export type CustomerFunctionsType = {
  fetchLoyaltyPoints: (customerName: string, inLoyaltyProgram: boolean | undefined) => Promise<number>;
  savePaymentMethod: (paymentMethod: PaymentMethod) => Promise<PaymentMethod>;
  fetchSavedPaymentMethods: () => Promise<PaymentMethod[]>;
  setPaymentMethodsWithNicknames: Dispatch<SetStateAction<PaymentMethod[]>>;
  setDefaultSavedPaymentMethod: Dispatch<SetStateAction<PaymentMethod | undefined>>;
  deletePaymentMethod: (card: PaymentMethod) => Promise<void>;
  updatePaymentMethod: (card: PaymentMethod) => Promise<void>;
  getParentCustomerName: (customerName: string) => string;
  customerName: string;
  parentCustomerName: string;
    fetchJobLines: (customerName: string) => Promise<JobLine[]>;
};

export const CustomerContext = React.createContext<
  Partial<CustomerPropertiesType & CustomerFunctionsType>
>({});

export const CustomerProvider = (props: any) => {
  const { user } = useContext(AuthContext);

  const [customerName, setCustomerName] = useState<string>("");
  const [parentCustomerName, setParentCustomerName] = useState<string>("");

  const getParentCustomerName = useCallback((customerName: string) => {
    if(customerName.includes(":")) {
      return customerName.split(":")[0];
    } else {
      return customerName;
    }
  }, []);

  useEffect(() => {
    if(user?.customer?.fullName) {
      setCustomerName(user.customer.fullName);
      setParentCustomerName(getParentCustomerName(user.customer.fullName));
    }
  }, [user, getParentCustomerName]);

  const fetchLoyaltyPoints = async (customerName: string, inLoyaltyProgram: boolean | undefined) => {
    if (inLoyaltyProgram) {
      return fetch("/api/loyaltypoints?" + new URLSearchParams({customerName: customerName}), {
        headers: {
          Authorization: localStorage.getItem("token") ?? undefined,
        } as HeadersInit,
      })
          .then((res) => {
            if (!res.ok) {
              throw new Error("Network response was not ok");
            }
            return res.json();
          })
          .then((data) => {
            return data.points;
          })
          .catch((error) => {
            console.error(
                "There has been a problem with your fetch operation:",
                error
            );

            return 0;
          });
    } else {
      return 0;
    }
  };

  const [paymentMethodsWithNicknames, setPaymentMethodsWithNicknames] = useState<
    PaymentMethod[]
  >([]);
  const [defaultSavedPaymentMethod, setDefaultSavedPaymentMethod] = useState<
    PaymentMethod | undefined
  >();

  const fetchSavedPaymentMethods = async () => {
    let parentCustomerName = getParentCustomerName(user?.customer?.fullName ?? "");
    let encodedCustomerName = encodeURIComponent(parentCustomerName);
    let encodedEntityName = encodeURIComponent(user?.customer?.entityName ?? "Central");

    return fetch(`/api/payment-method?entityName=${encodedEntityName}&customerName=${encodedCustomerName}`, {
      headers: {
        Authorization: localStorage.getItem("token") ?? undefined,
        Accept: "application/json",
        "Content-Type": "application/json",
      } as HeadersInit,
    }).then((res) => {
      if(!res.ok) {
        throw new Error("Network response was not ok");
      }

      return res.json();
    }).catch((error) => {
      console.log("There has been a problem with your fetch operation:", error);

      return [];
    });
  };

  const savePaymentMethod = async (paymentMethod: PaymentMethod): Promise<PaymentMethod> => {
    const simplifiedCard = { ...paymentMethod, cvcVerification: null, created: null, updated: null};

    let parentCustomerName = getParentCustomerName(user?.customer?.fullName ?? "");
    let encodedCustomerName = encodeURIComponent(parentCustomerName);

    return fetch(`/api/payment-method/${paymentMethod.type}?customerName=${encodedCustomerName}`, {
      method: "POST",
        headers: {
            Authorization: localStorage.getItem("token") ?? undefined,
            Accept: "application/json",
            "Content-Type": "application/json",
        } as HeadersInit,
        body: JSON.stringify(simplifiedCard),
    }).then((res) => {
        return res.json();
    }).catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);
    });
  }

  const updatePaymentMethod = async (paymentMethod: PaymentMethod) => {
    const simplifiedCard = { ...paymentMethod, cvcVerification: null, created: null, updated: null};

    let parentCustomerName = getParentCustomerName(user?.customer?.fullName ?? "");
    let encodedCustomerName = encodeURIComponent(parentCustomerName);

    return fetch(`/api/payment-method/${paymentMethod.type}?customerName=${encodedCustomerName}`, {
      method: "PUT",
        headers: {
            Authorization: localStorage.getItem("token") ?? undefined,
            Accept: "application/json",
            "Content-Type": "application/json",
        } as HeadersInit,
        body: JSON.stringify(simplifiedCard),
    }).then((res) => {
        if(!res.ok) {
            throw new Error("Network response was not ok");
        }

        return res.json();
    }).catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);

    });
  }

  const deletePaymentMethod = async (paymentMethod: PaymentMethod) => {
    let encodedCustomerName = encodeURIComponent(user?.customer?.fullName!);
    let encodedEntityName = encodeURIComponent(user?.customer?.entityName ?? "Central");

    const config = {
      headers: {
        authorization: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      await Axios.delete(
        `/api/payment-method/${paymentMethod.type}?entityName=${encodedEntityName}&customerName=${encodedCustomerName}&cardId=${paymentMethod.id}`,
        config
      );
    } catch (error) {}
  };

  const fetchJobLines = useCallback((customerName: string) => {
    const parentCustomerName = getParentCustomerName ? getParentCustomerName(customerName) : customerName;
    const encodedCustomerName = encodeURIComponent(parentCustomerName);

    return fetch(`/api/customer/${encodedCustomerName}/job-lines`, {
      headers: {
          Authorization: localStorage.getItem("token") ?? undefined,
        } as HeadersInit,
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .catch((error) => {
        console.error(
            "There has been a problem with your fetch operation:",
            error
        );
      });
  }, [getParentCustomerName]);

  const customer: CustomerPropertiesType & CustomerFunctionsType = {
    fetchLoyaltyPoints,
    fetchSavedPaymentMethods,
    savePaymentMethod,
    setPaymentMethodsWithNicknames,
    deletePaymentMethod,
    updatePaymentMethod,
    setDefaultSavedPaymentMethod,
    paymentMethodsWithNicknames,
    defaultSavedPaymentMethod,
    getParentCustomerName,
    customerName,
    parentCustomerName,
    fetchJobLines
  };

  return (
    <CustomerContext.Provider value={customer}>
      {props.children}
    </CustomerContext.Provider>
  );
};
