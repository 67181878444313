import {CardContent, CardHeader, Typography} from "@mui/material";
import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {useContext} from "react";
import {StylesContext} from "../../../providers/StylesProvider";

export const RACHEL_SHOCKEY: AccountReceivableSpecialist = {
    firstName: "Rachel",
    name: "Rachel Shockey",
    email: "rachel@treesource.com",
    phone: "208-530-5024",
    imageUrl: "https://res.cloudinary.com/treesource-systems/image/upload/c_mfit,h_100,w_150/v1711506946/moreSquare_xt1d1y.jpg"
}

type AccountReceivableSpecialist = {
    firstName: string;
    name: string;
    email: string;
    phone: string;
    imageUrl: string;
}

type AccountReceivableSpecialistInfoProps = {
    arSpecialist: AccountReceivableSpecialist;
    padded?: boolean;
    title: string;
}

const AccountReceivableSpecialistInfo = (props: AccountReceivableSpecialistInfoProps) => {
    const { arSpecialist } = props; //destructuring
    const { isDesktop } = useContext(StylesContext);

    const padded = props.padded ?? true;

    const renderDesktop = () => {
        return (
            <PaddedCard style={{margin: padded ? 10 : 0, display: "flex", justifyContent: "space-between", alignItems: "flex-start", height: "90%", minHeight: 200}} >
                <div style={{width: "68%"}}>
                    <CardHeader title={props.title} />
                    <CardContent>
                        <Typography variant="body1">
                            <strong>AR Specialist: </strong> {arSpecialist.name}
                        </Typography>
                        <Typography variant="body1">
                            <strong> Email: </strong>
                            <a href={`mailto: ${arSpecialist.email}"`}>
                                {arSpecialist.email}
                            </a>
                        </Typography>
                        <Typography variant="body1">
                            <strong>Phone: </strong>
                            <a href={`tel: ${arSpecialist.phone}`}>
                                {arSpecialist.phone}
                            </a>
                        </Typography>
                    </CardContent>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", height: 50, width: "32%"}}>
                    <img style={{width: 150}} src={arSpecialist.imageUrl} alt={"AR Specialist"}/>
                </div>
            </PaddedCard>
        );
    }

    const renderMobile = () => {
        return (
            <PaddedCard style={{ margin: padded ? 10 : 0, display: "flex", flexDirection: "column", alignItems: "stretch", height: "90%"}} >
                <CardHeader title={props.title}  />
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "40%",
                        marginRight: 5,
                        marginLeft: 15,
                        marginBottom: 20
                    }}>
                        <img style={{width: 150, height: 218}} src={arSpecialist.imageUrl} alt={"AR Specialist"}/>
                    </div>
                    <div style={{width: "60%", minHeight: 217}}>
                        <CardContent>
                            <div>
                                <Typography variant="body1">
                                    <strong>AR Specialist: </strong>
                                </Typography>
                                <Typography variant="body1">
                                    {arSpecialist.name}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body1">
                                    <strong> Email: </strong>
                                </Typography>
                                <Typography variant="body1">
                                    <a href={`mailto: ${arSpecialist.email}"`}>
                                        {arSpecialist.email}
                                    </a>
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body1">
                                    <strong>Phone: </strong>
                                </Typography>
                                <Typography variant="body1">
                                    <a href={`tel: ${arSpecialist.phone}`}>
                                        {arSpecialist.phone}
                                    </a>
                                </Typography>
                            </div>
                        </CardContent>
                    </div>

                </div>
            </PaddedCard>
        );
    }

    return <>
        {isDesktop ? renderDesktop() : renderMobile()}
    </>

};

export default AccountReceivableSpecialistInfo;