import {useCallback, useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Container} from "@mui/material";
import {DEFAULT_PHOTO, Photo, PhotoGallery} from "./PhotoGallery";
import {PhotoInfo} from "./PhotoInfo";
import {ProductInfo} from "./ProductInfo";
import {StylesContext} from "../../providers/StylesProvider";
import TreeSourceLoad from "../Loaders/TreeSourceLoad";
import ItemBasePhotoCard from "../internal/ItemBasePhotoCard";
import {WebsiteAvailabilityGroup} from "../../types/ProductType";
import {AuthContext, AuthFunctionsType, AuthPropertiesType} from "../../providers/AuthProvider";
import {CUSTOMER_NAME, DEFAULT_BRANCH} from "../../constants/Constants";
import {FloatingShipmentSelector} from "../global/FloatingShipmentSelector";

export const DEFAULT_PRODUCT: WebsiteAvailabilityGroup = {
    botanicalName: "",
    commonName: "",
    size: "",
    id: "",
    alu: "",
    quantity: "0",
    quantityOnPO: "0",
    yardPrice: 0,
    companyName: "",
    itemCode: "",
    category: "",
    leafType: "",
    rootBall: "",
    growthRate: "",
    zoneMax: 0,
    zoneMin: 0,
    matureHeightInFeet: 0,
    matureSpreadInFeet: 0,
    bloomStartDate: "",
    bloomEndDate: "",
    bloomDesc: "",
    sun: "",
    water: "",
    maintenance: "",
    suggestedUse: "",
    tags: "", //I'm not using this property yet because I don't think it looks super professional
    color: "",
    availabilityTimeframe: "",
    searchScore: 0,
    imageLink: "",
    shipmentType: undefined,
    priceFloor: 0,
    websiteItemAvailabilities: [],
}

export const Product = () => {
    const { itemCode } = useParams<{ itemCode: string }>();
    const [ALU, setALU] = useState<string>("");
    const [selectedPhoto, setSelectedPhoto] = useState<Photo>(DEFAULT_PHOTO)
    const [productReady, setProductReady] = useState(false);
    const [pageReady, setPageReady] = useState(false);
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [hideItemBaseConfig, setHideItemBaseConfig] = useState(true);
    const [product, setProduct] = useState<WebsiteAvailabilityGroup>(DEFAULT_PRODUCT);

    const { isDesktop, navBarHeight } = useContext(StylesContext);
    const auth = useContext(AuthContext);


    useEffect(() => {
        //TODO: I'm sure these lookups can be extracted and done better. Another night perhaps.
        const entityName = lookupSelectedBranch(auth);
        const customerName = lookupCustomerName(auth);

        fetchWebsiteAvailabilityGroup(itemCode,
            entityName,
            customerName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchWebsiteAvailabilityGroup = async (itemCode: string, branchName: string, customerName: string) => {
        const params = new URLSearchParams({
            itemCode: itemCode,
            entityName: branchName,
            customerName: customerName
        });

        fetch(`/api/products/item?` + params, {
            headers: {
                authorization: localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            } as HeadersInit
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((data) => {
                setProduct(data);
                setALU(data.alu);
                setProductReady(true);
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    useEffect(() => {
        setPageReady(photos.length > 0 && productReady);
    }, [photos.length, productReady]);

    //Configured users get defaulted to their local branch. Unconfigured users get to choose a local branch.
    const lookupSelectedBranch = (auth: Partial<AuthPropertiesType & AuthFunctionsType>) => {
        const defaultBranch = auth.user?.userCustomerAssociation?.defaultLocalBranch;

        if(defaultBranch && !isLoggedOut(auth)) {
            return defaultBranch;
        } else {
            return auth.loggedOutLocation ?? DEFAULT_BRANCH;
        }
    }

    const lookupCustomerName = (auth: Partial<AuthPropertiesType & AuthFunctionsType>): string => {
        if(isLoggedOut(auth) || auth.user?.customer?.entityName === undefined) {
            return "unverified";
        } else {
            return localStorage.getItem(CUSTOMER_NAME) ?? "unverified";
        }
    }

    const isLoggedOut = (auth: Partial<AuthPropertiesType & AuthFunctionsType>) => {
        const storedToken = localStorage.getItem("token");

        //if we're not properly configured, we need to behave as unauthenticated.
        if(!storedToken || !auth.isTokenExpired) {
            return true;
        } else {
            return auth.isTokenExpired();
        }
    }

    const renderDesktop = () => {
        const test = navBarHeight!! -10 //+ 15
        return <div style={{ marginTop: test}}>
                    <FloatingShipmentSelector />
                    <ItemBasePhotoCard ALU={ALU} hide={hideItemBaseConfig} setHide={setHideItemBaseConfig} photos={photos} callRefresh={refresh} />
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <div>
                            <PhotoGallery itemcode={itemCode}
                                          selectedProduct={product}
                                          setSelectedPhoto={setSelectedPhoto}
                                          pageReady={pageReady}
                                          photos={photos}
                                          setPhotos={setPhotos}  />

                        </div>
                        <div>
                            <ProductInfo selectedProduct={product}
                                         defaultBranch={lookupSelectedBranch(auth)}
                                         isLoggedOUt={isLoggedOut(auth)}
                                         pageReady={pageReady} />
                            <PhotoInfo photo={selectedPhoto} pageReady={pageReady} setHideItemBaseConfig={setHideItemBaseConfig} />
                        </div>
                    </div>
                </div>
    }

    const refresh = useCallback(() => {
        setPhotos([]);
    }, [setPhotos]);

    const renderMobile = () => {
        return <Container style={{marginTop: 65}}>
                    <FloatingShipmentSelector />
                    <PhotoGallery itemcode={itemCode}
                                  selectedProduct={product}
                                  setSelectedPhoto={setSelectedPhoto}
                                  pageReady={pageReady}
                                  photos={photos}
                                  setPhotos={setPhotos} />
                    <PhotoInfo photo={selectedPhoto} pageReady={pageReady} setHideItemBaseConfig={setHideItemBaseConfig} />
                    <ProductInfo selectedProduct={product}
                                 defaultBranch={lookupSelectedBranch(auth)}
                                 isLoggedOUt={isLoggedOut(auth)}
                                 pageReady={pageReady} />
                </Container>
    }

    const displayLoading = () => {
        return <TreeSourceLoad message={"Loading Product Details..."}/>
    }

    return  <>
                {pageReady ? "" : displayLoading()}
                {isDesktop ? renderDesktop() : renderMobile()}
            </>;
}