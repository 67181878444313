const usePDFDownloader = () => {

    const downloadPDF = (url: string, refNumber: string) => {
        // Set the cursor to 'wait'
        document.body.style.cursor = 'wait';

        fetch(url, {
            headers: {
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        })
        .then((res) => {
            if (!res.ok) {
                throw new Error("Network response was not ok");
            }
            return res.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${refNumber}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();

            // Reset the cursor back to 'auto' on success
            document.body.style.cursor = 'auto';
        })
        .catch((error) => {
            console.error(
                "There has been a problem with your fetch operation:",
                error
            );

            // Reset the cursor back to 'auto' in case of error
            document.body.style.cursor = 'auto';
        });
    };

    return {
        downloadPDF
    }
}

export default usePDFDownloader;