import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {Box, TextField} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {
    GridSelectionModel,
} from "@mui/x-data-grid";
import {AuthContext} from "../../../providers/AuthProvider";
import {formatter} from "../../../constants/Constants";
import SpecificInvoiceGrid from "./SpecificInvoiceGrid";
import {StylesContext} from "../../../providers/StylesProvider";
import SpecificAccountBalanceEventListItem from "./SpecificAccountBalanceEventListItem";
import usePDFDownloader from "../../../hooks/usePDFDownloader";

export const TYPE_INVOICE = "Invoice"
export const TYPE_CREDIT_MEMO = "Credit Memo"

export type AccountEvent = {
    type: string;
    dueDate: string;
    refNumber: string;
    poNumber: string;
    memo: string;
    balanceRemaining: number;
    url: string;
}

type PaymentNotesCardProps = {
    paymentAmount: number;
    setSelectedAccountEvents: React.Dispatch<React.SetStateAction<AccountEvent[]>>;
    selectedInvoiceHint: string | null;
}

const PaySpecificInvoicesCard = (props: PaymentNotesCardProps) => {
    const [accountBalanceEvents, setAccountBalanceEvents] = useState<AccountEvent[]>([]);
    const [filteredAccountBalanceEvents, setFilteredAccountBalanceEvents] = useState<AccountEvent[]>([]);
    const [selectedRowModel, setSelectedRowModel] = useState<GridSelectionModel>([]);
    const [searchString, setSearchString] = useState<string>("");
    const [defaultsInitialized, setDefaultsInitialized] = useState<boolean>(false)

    const auth = useContext(AuthContext);
    const customerName = auth.user?.customer?.fullName ?? "";
    const {selectedInvoiceHint} = props;
    const { isDesktop } = useContext(StylesContext);
    const { downloadPDF } = usePDFDownloader();

    useEffect(() => {
        fetchAccountBalanceEvents(customerName);
    }, [customerName]);

    useEffect(() => {
        if(selectedInvoiceHint && !defaultsInitialized && filteredAccountBalanceEvents.length > 0) {
            initializeDefaults(selectedInvoiceHint);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredAccountBalanceEvents, defaultsInitialized, selectedInvoiceHint]);

    useEffect(() => {
        // You might ask: why?
        // I'm including previously selected rows in the model regardless of whether they match the search string
        // because I want to make sure that the user can always see the rows they've selected.
        // As soon as they unselect a non-matching row, this will cause it to disappear from the model.
        handleSearchSubmit(searchString);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowModel]);

    const fetchAccountBalanceEvents = (customerName: string) => {
        const encodedCustomerName = encodeURIComponent(customerName);

        fetch(`/api/customer/${encodedCustomerName}/balance/events`, {
            headers: {
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((data) => {
                setAccountBalanceEvents(data);
                setFilteredAccountBalanceEvents(data);
            })
            .catch((error) => {
                console.error(
                    "There has been a problem with your fetch operation:",
                    error
                );
            });
    }

    const initializeDefaults = (hint: string) => {
        if (!defaultsInitialized) {
            handleSearchSubmit(hint);
            setSelectedRowModel([hint]);
            handleEventSelectionChange([hint]); //cheat because we know what we just set the model to.

            setDefaultsInitialized(true);
        }
    }

    const handleEventSelectionChange = (newSelection: GridSelectionModel) => {
        const newSelectedRows = filteredAccountBalanceEvents.filter((row) => newSelection.includes(row.refNumber));

        props.setSelectedAccountEvents(newSelectedRows);
    }

    const handleSearchSubmit = (value: string) => {
        setSearchString(value);

        value = value.toLowerCase().replace("#", "");
        const foo = accountBalanceEvents.filter(
            (x) =>
                x?.type?.toLowerCase().includes(value) ||
                x?.dueDate?.toLowerCase().includes(value) ||
                x?.refNumber?.toLowerCase().includes(value) ||
                x?.poNumber?.toLowerCase().includes(value) ||
                x?.memo?.toLowerCase().includes(value) ||
                x?.balanceRemaining?.toString().includes(value) ||
                formatter.format(x?.balanceRemaining).includes(value) ||
                selectedRowModel.includes(x.refNumber)
        );
        setFilteredAccountBalanceEvents(foo);
    };

    const handlePdfClick = (pressedRow: AccountEvent) => {
        downloadPDF(pressedRow.url, pressedRow.refNumber);
    }

    const renderDesktop = () => {
        return <PaddedCard style={{marginBottom: 20}}>
            <div style={{margin: 10}}>
                <TextField
                    label="Search for an event"
                    onChange={(event: any) => handleSearchSubmit(event.target.value)}
                    value={searchString}
                    id="accountEventSearchField"
                    variant="outlined"
                    fullWidth
                />
            </div>

            <SpecificInvoiceGrid
                filteredAccountBalanceEvents={filteredAccountBalanceEvents}
                selectedRowModel={selectedRowModel}
                setSelectedRowModel={setSelectedRowModel}
                handleEventSelectionChange={handleEventSelectionChange}
                handlePdfClick={handlePdfClick}
            />
        </PaddedCard>
    }

    const renderInvoiceList = () => {
        return filteredAccountBalanceEvents.map((event) => (
            <SpecificAccountBalanceEventListItem
                key={event.refNumber}
                accountBalanceEvent={event}
                selectedRowModel={selectedRowModel}
                setSelectedRowModel={setSelectedRowModel}
                handleEventSelectionChange={handleEventSelectionChange}
                handlePdfClick={handlePdfClick} />
        ));
    }

    const renderMobile = () => {
        return <PaddedCard style={{marginBottom: 20}}>
            <div style={{margin: 10}}>
                <TextField
                    label="Search for an event"
                    onChange={(event: any) => handleSearchSubmit(event.target.value)}
                    value={searchString}
                    id="accountEventSearchField"
                    variant="outlined"
                    fullWidth
                />
            </div>

            <Box style={{overflow: 'auto', maxHeight: 500, margin: 13}}>
                {renderInvoiceList()}
            </Box>
        </PaddedCard>
    }

    return <>
        {isDesktop ? renderDesktop() : renderMobile()}
    </>

}

export default PaySpecificInvoicesCard;
