import React from 'react';
import {
    CardHeader
} from '@mui/material';
import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {AddressType, CardWithNickname, PaymentMethod} from "../../../types/CardDataType";
import CardInputFields from "./CardInputFields";
import AddressInputFields from "./AddressInputFields";
import SavePaymentMethodFields from "./SavePaymentMethodFields";


export type NewPaymentMethodCardProps = {
    //Required and used on the [take] payment page
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
    paymentMethod: CardWithNickname;
    saveCard: boolean;
    setSaveCard: React.Dispatch<React.SetStateAction<boolean>>;

    //Optional and used on the manage payment page
    cancel?: () => void;
    save?: () => void;

    padded?: boolean;
}

const NewCreditCard: React.FC<NewPaymentMethodCardProps> = (props: NewPaymentMethodCardProps) => {
    const padded = props.padded ?? true;

    //Converts a CardWithNickname to a PaymentMethod (workaround some limitation of types)
    const asPaymentMethod = (card: CardWithNickname): PaymentMethod => {
        return card;
    }

    const setAddress = (newAddress: AddressType) => {
        props.setPaymentMethod(prevState => ({...prevState, address: newAddress}));
    }

    return (
        <PaddedCard style={{margin: padded ? 10 : 0, marginBottom: 20}}>
            <CardHeader title="New Credit Card" />
            <div style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}>
                <CardInputFields paymentMethod={props.paymentMethod} setPaymentMethod={props.setPaymentMethod} asPaymentMethod={asPaymentMethod}/>
                <AddressInputFields label={"Street Address"} address={props.paymentMethod.address} setAddress={setAddress} />
                <SavePaymentMethodFields
                    paymentMethod={props.paymentMethod}
                    setPaymentMethod={props.setPaymentMethod}
                    savePaymentMethod={props.saveCard}
                    setSavePaymentMethod={props.setSaveCard}
                    cancel={props.cancel}
                    save={props.save}
                />

            </div>
        </PaddedCard>
    );
};

export default NewCreditCard;