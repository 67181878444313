import {StylesContext} from "../../../providers/StylesProvider";
import React, {useContext, useEffect, useState} from "react";
import {CustomerContext} from "../../../providers/CustomerProvider";
import {
    BankAccountWithNickname,
    CardWithNickname,
    PaymentMethod,
    TYPE_CARD,
    TYPE_TERMS_ACCOUNT
} from "../../../types/CardDataType";
import {SelectChangeEvent} from "@mui/material/Select";
import {Button, CardHeader, FormControl, MenuItem, Select} from "@mui/material";
import {NEW_BANK_ACCOUNT, NEW_CARD, TERMS_ACCOUNT} from "./PaymentCardConstants";
import {useHistory} from "react-router";
import {AuthContext} from "../../../providers/AuthProvider";

export type PaymentMethodDropDownProps = {
    paymentMethod: PaymentMethod;
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>
    desktopWidth?: string;
    includeTermsAccount?: boolean;
}

const PaymentMethodDropDown: React.FC<PaymentMethodDropDownProps> = (props: PaymentMethodDropDownProps) => {
    //context
    const { isDesktop } = useContext(StylesContext);
    const { getSelectedCustomerTerms }  = useContext(AuthContext);
    const desktopWidth = props.desktopWidth ?? "42%";
    const includeTermsAccount = props.includeTermsAccount ?? false;

    //react state
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState<PaymentMethod[]>([]);
    const history = useHistory();

    //local
    const { setPaymentMethod} = props;
    const { fetchSavedPaymentMethods } = useContext(CustomerContext); //hmm, do we like this? Its advantage is that I'll use the same in the manage cards page

    useEffect(() => {
        if(fetchSavedPaymentMethods && setPaymentMethod) {
            fetchSavedPaymentMethods()
                .then((paymentMethods) => {
                    const shouldAddPayLater = includeTermsAccount && canPayLater(getSelectedCustomerTerms!());
                    const temp = shouldAddPayLater ? [TERMS_ACCOUNT, ...paymentMethods] : paymentMethods;

                    setAvailablePaymentMethods([...temp]);

                    return temp;
                })
                .then((paymentMethods: PaymentMethod[]) => {
                    const termsAccount = paymentMethods.find((x) => x.id === TERMS_ACCOUNT.id);

                    if(termsAccount) {
                        setPaymentMethod(termsAccount);
                        return;
                    }

                    const defaultCard = paymentMethods.find((x) => x.isDefault);

                    if(defaultCard) {
                        setPaymentMethod(defaultCard);
                    } else {
                        setPaymentMethod(NEW_CARD);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching saved cards: ", error);
                });
        }
    }, [fetchSavedPaymentMethods, setPaymentMethod, getSelectedCustomerTerms, includeTermsAccount]);

    const canPayLater = (termsName: string) => {
        termsName = termsName.toUpperCase();

        //TODO: prevent NO SALE customers from building orders
        if(termsName.includes("COD") ||
            termsName.includes("CASH ONLY") ||
            termsName.length === 0 ||
            termsName.includes("DUE ON RECEIPT")
            || termsName.includes("NO SALE")
            || termsName.includes("PRE PAY")) {
            return false;
        } else {
            return true;
        }
    }

    const handlePaymentMethodChange = (event: SelectChangeEvent<string>) => {
        const selectedPaymentId = event.target.value;
        const selectedPaymentMethod = availablePaymentMethods.find(card => card.id === selectedPaymentId);
        if (selectedPaymentMethod) {
            props.setPaymentMethod(selectedPaymentMethod);
        } else if (selectedPaymentId === NEW_CARD.id) {
            props.setPaymentMethod(NEW_CARD);
        } else {
            props.setPaymentMethod(NEW_BANK_ACCOUNT);
        }
    };

    const renderPaymentMethods = () => {
        if(availablePaymentMethods.length === 0) {
            return <MenuItem key={"Loading"} value={"Loading"} >
                Loading...
            </MenuItem>
        }

        return availablePaymentMethods?.map((x) => {
            if (x.nickname === NEW_CARD.id) { //x is being returned from an older endpoint. I'd rather not change it, but here "New Card" is stored in the id.
                return renderNewCard(x);
            } else if(x.nickname === NEW_BANK_ACCOUNT.id) {
                return renderNewBankAccount(x);
            } else if(x.type === TYPE_TERMS_ACCOUNT) {
                return renderAccountTerms();
            } else if (x.type === TYPE_CARD) {
                return renderSavedCard(x as CardWithNickname);
            } else {
                return renderSavedBankAccount(x as BankAccountWithNickname);
            }
        });
    }

    const renderAccountTerms = () => {
        return <MenuItem key={TERMS_ACCOUNT.id} value={TERMS_ACCOUNT.id} >
            Pay Later - Put it on my account.
        </MenuItem>
    }

    const renderNewCard = (currentPaymentMethod: PaymentMethod) => {
        return <MenuItem key={NEW_CARD.id} value={NEW_CARD.id} >
            {NEW_CARD.id}
        </MenuItem>
    }

    const renderSavedCard = (currentPaymentMethod: CardWithNickname) => {
        return <MenuItem
            key={currentPaymentMethod.id}
            value={currentPaymentMethod.id}
        >
            {currentPaymentMethod.cardType +
                " - " +
                currentPaymentMethod.number.substring(currentPaymentMethod.number.length - 4) +
                (currentPaymentMethod.nickname ? " | " + currentPaymentMethod.nickname : "")
            }
        </MenuItem>
    }

    const renderNewBankAccount = (currentPaymentMethod: PaymentMethod) => {
        return <MenuItem key={NEW_BANK_ACCOUNT.id} value={NEW_BANK_ACCOUNT.id} >
            {NEW_BANK_ACCOUNT.id}
        </MenuItem>
    }

    const renderSavedBankAccount = (currentPaymentMethod: BankAccountWithNickname) => {
        return <MenuItem
            key={currentPaymentMethod.id}
            value={currentPaymentMethod.id}
        >
            {"BANK - " +
                currentPaymentMethod.accountNumber.substring(currentPaymentMethod.accountNumber.length - 4) +
                (currentPaymentMethod.nickname ? " | " + currentPaymentMethod.nickname : "")
            }
        </MenuItem>
    }

    const renderDesktop = () => {
        return <>
            <div  style={{width: desktopWidth, minHeight: 180}} >
                <CardHeader title="Payment Method" />

                <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", marginTop: 10}} >
                    <Select style={{width: "90%"}} value={props.paymentMethod.id} onChange={handlePaymentMethodChange}>
                        {renderPaymentMethods()}
                    </Select>

                    <Button
                        onClick={() => history.push('/payments/manage')}
                        style={{color: '#0000EE', textTransform: 'none', textDecoration: 'underline'}}
                    >
                        Manage payment methods
                    </Button>
                </div>

            </div>
        </>
    }

    const renderMobile = () => {
        return <>
            <CardHeader title="Payment Method" />

            <div style={{display: "flex", justifyContent: "center", marginBottom: 20}}>
                <FormControl  style={{width: "100%",  marginLeft: 20, marginRight: 20 }} >
                    <Select value={props.paymentMethod.id} onChange={handlePaymentMethodChange}>
                        {renderPaymentMethods()}
                    </Select>

                    <Button
                        onClick={() => history.push('/payments/manage')}
                        style={{color: '#0000EE', textTransform: 'none', textDecoration: 'underline'}}
                    >
                        Manage Payment Methods
                    </Button>
                </FormControl>
            </div>
        </>
    }

    return <>
        {isDesktop ? renderDesktop() : renderMobile()}
    </>;
}

export default PaymentMethodDropDown;