import { useContext} from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { AuthContext } from "../../providers/AuthProvider"

export type ProtectedRouteProps = {
  isAuthenticated: boolean
  authenticationPath: string
} & RouteProps

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {

  const {isTokenExpired, } = useContext(AuthContext)

  const isExpired = () => {
    if(isTokenExpired){
      return isTokenExpired()
  }
    return true
  }

    let redirectPath = ''
    if (!props.isAuthenticated || isExpired()) {
      redirectPath = props.authenticationPath

    }
      
      if (redirectPath) {

        const renderComponent = () => (<Redirect to={{ pathname: redirectPath, state: {from: props.location?.pathname, search: props.location?.search}}} from={props.location?.pathname} />)
        return <Route {...props} component={renderComponent}
        />

      } else {

        return <Route {...props} />
      }
    }

export default ProtectedRoute
