import React, {useContext} from "react";
import {OrderContext} from "../../providers/OrderProvider";
import {Autocomplete, Button, TextField} from "@mui/material";
import {NEW_ORDER, OrderForAdd} from "../../types/OrderForAdd";
import {StylesContext} from "../../providers/StylesProvider";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import {useHistory} from "react-router";

export const OrderSelector = () => {
    // context
    const { setSelectedShipmentForAdd,  selectedShipmentForAdd, allAddToOptions} = useContext(OrderContext);
    const history = useHistory();

    // gui control
    const isDesktop = useContext(StylesContext).isDesktop ?? false;

    function ordersAreEqual(option: OrderForAdd, value: OrderForAdd) {
        if (value === undefined) {
            return false;
        }

        return option.orderId === value.orderId;
    }

    const handleChangeOrder = (value: OrderForAdd) => {
        if (setSelectedShipmentForAdd) {
            setSelectedShipmentForAdd(value);
        }
    };

    const viewOrderButtonPhone = () => {
        return (
            <Button
                style={{ marginLeft: 10 }}
                variant="contained"
                color="primary"
                onClick={() => history.push(`/order-management/order/${selectedShipmentForAdd?.orderId}`)}
            >
                <ViewHeadlineIcon />
            </Button>
        );
    };

    const viewOrderButton = () => {
        return (
            <div>
                <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="primary"
                    disabled={selectedShipmentForAdd === NEW_ORDER || !selectedShipmentForAdd}
                    onClick={() => history.push(`/order-management/order/${selectedShipmentForAdd?.orderId}`)}
                >
                    View Order
                </Button>
            </div>
        );
    };

    return (
        <div style={{display: "flex"}}>
            <Autocomplete
                id="open-order-combo-box"
                options={allAddToOptions ?? [NEW_ORDER]}
                style={{ width: 350 }}
                renderInput={(params) => (
                    <TextField {...params} label="Add To Open Order" size="small" />
                )}
                onChange={(event, value) => handleChangeOrder(value as OrderForAdd)}
                value={selectedShipmentForAdd}
                isOptionEqualToValue={ordersAreEqual}
            />
            {!isDesktop
                    ? viewOrderButtonPhone()
                    : viewOrderButton()
            }
        </div>
    );
}