import React, {useContext} from "react";

import {Alert, Snackbar} from "@mui/material";
import {SpecificOrderContext} from "../../../providers/SpecificOrderContext";

const SpecificOrderSnackbar = () => {
    const { mainToastOpen, setMainToastOpen, mainToastSeverity, mainToastMessage } = useContext(SpecificOrderContext);

    return <>
        <Snackbar
            open={mainToastOpen}
            autoHideDuration={6000}
            onClose={() => setMainToastOpen!(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={() => setMainToastOpen!(false)} severity={mainToastSeverity} sx={{ width: '100%' }}>
                {mainToastMessage}
            </Alert>
        </Snackbar>
    </>
}

export default SpecificOrderSnackbar