import React, { useLayoutEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import {
  IconButton,
  AppBar,
  Toolbar,
  ListItemText,
  ListItem,
  List,
  ListItemIcon,
  Divider, Badge,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HandshakeIcon from '@mui/icons-material/Handshake';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HomeIcon from "@mui/icons-material/Home";
import { Customer } from "../../types/UserType";
import { ButtonWithMenu } from "../guiComponents/ButtonWithMenu";
import { AccountDropDown } from "../guiComponents/AccountDropDown";
import { BranchContext } from "../../providers/BranchProvider";
import { StylesContext } from "../../providers/StylesProvider";
import { BranchType } from "../../types/BranchType";
import {LoyaltyPointCounter} from "../guiComponents/loyaltyProgram/LoyaltyPointCounter";
import {CameraAlt, Forest, Payment} from "@mui/icons-material";
import {OrderContext} from "../../providers/OrderProvider";
import {NEW_ORDER} from "../../types/OrderForAdd";

const PREFIX = "NavBar";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  navDisplayFlex: `${PREFIX}-navDisplayFlex`,
  navbarDisplayFlex: `${PREFIX}-navbarDisplayFlex`,
  menuButton: `${PREFIX}-menuButton`,
  lowerCaseMenuButton: `${PREFIX}-lowerCaseMenuButton`,
  homeButton: `${PREFIX}-homeButton`,
  availButton: `${PREFIX}-availButton`,
  appBarColor: `${PREFIX}-appBarColor`,
  headerText: `${PREFIX}-headerText`,
  subHeaderText: `${PREFIX}-subHeaderText`,
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
  formControl: `${PREFIX}-formControl`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.paper}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.navDisplayFlex}`]: {
    display: `flex`,
    justifyContent: `space-between`,
  },

  [`& .${classes.navbarDisplayFlex}`]: {
    display: `flex`,
    justifyContent: `space-between`,
  },

  [`& .${classes.menuButton}`]: {
    textAlign: "center",
    fontSize: "9pt",
  },

  [`& .${classes.lowerCaseMenuButton}`]: {
    textAlign: "center",
    textTransform: "none",
    fontSize: 9,
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.homeButton}`]: {
    maxWidth: 50,
    minWidth: 50,
    textAlign: "center",
  },

  [`& .${classes.availButton}`]: {
    maxWidth: 150,
    minWidth: 150,
    textAlign: "center",
  },

  [`&.${classes.appBarColor}`]: {
    backgroundColor: "#1D4151",
    position: "fixed",
    zIndex: "100",
  },

  [`& .${classes.headerText}`]: {
    whiteSpace: "nowrap",
    fontSize: ".9rem",
  },

  [`& .${classes.subHeaderText}`]: {
    whiteSpace: "nowrap",
    fontSize: ".6rem",
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.list}`]: {
    width: 250,
  },

  [`& .${classes.formControl}`]: {
    margin: 1,
    minWidth: 175,
    textOverflow: "ellipsis",
  },
}));

type Anchor = "top" | "left" | "bottom" | "right";

const NavBar: React.FC<RouteComponentProps> = (props) => {
  const Auth = useContext(AuthContext);
  const isTrustedInternalUser = Auth.trustedInternalRole || false;
  const fullScreen = useMediaQuery("(min-width:1118px");
  const history = useHistory();
  const {
    user,
    userCustomers,
    handleLogout,
    authenticated,
    setUser,
    setSelectedCustomer,
    loggedOutLocation,
    setLoggedOutLocation,
  } = useContext(AuthContext);
  const { setNavBarHeight } = useContext(StylesContext);

  const { branches, loadBranches } = useContext(BranchContext);
  const { selectedShipmentForAdd } = useContext(OrderContext);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let height = ref.current?.clientHeight as number;
    if (setNavBarHeight) {
      setNavBarHeight(height + 10);
    }
    // eslint-disable-next-line
    if (loadBranches) {
      loadBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customer = user?.customer;

  const [drawer, setDrawer] = React.useState<boolean>(false);

  // eslint-disable-next-line
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  let defaultCustomer: Customer | undefined;
  if (
    userCustomers &&
    userCustomers != null &&
    userCustomers[0] &&
    userCustomers[0] != null
  ) {
    defaultCustomer = userCustomers[0];
  }

  const objectMiddlePhone = [
    { path: "/products", label: "Availability List", icon: <ListAltIcon /> },
    { path: "/loyalty-program", label: "Loyalty Program", icon: <HandshakeIcon /> },
    { path: "/contact", label: "Contact Us", icon: <PhoneIcon /> },
    // { path: "/about", label: "About Us", icon: <InfoIcon />}
  ];

  const objectMiddleComputer = [
    { path: "/products", label: "Availability List", icon: <ListAltIcon /> },
    { path: "/loyalty-program", label: "Loyalty Program", icon: <HandshakeIcon /> },
    { path: "/contact", label: "Contact Us", icon: <PhoneIcon /> },
  ];

  let loggedOutObjectRight = [
    { path: "/login", label: "Login", icon: <LockOpenIcon /> },
    { path: "/register", label: "Register", icon: <PersonAddIcon /> },
  ];

  let loggedOutGuidValidator = () => {
    if (user?.verified) {
      return [{ path: "/login", label: "Login", icon: <LockOpenIcon /> }];
    } else {
      return [
        { path: "/login", label: "Login", icon: <LockOpenIcon /> },
        { path: "/register", label: "Register", icon: <PersonAddIcon /> },
      ];
    }
  };

  const loggedInRight = [
    { path: "/order-management/", label: "My Orders", icon: <NaturePeopleIcon /> },
    { path: "/payments", label: "Make Payment", icon: <Payment /> },
    { path: "/user", label: "Account", icon: <PersonIcon /> },
  ];

  const handleMenuClick = (path: string) => {
    props.history.push(path);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let value = customer?.fullName
    ? customer.fullName
    : defaultCustomer?.fullName;

  const handleDropDownChange = (e: any) => {
    let fullName = e.target.value as string;

    let returnedCustomer = userCustomers?.find((x) => x?.fullName === fullName);
    if (setUser && setSelectedCustomer && customer) {
      setSelectedCustomer(returnedCustomer as Customer);
      let customerEdit = {
        ...customer,
        customerName: returnedCustomer?.companyName,
      };
      setUser(user ? { ...user, customer: customerEdit } : null);
    }

    if (window.location.pathname.includes("/order-management/order/") || window.location.pathname.includes("/checkout/order/")) {
      history.push("/order-management/");
    }

    window.location.reload();
  };

  const loggedIn = () => {
    return (
      <>
        <List
          component="nav"
          aria-labelledby="middle navigation"
          className={classes.navDisplayFlex}
        >
          <ListItem
            button
            className={classes.homeButton}
            onClick={() => {
              handleMenuClick("/");
            }}
          >
            <ListItemIcon>
              <HomeIcon style={{ color: "white" }} fontSize="medium" />
            </ListItemIcon>
          </ListItem>
          {objectMiddleComputer.map(({ path, label }, index) => (
            <ListItem
              className={classes.availButton}
              key={index + "-" + path}
              button
              onClick={() => {
                handleMenuClick(path);
              }}
            >
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {userCustomers ? (
              userCustomers[0]?.fullName ? (
                  customerDropDown()
              ) : (
                  <></>
              )
          ) : (
              locationDropDown()
          )}
          <LoyaltyPointCounter fullScreen={fullScreen}/>
        </div>

        <List
            className={classes.navDisplayFlex}
            component="nav"
            aria-labelledby="right navigation"
        >

        {isTrustedInternalUser &&
            <ButtonWithMenu
              header="Internal"
              subHeader=""
              menuItems={[
                { label: "Cycle Count", path: "/cycle-count" },
                { label: "Photo Booth", path: "/plant-photo-booth/" },
              ]}
            />
        }

        <ButtonWithMenu
            header="My Account"
            subHeader={user?.email as string}
            menuItems={[
              { label: "My Orders", path: "/order-management/" },
              { label: "Make Payment", path: "/payments" },
              { label: "Job Lines", path: "/jobline" },
              { label: "Account", path: "/user" },
              { label: "Log Out", path: "/login", onClick: () => handleLogout!(history) },
            ]}
        />

          <ListItem
            className={classes.menuButton}
            button
            onClick={() => {
              if(selectedShipmentForAdd === NEW_ORDER) {
                history.push(`/order-management/`);
              } else {
                history.push(`/order-management/order/${selectedShipmentForAdd?.orderId}`);
              }
            }}
          >
            <div style={{display: "flex"}}>
              <ShoppingCartIcon fontSize={"medium"} />
              <Badge badgeContent={selectedShipmentForAdd?.uncommittedLineItemCount ?? 0} color="primary" sx={{ position: 'relative', top: -2, right: -8}} />
            </div>
          </ListItem>
        </List>
      </>
    );
  };

  const loggedOut = () => {
    return (
      <>
        <List
          component="nav"
          aria-labelledby="middle navigation"
          className={classes.navDisplayFlex}
        >
          <ListItem
            button
            className={classes.homeButton}
            onClick={() => {
              handleMenuClick("/");
            }}
          >
            <ListItemIcon>
              <HomeIcon style={{ color: "white" }} fontSize="medium" />
            </ListItemIcon>
          </ListItem>
          {objectMiddleComputer.map(({ path, label }, index) => (
            <ListItem
              className={classes.availButton}
              key={index + "-" + path}
              button
              onClick={() => {
                handleMenuClick(path);
              }}
            >
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
        {locationDropDown()}
        <List
          component="nav"
          aria-labelledby="right navigation"
          className={classes.navDisplayFlex}
        >
          {loggedOutGuidValidator().map(({ path, label }, index) => (
            <ListItem
              key={index + "-" + path}
              button
              onClick={() => {
                handleMenuClick(path);
              }}
              className={classes.menuButton}
            >
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  const customerDropDown = () => {
    return (
      <AccountDropDown
        fullscreen={fullScreen}
        handleChange={handleDropDownChange}
        value={value as string}
        menuItems={userCustomers ?? []}
        dropDownUse="customer"
      />
    );
  };

  const location = useLocation();

  const handleLocationChange = (e: any) => {
    if (setLoggedOutLocation) {
      setLoggedOutLocation(e.target.value);
      history.push(`${location.pathname}?location=${e.target.value}`);
    }
  };

  const locationDropDown = () => {
    return (
      <AccountDropDown
        fullscreen={fullScreen}
        handleChange={handleLocationChange}
        value={loggedOutLocation?.toString() as string}
        menuItems={branches as BranchType[]}
        dropDownUse="location"
      />
    );
  };

  const userStatus = () => {
    if (authenticated) {
      return loggedIn();
    }
    return loggedOut();
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawer(drawer ? false : true);
    };

  const loggedInList = (anchor: Anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              handleMenuClick("/");
            }}
            primary="Home"
          />
        </ListItem>
        {objectMiddlePhone.map(({ path, label, icon }, index) => (
          <ListItem
            key={index + "-" + path}
            button
            onClick={() => {
              handleMenuClick(path);
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {loggedInRight.map(({ path, label, icon }, index) => (
          <ListItem
            key={index + "-" + path}
            button
            onClick={() => {
              handleMenuClick(path);
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
        {Auth.trustedInternalRole ? (
            <>
              <ListItem
                key="cycle-count"
                onClick={() => {
                  handleMenuClick("/cycle-count");
                }}
              >
                <ListItemIcon>
                  <Forest />
                </ListItemIcon>
                <ListItemText primary="Cycle Count" />
              </ListItem>
              <ListItem
                  key="plant-photo-booth"
                  onClick={() => {
                    handleMenuClick("/plant-photo-booth");
                  }}
              >
                <ListItemIcon>
                  <CameraAlt />
                </ListItemIcon>
                <ListItemText primary="Photo Booth" />
              </ListItem>
            </>

        ) : (
          ""
        )}
        <ListItem
          button
          onClick={() => (handleLogout ? handleLogout(history) : null)}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary={user?.email} secondary="Currently logged in" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Company Name" />
        </ListItem>
        <ListItem>
          {userCustomers ? (
            userCustomers[0]?.fullName ? (
              customerDropDown()
            ) : (
              <></>
            )
          ) : (
            locationDropDown()
          )}
        </ListItem>
        <LoyaltyPointCounter fullScreen={fullScreen}/>
      </List>
    </div>
  );

  const loggedOutList = (anchor: Anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              handleMenuClick("/");
            }}
            primary="Home"
          />
        </ListItem>
        {objectMiddlePhone.map(({ path, label, icon }, index) => (
          <ListItem
            key={index + "-" + path}
            button
            onClick={() => {
              handleMenuClick(path);
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {loggedOutObjectRight.map(({ path, label, icon }, index) => (
          <ListItem
            key={index + "-" + path}
            button
            onClick={() => {
              handleMenuClick(path);
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
        <ListItem title="Branch:">{locationDropDown()}</ListItem>
      </List>
    </div>
  );

  const mainMenu = () => {
    return (
      <>
        <Drawer
          anchor="left"
          open={drawer}
          onClose={toggleDrawer("left", false)}
        >
          {authenticated ? loggedInList("left") : loggedOutList("left")}
        </Drawer>
      </>
    );
  };

  const phoneUserStatus = () => {
    return (
      <>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer("left", true)}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        {mainMenu()}
        <Typography variant="h6" className={classes.title}>
          TreeSource.com
        </Typography>
      </>
    );
  };

  return (
    <>
      <StyledAppBar
        id={"nav-bar"}
        color="primary"
        className={classes.appBarColor}
        ref={ref}
      >
        <Toolbar variant="dense" className={classes.navDisplayFlex}>
          {fullScreen ? userStatus() : phoneUserStatus()}
        </Toolbar>
      </StyledAppBar>
    </>
  );
};

export default withRouter(NavBar);
