import {NEW_ORDER, OrderForAdd} from "../types/OrderForAdd";
import {OrderType} from "../types/OrderType";
import {useCallback, useContext, useMemo} from "react";
import {CUSTOMER_NAME} from "../constants/Constants";
import {DEFAULT_ORDER_INFO, OrderEO} from "../components/LoggedIn/orders/OrderInformation";
import {ShipmentEO} from "../components/LoggedIn/orders/ShipmentEO";
import {LandDateResponse} from "../components/LoggedIn/orders/checkout/LandDateResponse";
import {useHistory} from "react-router";
import {CustomerContext} from "../providers/CustomerProvider";

const useOrderAPI = () => {
    const history = useHistory();
    const { parentCustomerName } = useContext(CustomerContext);

    const getOpenOrders = useCallback((customerName: String,
                                                                setAddToOrderOptions: (orders: OrderForAdd[]) => void,
                                                                setSelectedOrder: (selectedItem: OrderForAdd) => void) => {
        fetch(`/api/orders/for-add?selectedCustomerName=${customerName}`, {
            method: 'GET',
            headers: {
                'authorization': localStorage.getItem("token"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Cache-Control": "no-store"
            } as HeadersInit
        }).then((res) => res.json())
            .then((response) => {
                const orderOptions: OrderForAdd[] = response.map((x: OrderType) => {
                    const jobName = x.jobName ? ": " + x.jobName : "";
                    const temp: OrderForAdd = {
                        id: x.orderId,
                        label: `${x.orderId}${jobName}`,
                        orderId: x.orderId,
                        orderName: x.jobName as string,
                        uncommittedLineItemCount: x.uncommittedLineItemCount ?? 0,
                    };

                    return temp;
                });

                orderOptions.sort((a, b) => b.orderId - a.orderId);

                if(orderOptions.length > 0) {
                    setSelectedOrder(orderOptions[0]); //default to first order
                }

                orderOptions.unshift(NEW_ORDER);

                setAddToOrderOptions(orderOptions);
            }).catch((error: any) => {
            console.error("An error occurred:", error);
        });
    }, []);

    const addToOrder = useCallback((selectedOrder: OrderForAdd, shipmentTypeName: string, vendor: string, itemCode: string, quantity: number) => {
        let encodedCustomerName = encodeURIComponent(
            localStorage.getItem(CUSTOMER_NAME) as string | number | boolean
        );

        const shipmentName = selectedOrder === NEW_ORDER ? `Cart created ${new Date().toLocaleDateString('en-US')}` : ""

        const data = {
            itemCode,
            quantity,
            encodedCustomerName,
            vendor,
            shipmentTypeName,
            shipmentName
        };

        return fetch(`/api/orders/${selectedOrder.orderId}/line-items`, {
            method: 'POST',
            headers: {
                'authorization': localStorage.getItem("token"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Cache-Control": "no-store"
            } as HeadersInit,
            body: JSON.stringify(data)
        })
        .then((res) => res.json())
        .then((response) => {
            return response;
        }).catch((error: any) => {
            console.error("An error occurred:", error);
            return selectedOrder;
        });
    }, []);

    const getOrderDetails = useCallback((orderId: string | number) => {
        if(!parentCustomerName) {
            return Promise.resolve(DEFAULT_ORDER_INFO); //return default until our actual order loads
        }

        const encodedCustomerName = encodeURIComponent(parentCustomerName as string);

        // @ts-ignore
        return fetch(`/api/orders/${orderId}/details?customerName=${encodedCustomerName}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        })
            .then(response => {
                if(response.ok) {
                    return response;
                } else if(response.status === 404 || response.status === 401) {
                    history.push('/not-found');
                } else {
                    throw new Error("Failed to retrieve order details");
                }
            })
        .then(response => response?.json() ?? DEFAULT_ORDER_INFO)
        .then((data: OrderEO) => {
            if(data.defaultShippingAddress == null) {
                data.defaultShippingAddress = "";
            }

            data.deliveries.forEach((delivery) => {
                if(delivery.destination == null) {
                    delivery.destination = "";
                }

                delivery.selected = false;
            });

            return data;
        });
    }, [history, parentCustomerName]);

    const getEarliestLandDate = useCallback((orderId: string | number, customerName: string) => {
        const encodedCustomerName = encodeURIComponent(customerName);

        return fetch(`/api/freight/earliest-land-date?orderId=${orderId}&customerName=${encodedCustomerName}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        })
            .then(response => response.json())
            .then((data: LandDateResponse) => {
                return data;
            });
    }, []);

    const postShipmentForFreight = useCallback((orderId: number,
                                                                         shipment: ShipmentEO,
                                                                         customerName: string,
                                                                         save: boolean) => {
        return fetch(`/api/freight`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({
                    orderId,
                    shipmentId: shipment.id,
                    customerName,
                    destination: shipment.destination,
                    landDate: shipment.landDate,
                    save
                })
            })
            .then(response => response.json())
            .then((data: ShipmentEO) => {
                return data;
            });
    }, []);

    return useMemo(() => {
        return {
            getOpenOrders,
            addToOrder,
            getOrderDetails,
            postShipmentForFreight,
            getEarliestLandDate
        };
    }, [getOpenOrders, addToOrder, getOrderDetails, postShipmentForFreight, getEarliestLandDate]);
}

export default useOrderAPI;