import {useCallback, useContext} from "react";
import {AccountEvent} from "../components/guiComponents/payments/PaySpecificInvoicesCard";
import {PaymentMethod, TYPE_BANK_ACCOUNT, TYPE_CARD} from "../types/CardDataType";
import {formatter} from "../constants/Constants";
import {AuthContext} from "../providers/AuthProvider";
import {CustomerContext} from "../providers/CustomerProvider";

const usePaymentsAPI = () => {
    const { getParentCustomerName } = useContext(CustomerContext);
    const { user } = useContext(AuthContext);

    const formatErrorString = useCallback((str: string) => {
        if ((str===null) || (str===''))
            return "";
        else {
            str = str.replace("<br>", " ");
            str = str.replace(/<[^>]*>/g, ''); //remove html tags

            if(str.includes("Error code")) {
                return str.split("Error code")[0];
            } else {
                return str;
            }
        }
    }, [])

    //yikes lol
    const makePaymentRequest = useCallback((paymentMethod: PaymentMethod,
                                                                        recaptchaToken: string,
                                                                        grandTotal: number,
                                                                        processingFee: number,
                                                                        paymentNotes: string,
                                                                        applyToOldestFirst: boolean,
                                                                        selectedAccountEvents: AccountEvent[],
                                                                        setError: (message: string) => void) => {

        const simplifiedPaymentMethod = { ...paymentMethod, cvcVerification: null, created: null, updated: null } as PaymentMethod & { accountType?: string };
        const parentCustomerName = getParentCustomerName ? getParentCustomerName(user?.customer?.fullName ?? "") : ""
        const adjustedAccountEvents = selectedAccountEvents.map((x) => {
            return {...x, balanceRemaining: formatter.format(x.balanceRemaining)}
        })

        if(!simplifiedPaymentMethod.accountType) {
            simplifiedPaymentMethod.accountType = "BUSINESS_CHECKING";
        }

        return fetch("/api/payment/v2", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({
                parentCustomerName: parentCustomerName,
                totalAmount: grandTotal,
                processingFee: processingFee,
                notes: paymentNotes,
                card: simplifiedPaymentMethod.type === TYPE_CARD ? simplifiedPaymentMethod : null,
                bankAccount: simplifiedPaymentMethod.type === TYPE_BANK_ACCOUNT ? simplifiedPaymentMethod : null,
                recaptchaToken: recaptchaToken,

                accountEvents: adjustedAccountEvents,
                applyToOldestFirst: applyToOldestFirst,
            }),
        })
        .then((res) => {
            if (!res.ok) {
                res.json().then(obj => {
                    setError(formatErrorString(obj.message));
                })
            } else {
                return res.json().then((data) => {
                    setError("")

                    return data;
                });
            }
        })
        .catch((error) => {
            console.error("There has been a problem with your fetch operation:", error);
        })
    }, [formatErrorString, getParentCustomerName, user]);

    return {
        makePaymentRequest
    }
}

export default usePaymentsAPI;