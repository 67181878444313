import React, {Dispatch, SetStateAction, useCallback} from "react";
import { useState } from "react";
import Axios from "axios";
import { Customer, UserType } from "../types/UserType";
import { useHistory } from "react-router";
import {
  CUSTOMER_NAME, DEFAULT_BRANCH,
  SHOW_ABOUT_US_PAGE,
} from "../constants/Constants";
import { datadogLogs } from "@datadog/browser-logs";

export type AuthPropertiesType = {
  user: UserType;
  authenticated: boolean;
  errorMessage: string | null;
  loadingLogin: boolean;
  fromPath: string | undefined;
  userGuid: string | null;
  userCustomers: Customer[] | undefined;
  selectedCustomer: Customer | null;
  loggedOutLocation: string;
  trustedInternalRole: boolean;
};

export type AuthFunctionsType = {
  handleLogin: (
    email: string,
    password: string,
    fromPath: string | undefined,
    callback: () => void
  ) => Promise<void>;
  handleLogout: (history: any) => void;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<UserType>>;
  setUserCustomers: Dispatch<SetStateAction<Customer[] | undefined>>;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  setFromPath: Dispatch<SetStateAction<string | undefined>>;
  setUserGuid: Dispatch<SetStateAction<string | null>>;
  isTokenExpired: () => boolean;
  isConfiguredUser: () => boolean;
  getUserInfoFromGuid: (guid: string | null) => Promise<void>;
  handleVerifyUserWithPassword: (
    passedInHistoryObj: any,
    guid: string | null,
    password: string
  ) => Promise<void>;
  getUsersCustomers: (email: string, token: string) => Promise<Customer[]>;
  setSelectedCustomer: (customer: Customer | null) => void;
  updateUser: (user: UserType) => Promise<void>;
  updateCustomer: (
    fullName: string,
    companyName: string,
    entityName: String
  ) => Promise<void>;
  setLoggedOutLocation: Dispatch<SetStateAction<string>>;
  getInternalUserPermissions: () => Promise<void>;
  getSelectedCustomerTerms: () => string;
};

export const AuthContext = React.createContext<
  Partial<AuthPropertiesType & AuthFunctionsType>
>({});

export function AuthProvider(props: any) {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-Control": "no-store",
    },
  };

  const [user, setUser] = useState<UserType>(null);
  const [loggedOutLocation, setLoggedOutLocation] =
    useState<string>(DEFAULT_BRANCH);
  const [selectedCustomer, setSelectedCustomerInner] = useState<Customer | null>(null);
  const [userCustomers, setUserCustomers] = useState<Customer[] | undefined>();
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fromPath, setFromPath] = useState<string | undefined>("");
  const [userGuid, setUserGuid] = useState<string | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [trustedInternalRole, setTrustedInternalRole] = useState(false);

  const history = useHistory();

  const isTokenExpired = () => {
    const expirationDate = localStorage.getItem("expirationDate");

    const today = new Date().getTime();
    const exDate = new Date(expirationDate ? expirationDate : "").getTime();
    const isExpired: boolean = exDate ? exDate < today : true;

    return isExpired;
  };

  const wrappedSetSelectedCustomer = useCallback((customer: Customer | null) => {
    localStorage.setItem(CUSTOMER_NAME, customer?.fullName ?? "");
    setSelectedCustomerInner(customer);
  }, [setSelectedCustomerInner]);

  const isConfiguredUser = () => {
    if (userCustomers) {
      return userCustomers?.length > 0 && authenticated;
    }

    return false;
  };

  const getUserInfoFromGuid = async (guid: string | null) => {
    try {
      let res = await Axios.get(
        `/api/user/getUserFromGuid?GUID=${
          guid ? guid : userGuid
        }&customerName=${localStorage.getItem("customerName")}`,
        config
      );

      if (user?.email ? user.email !== res.data.email : false) {
        handleLogout(history);
      }

      if (user) {
        setUser(user);
      } else {
        let newDate = new Date(res.data.guidExpiration);

        setUser({
          id: res.data.userId,
          email: res.data.email,
          password: res.data.password,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          phoneNumber: res.data.phoneNumber,
          title: res.data.title,
          entityName: res.data.entityName,
          guid: res.data.guid,
          guidExpiration: newDate,
          verified: res.data.verified,
          userCustomerAssociation: res.data.userCustomerAssociation,
          customer: {
            fullName: res.data.customer.fullName,
            companyName: res.data.customer.companyName,
            entityName: res.data.customer.entityName,
            salesRepRef_FullName: res.data.salesRepRef_FullName,
            creditLimit: res.data.salesRepRef_FullName,
            termsRef_FullName: res.data.termsRef_FullName,
            totalBalance: res.data.totalBalance,
            billingAddress: res.data.customer.billingAddress,
            billaddr1: res.data.customer.billaddr1,
            billaddr2: res.data.customer.billaddr2,
            billaddr3: res.data.customer.billaddr3,
            billCity: res.data.customer.billCity,
            billState: res.data.customer.billState,
            billPostalCode: res.data.customer.billPostalCode,
            salesRep: {
              name: res.data.customer.salesRep.name,
              phoneNumber: res.data.customer.salesRep.name,
              emailAddress: res.data.customer.salesRep.emailAddress,
              photoUrl: res.data.customer.salesRep.photoUrl,
              humanizingFact: res.data.customer.salesRep.humanizingFact,
            },
            displayName: calcDisplayName(
              res.data.customer.fullName,
              res.data.customer.companyName
            ),
            inLoyaltyProgram: res.data.customer.inLoyaltyProgram,
          },
        });
      }
    } catch (error) {}
  };

  const getSelectedCustomerTerms = () => {
    return auth.user?.customer?.termsRef_FullName ?? "COD";
  }

  const handleVerifyUserWithPassword = async (
      passedInHistoryObj: any,
      guid: string | null,
      password: string,
  ) => {
    const config = {
      headers: {
        authorization: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      let res = await Axios.post(
        `/api/user/setpassword`,
        `guid=${guid ? guid : userGuid}&password=${password}`,
        config
      );

      await handleLogin(res.data.email, password, undefined, () => {
        console.log("pushing to order-management", passedInHistoryObj);
        passedInHistoryObj.push("/order-management");
      });
    } catch (error: any) {
      setErrorMessage(
        error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : "Internal Server Error"
      );
    }
  };

  const getLowerCaseParentCustomerName = (customerName: string) => {
    if(customerName.includes(":")) {
      return customerName.split(":")[0].toLowerCase();
    } else {
      return customerName.toLowerCase();
    }
  }

  const handleSettingCustomerIfRequested = (tempCustomerList: Customer[], fromPath: string | undefined, priorCustomerName: string | null) => {
    if(fromPath && fromPath.includes("?")) {
      let queryParams = fromPath.split("?")[1]
      let urlParams = new URLSearchParams(queryParams);

      const requestedCustomerName = urlParams?.get('customerName')  ?? "";
      const requestedParentName = getLowerCaseParentCustomerName(requestedCustomerName ?? "");

      const matchingCustomer = tempCustomerList.find(customer => getLowerCaseParentCustomerName(customer?.fullName ?? "") === requestedParentName);
      if(matchingCustomer) {
        wrappedSetSelectedCustomer(matchingCustomer);
      }
    } else if (priorCustomerName) {
      // if the link isn't requesting a customer, let's see if they were previously logged in under a customer.
      // If so, let's restore it if we can

      const matchingCustomer = tempCustomerList.find(customer => (customer?.fullName ?? "") === priorCustomerName);
      if(matchingCustomer) {
        wrappedSetSelectedCustomer(matchingCustomer);
      }
    }
  }

  const handleLogin = async (
    email: string,
    password: string,
    fromPath: string | undefined,
    callback: () => void
  ) => {
    console.log("handleLogin");
    setLoadingLogin(true);
    let hideDialog = localStorage.getItem("hideDialog");
    let priorCustomerName = localStorage.getItem(CUSTOMER_NAME);
    localStorage.clear();
    localStorage.setItem("hideDialog", hideDialog as string);
    Axios({
      method: "post",
      url: `/api/authenticate/auth`,
      data: { email, password },
      headers: config,
    })
      .then(function (response: any) {
        localStorage.setItem("token", response.data);
        setAuthenticated(true);

        getUsersCustomers(email, response.data)
            .then((tempCustomerList) => {
              console.log("tempCustomerList", tempCustomerList);
              handleSettingCustomerIfRequested(tempCustomerList, fromPath, priorCustomerName)
              handleSetUserAfterLogin(response.data, history, callback);
            });

        datadogLogs.setLoggerGlobalContext({ userEmail: email });
        datadogLogs.logger.info(email + " just logged in.");
      })
      .catch(function (error: any) {
        setErrorMessage(
          error.response.data && typeof error.response.data === "string"
            ? error.response.data
            : "Internal Server Error"
        );
        setLoadingLogin(false);
      });
  };

  const handleSetUserAfterLogin = async (
    token: string,
    history: any,
    callback: () => void
  ) => {
    let customerName = encodeURIComponent(
      localStorage.getItem(CUSTOMER_NAME) as string | number | boolean
    );

    console.log("handleSetUserAfterLogin");
    let config = {
      headers: {
        authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
      },
    };

    try {
      let res = await Axios.get(
        `/api/user/getUser?${CUSTOMER_NAME}=${customerName}&timestamp=${new Date().getTime()}`,
        config
      );
      setUser(res.data);

      localStorage.setItem("expirationDate", res.headers.expires);
      console.log("calling callback");
      callback();
      loadFeatureFlags(config);
      setLoadingLogin(false);
    } catch (error) {}
  };

  const handleLogout = (history: any): void => {
    let userEmail = user?.email;
    datadogLogs.logger.info(userEmail + " just logged out.");
    datadogLogs.setLoggerGlobalContext({ userEmail: undefined });

    let hideDialog = localStorage.getItem("hideDialog");
    localStorage.clear();
    localStorage.setItem("hideDialog", hideDialog as string);
    setUser(null);
    setAuthenticated(false);

    history.push("/login");
    history.go(0);
    setErrorMessage(null);
  };

  const setCustomerNameInLocalStorage = (fullName: string) => {
    localStorage.setItem(CUSTOMER_NAME, fullName);
  };

  const getUsersCustomers = async (email: string, token: string) => {
      try {
          const config = {
            headers: {
              authorization: token,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Cache-Control": "no-store",
            },
          };

          let encodedEmail = encodeURIComponent(email as string | number | boolean);
          let res = await Axios.get(
            `/api/user/customerlist?email=${encodedEmail}`,
            config
          );


      let fullName = res.data[0].fullName as string;

      if (localStorage.getItem(CUSTOMER_NAME) === null) {
        setCustomerNameInLocalStorage(fullName);
      }

      const castCustomers = res.data as Customer[];
      const sortedCustomers = castCustomers.sort((a, b) => {
        return (a?.fullName ?? "").localeCompare((b?.fullName ?? ""));
      });

      const result = decorate(sortedCustomers)
      setUserCustomers(result);

      return result;
    } catch (error) {
      return [];
    }

  };

  const loadFeatureFlags = async (configParam: any) => {
    try {
      let aboutUsPage = await Axios.get("api/flags/aboutUsPage", configParam);
      localStorage.setItem(SHOW_ABOUT_US_PAGE, aboutUsPage.data);
    } catch (error) {
      localStorage.setItem(SHOW_ABOUT_US_PAGE, "false");
    }
  };

  function decorate(customerList: Customer[]): Customer[] {
    customerList.forEach((customer) => {
      if (customer != null) {
        customer.displayName = calcDisplayName(
          customer?.fullName,
          customer?.companyName
        );
      }
    });

    return customerList;
  }

  function calcDisplayName(
    fullName: string | undefined,
    companyName: string | undefined
  ): string {
    if (fullName?.includes(":")) {
      let jobName = fullName.substring(fullName.indexOf(":"));

      return companyName + jobName;
    } else {
      return "" + companyName;
    }
  }

  const updateUser = async (user: UserType) => {
    let userToSend = { ...user, customer: null };

    try {
      await Axios.put("/api/user/update", userToSend, config);
    } catch (error) {}
  };

  const updateCustomer = async (
    fullName: string,
    companyName: string,
    entityName: String
  ) => {
    const updateCustomerConfig = {
      headers: {
        authorization: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    try {
      await Axios.put(
        "/api/user/customer/update",
        `FullName=${fullName}&CompanyName=${companyName}&EntityName=${entityName}`,
        updateCustomerConfig
      );
    } catch (error) {}
  };

  const getInternalUserPermissions = async () => {
    const config = {
      headers: {
        authorization: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
      },
    };

    let res = await Axios.get("/api/roles", config);
    setTrustedInternalRole(res.data);
  };

  const auth: AuthPropertiesType & AuthFunctionsType = {
    user,
    getSelectedCustomerTerms,
    handleVerifyUserWithPassword,
    handleLogin,
    handleLogout,
    setUser,
    setErrorMessage,
    setFromPath,
    setUserGuid,
    isTokenExpired,
    isConfiguredUser,
    setAuthenticated,
    getUserInfoFromGuid,
    setUserCustomers,
    getUsersCustomers,
    setSelectedCustomer: wrappedSetSelectedCustomer,
    updateUser,
    updateCustomer,
    setLoggedOutLocation,
    getInternalUserPermissions,
    loggedOutLocation,
    authenticated,
    errorMessage,
    loadingLogin,
    fromPath,
    userGuid,
    userCustomers,
    selectedCustomer,
    trustedInternalRole,
  };

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}
