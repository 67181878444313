import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React, {useContext} from "react";
import {CustomerContext} from "../../../providers/CustomerProvider";
import { OrderContext } from "../../../providers/OrderProvider";
import {SpecificOrderContext} from "../../../providers/SpecificOrderContext";

export type CancelShipmentDialogProps = {
    open: boolean;
    orderId: number;
    shipmentId: number;
    onClose: () => void;
    onSuccessfulCancel: (shipmentId: number) => void;
}

const CancelShipmentDialog = (props: CancelShipmentDialogProps) => {
    const { open, onClose, onSuccessfulCancel } = props;
    const { selectedShipmentForAdd, setSelectedShipmentForAdd } = useContext(OrderContext);
    const { parentCustomerName } = useContext(CustomerContext);
    const { setMainToastMessage, setMainToastSeverity, setMainToastOpen } = useContext(SpecificOrderContext);

    const makeDeleteRequest = () => {
        const customerName = parentCustomerName;
        fetch(`/api/orders/${props.orderId}/shipment/${props.shipmentId}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({customerName})
        }).then((res) => {
            if(res.ok) {
                res.json().then((data) => {
                    setSelectedShipmentForAdd({...selectedShipmentForAdd, uncommittedLineItemCount: data.uncommittedLineItemCount})
                });

                onSuccessfulCancel(props.shipmentId);
                onClose();
            } else {
                res.json().then((data) => {
                    setMainToastMessage(data.message ?? "An error occurred. Please reach out if the problem persists.");
                    setMainToastSeverity("error");
                    setMainToastOpen(true);

                    onClose();
                });

            }
        })
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>Cancel Shipment #{props.shipmentId}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                This will delete all items in the shipment and then it will remove the shipment. Is that what you want to do?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{marginBottom: 10, marginRight: 15}}>
                <Button style={{marginRight: 10}} onClick={onClose}>Go back</Button>
                <Button variant={"contained"} color={"secondary"} onClick={makeDeleteRequest}>Proceed</Button>
            </div>

        </DialogActions>
    </Dialog>
}

export default CancelShipmentDialog;