import React, {useContext} from 'react';
import {
    Alert,
    CardContent,
    CardHeader,
    Container,
    Typography
} from "@mui/material";
import {StylesContext} from "../../../../providers/StylesProvider";
import {useLocation} from "react-router-dom";
import {useHistory} from "react-router";
import {ShipmentConfirmation} from "./ShipmentConfirmation";
import {PaddedCard} from "../../../LoggedOut/PaddedCard";
import {formatter} from "../../../../constants/Constants";

interface NoPaymentCheckoutLocationState {
    data: ShipmentConfirmation;
}

const NoPaymentThankYouPage: React.FC = () => {
    const { navBarHeight } = useContext(StylesContext);
    const location = useLocation<NoPaymentCheckoutLocationState>();
    const history = useHistory();

    // Redirect to /order-management if location.state is null or undefined
    if (!location.state) {
        history.push('/order-management');
        return null;
    }

    const confirmation = location.state.data;

    const notesCard = () => {
        if (!confirmation.customerNote) {
            return <></>;
        }

        return (
            <PaddedCard style={{marginBottom: 20}}>
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <div>
                        <CardHeader title="Notes:"/>
                    </div>
                    <div style={{margin: 10, display: "flex", alignItems: "flex-start", justifyContent: "space-between"}}>
                        <Typography>{confirmation.customerNote}</Typography>
                    </div>
                </div>
            </PaddedCard>
        );
    };

    const format = (input: string | null): string => {
        if (input === null) {
            return "";
        }
        return input.replace(/\r\n/g, "<br>").replace(/\n/g, "<br>").replace(/\r/g, "<br>");
    };

    const renderShipments = () => {
        return confirmation.shipments.map((shipment, index) => (
            <tr key={index}>
                <td style={{borderRight: '1px solid black', borderBottom: "1px solid black", paddingLeft: 10}}>{shipment.orderId}-{shipment.id}</td>
                <td style={{borderRight: '1px solid black', borderBottom: "1px solid black", paddingLeft: 10}}>{shipment.landDate}</td>
                <td style={{borderRight: '1px solid black', borderBottom: "1px solid black", paddingLeft: 10}}
                    dangerouslySetInnerHTML={{__html: format(shipment.destination)}}></td>
                <td style={{
                    textAlign: "right",
                    borderBottom: "1px solid black",
                    paddingRight: 10
                }}>{formatter.format(shipment.total)}</td>
            </tr>
        ));
    };

    const shipmentsTable = () => {
        if (!confirmation.shipments || confirmation.shipments.length === 0) {
            return <></>;
        }

        return (
            <PaddedCard>
                <CardHeader title={"Committed Shipments:"}/>
                <CardContent>
                    <table style={{border: '1px solid black', borderCollapse: "collapse"}}>
                        <thead>
                        <tr>
                            <th style={{width: 150, borderRight: '1px solid black', borderBottom: "1px solid black"}}>Shipment ID</th>
                            <th style={{width: 150, borderRight: '1px solid black', borderBottom: "1px solid black"}}>Land Date</th>
                            <th style={{width: 250, borderRight: '1px solid black', borderBottom: "1px solid black"}}>Address</th>
                            <th style={{width: 150, borderBottom: "1px solid black"}}>Grand Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderShipments()}
                        </tbody>
                    </table>
                </CardContent>
            </PaddedCard>
        );
    };

    return (
        <Container>
            <div style={{paddingTop: navBarHeight, marginBottom: 30}}>
                <h1>Thank You For Your Order!</h1>

                <Alert severity="info">
                    You're good to go! Our team will reach out with a confirmation within one business day.
                </Alert>
                {notesCard()}
                {shipmentsTable()}
            </div>
            <div style={{margin: 50}}></div> {/* Spacer for mobile */}
        </Container>
    );
};

export default NoPaymentThankYouPage;