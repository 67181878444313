import {Box, Tab, Tabs, TextField, Tooltip} from "@mui/material";
import {CUSTOMER_NAME } from "../../../constants/Constants";
import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from "react";
import {ShipmentEO} from "./ShipmentEO";
import {SpecificOrderContext} from "../../../providers/SpecificOrderContext";
import useDateUtil from "../../../hooks/useDateUtil";
import {OrderContext} from "../../../providers/OrderProvider";
import DeliveriesTable from "./DeliveriesTable";
import useOrderAPI from "../../../hooks/useOrderAPI";
import SimpleAutoComplete from "../../guiComponents/SimpleAutoComplete";
import { JobLine } from "../../authComponents/registration/validators/jobLineApplication/JobLine";
import {CustomerContext} from "../../../providers/CustomerProvider";

export type OrderParams = {
    orderId: number,
    shipmentTotals: {[key: string]: number}
    removeLineItemsInDelivery: (deliveryId: number) => void,
    getMaxStatusForLineItemsInDelivery: (deliveryId: number) => string,
    setDeliveries: React.Dispatch<React.SetStateAction<ShipmentEO[]>>,
}

export type OrderEO = {
    [key: string]: any
    jobName: string,
    poNumber: string,
    customerName: string,
    jobLine: string,
    orderStatus: string,
    defaultShippingAddress?: string,
    defaultShippingDate?: string,
    onSiteContactName: string, //specified on the checkout page
    onSiteContactPhone: string, //specified on the checkout page
    useDefaultLogistics?: boolean, //optional for the checkout page
    deliveries: ShipmentEO[],
}

export const DEFAULT_ORDER_INFO: OrderEO = {
    jobName: "",
    poNumber: "",
    customerName: "",
    jobLine: "HOUSE ACCOUNT",
    orderStatus: "",
    onSiteContactName: "",
    onSiteContactPhone: "",
    defaultShippingAddress: "",
    deliveries: [],
}

const OrderInformation = (props: OrderParams) => {
    const { orderId, shipmentTotals, setDeliveries } = props;

    const { customerName, fetchJobLines } = useContext(CustomerContext);
    const { setMainToastMessage, setMainToastOpen, setMainToastSeverity } = useContext(SpecificOrderContext);
    const { updateOrderJobName } = useContext(OrderContext);
    const { asDate } = useDateUtil();
    const { getOrderDetails } = useOrderAPI();

    //local data
    const [orderInformation, setOrderInformation] = useState<OrderEO>(DEFAULT_ORDER_INFO);
    const [backupOrderInfo, setBackupOrderInfo] = useState<OrderEO>(DEFAULT_ORDER_INFO);
    const [jobLineOptions, setJobLineOptions] = useState<string[]>(["HOUSE ACCOUNT"]);

    const allDeliveriesInvoiced = orderInformation.deliveries.every((x) => x.status === "INVOICED");
    const generalTooltipMessage = allDeliveriesInvoiced ? "This field is disabled because all deliveries are invoiced." : "";
    const jobLineTooltip = orderInformation.orderStatus !== "QUOTE"  ? "This field is no longer editable. Please reach out to your sales rep with any concerns." : "A job line is a sub-account that is secured by a lien against the property.";

    //ui control
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const updateOrderInfo = (e: ChangeEvent<HTMLInputElement>) => {
        //this requires the element's name attribute to match the key in the OrderEO object
        setOrderInformation({...orderInformation, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        getOrderDetails(orderId)
            .then((data: OrderEO) => {
                setBackupOrderInfo(data);
                setOrderInformation(data);
                setDeliveries(data.deliveries); //for the order header on the specific order page so that we can control the checkout button's state properly.
            });
    }, [getOrderDetails, orderId, setDeliveries]);

    useEffect(() => {
        if(!customerName || !fetchJobLines) {
            return
        }

        fetchJobLines(customerName)
            .then((data: JobLine[]) => {
                const jobNames = data.map((x: JobLine) => x.jobName.toUpperCase());

                if(!jobNames.includes("HOUSE ACCOUNT")) {
                   jobNames.push("HOUSE ACCOUNT");
                }

                setJobLineOptions(jobNames);
            })
    }, [customerName, fetchJobLines]);

    const requestBackendUpdate = useCallback(() => {
        const checkpoint = {...orderInformation};

        if(orderInformation === backupOrderInfo || orderInformation === DEFAULT_ORDER_INFO) {
            return;
        }

        fetch(`/api/orders/${orderId}/details`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
            body: JSON.stringify({...checkpoint, customerName: localStorage.getItem(CUSTOMER_NAME)})
        }).then(response => {
            if (!response.ok) {
                setMainToastMessage("Failed to update order information");
                setMainToastSeverity("error");
                setMainToastOpen(true);
                setOrderInformation(backupOrderInfo);
            } else {
                if(backupOrderInfo.jobName !== checkpoint.jobName) {
                    updateOrderJobName(orderId, checkpoint.jobName);
                }

                if(backupOrderInfo.jobLine !== checkpoint.jobLine) {
                    response.json().then((data: any) => {
                        //if we change the job line, then the backend might update the default shipping address
                        if(data.defaultShippingAddress) {
                            setOrderInformation({...checkpoint, defaultShippingAddress: data.defaultShippingAddress});
                        }
                    });
                }

                setBackupOrderInfo(checkpoint);
            }
        })
    }, [orderInformation, backupOrderInfo, orderId, updateOrderJobName, setMainToastMessage, setMainToastSeverity, setMainToastOpen]);

    const handleShipmentCanceled = useCallback((shipmentId: number) => {
        const updatedDeliveries = orderInformation.deliveries.filter((x) => x.id !== shipmentId);
        setOrderInformation({...orderInformation, deliveries: updatedDeliveries});

        props.removeLineItemsInDelivery(shipmentId);
    }, [orderInformation, props]);

    const getAugmentedJobLineItems = (orderInfo: OrderEO) => {
        if(jobLineOptions.includes(orderInfo.jobLine)) {
            return jobLineOptions;
        } else {
            return [orderInfo.jobLine, ...jobLineOptions];
        }
    }

    const renderOrderView = ()=> {
        return <>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="shipment tabs">
                <Tab label="Job Information" />
                <Tab label="Delivery Information" />
            </Tabs>
            <Box p={1}>
                {tabIndex === 0 && (
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div style={{display: "flex", width: "66%", flexDirection: "column"}}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <Tooltip title={jobLineTooltip}>
                                    <div style={{width: "67%"}}>
                                        <SimpleAutoComplete id={"job-line-selector"}
                                                            label={"Job Line"}
                                                            options={getAugmentedJobLineItems(orderInformation)}
                                                            value={orderInformation.jobLine}
                                                            setValue={(newValue: string) => {
                                                                const newJobLine = newValue ? newValue : "HOUSE ACCOUNT";

                                                                const newOrderInfo = {...orderInformation, jobLine: newJobLine};
                                                                setOrderInformation(newOrderInfo);
                                                            }}
                                                            onBlur={() => requestBackendUpdate()}
                                                            style={{}}
                                                            disabled={orderInformation.orderStatus !== "QUOTE"}
                                                            outlined={false}
                                        />
                                    </div>

                                </Tooltip>
                                <Tooltip title={generalTooltipMessage}>
                                    <div style={{width: "30%"}}>
                                        <TextField
                                            label="Default Land Date"
                                            name="defaultShippingDate"
                                            type="date"
                                            variant={"standard"}
                                            disabled={allDeliveriesInvoiced}
                                            value={asDate(orderInformation.defaultShippingDate) || ""}
                                            onChange={updateOrderInfo}
                                            onBlur={() => requestBackendUpdate()}
                                            fullWidth
                                            InputLabelProps={{shrink: true}}
                                            inputProps={{maxLength: 225}}
                                        />
                                    </div>
                                </Tooltip>
                            </div>

                            <div style={{display: "flex", justifyContent: "space-between", marginTop: 20}}>
                                <TextField
                                    variant="standard"
                                    autoFocus
                                    fullWidth
                                    name="jobName"
                                    label="Job Name"
                                    style={{width: "67%"}}
                                    disabled={allDeliveriesInvoiced}
                                    onChange={updateOrderInfo}
                                    onBlur={() => requestBackendUpdate()}
                                    value={orderInformation.jobName ?? ""}
                                    inputProps={{maxLength: 50}}
                                />
                                <Tooltip title={generalTooltipMessage}>
                                    <TextField
                                        variant="standard"
                                        name="poNumber"
                                        label="PO Number"
                                        disabled={allDeliveriesInvoiced}
                                        style={{width: "30%"}}
                                        onChange={updateOrderInfo}
                                        onBlur={() => requestBackendUpdate()}
                                        value={orderInformation.poNumber ?? ""}
                                        inputProps={{maxLength: 25}}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{display: "flex", width: "31%", flexDirection: "column"}}>
                            <Tooltip title={generalTooltipMessage}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name="defaultShippingAddress"
                                    label="Default Shipping Address"
                                    disabled={allDeliveriesInvoiced}
                                    value={orderInformation?.defaultShippingAddress ?? ""}
                                    onChange={updateOrderInfo}
                                    onBlur={() => requestBackendUpdate()}
                                    multiline
                                    rows={4}
                                />
                            </Tooltip>
                        </div>
                    </div>
                )}
                {tabIndex === 1 && (
                    <DeliveriesTable
                        orderId={orderId}
                        deliveries={orderInformation.deliveries}
                        shipmentTotals={shipmentTotals}
                        onShipmentCanceled={handleShipmentCanceled}
                        maxStatusForLineItemsInDelivery={props.getMaxStatusForLineItemsInDelivery}
                    />
                )}
            </Box>
        </>
    }

    return renderOrderView();
}

export default OrderInformation;