import { Menu, Button,MenuItem} from "@mui/material"
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";


const PREFIX = 'ButtonWithMenu';
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  navDisplayFlex: `${PREFIX}-navDisplayFlex`,
  navbarDisplayFlex: `${PREFIX}-navbarDisplayFlex`,
  menuButton: `${PREFIX}-menuButton`,
  lowerCaseMenuButton: `${PREFIX}-lowerCaseMenuButton`,
  homeButton: `${PREFIX}-homeButton`,
  availButton: `${PREFIX}-availButton`,
  appBarColor: `${PREFIX}-appBarColor`,
  headerText: `${PREFIX}-headerText`,
  subHeaderText: `${PREFIX}-subHeaderText`,
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
  formControl: `${PREFIX}-formControl`
};
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.lowerCaseMenuButton}`]: {
    textAlign: "center",
    textTransform: "none",
    fontSize: 9,
    display: "flex",
    flexDirection: "column"
  },
  [`& .${classes.headerText}`]: {
    whiteSpace: "nowrap",
    fontSize: ".9rem"
  },
  [`& .${classes.subHeaderText}`]: {
    whiteSpace: "nowrap",
    fontSize: ".6rem"
  }
}));
type ButtonWithMenuProps= {
 header: string
 subHeader: string
 menuItems: menuItem[]
}
type menuItem = {
    label: string,
    path: string,
    onClick?: () => void
}
export const ButtonWithMenu = (props: ButtonWithMenuProps) => {
const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
const open = Boolean(anchorEl);
const {header, subHeader, menuItems} = props
const history = useHistory()
const handleMenuClick = (path: string) => {
    history.push(path)
    handleClose()
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    const withSubHeader = () => (
        <div style={{ marginLeft: 5, marginRight: 5, display: "flex", flexDirection: "column" }}>
            <div style={{ whiteSpace: "nowrap", fontSize: ".9rem" }}>{header}</div>
            <div style={{ whiteSpace: "nowrap", fontSize: ".6rem" }}>{subHeader}</div>
        </div>
    );

    const withoutSubHeader = () => {
        return <div style={{ marginLeft: 5, marginRight: 5, display: "flex", flexDirection: "column", cursor: "pointer", padding: "6px 16px", fontSize: "1rem", fontWeight: 500, lineHeight: 1.75, letterSpacing: "0.02857em" }}>
            <div style={{ whiteSpace: "nowrap" }}>{header}</div>
        </div>
    }

    return (
        <Root>
            <Button color="inherit"
                    className={classes.lowerCaseMenuButton}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    disableRipple
                fullWidth
            >
                {subHeader ? withSubHeader() : withoutSubHeader()}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {menuItems.map((x:menuItem, index: number)=>{
                    return(
                        <MenuItem key={index} onClick={(e) => {
                            e.preventDefault();

                            if(x.onClick) {
                                x.onClick()
                            } else {
                                handleMenuClick(x.path)
                            }
                        }}>
                            <a href={x.path} style={{textDecoration: "none", color: "black", width: "100%"}}>
                                {x.label}
                            </a>
                        </MenuItem>
                    )
                })}
            </Menu>
        </Root>
)
}