export type AddressType = {
    region?: string,
    postalCode?: string,
    streetAddress?: string,
    country?: string,
    city?: string
}

export const NEW_ADDRESS =  {
    streetAddress: "",
    city: "",
    region: "",
    postalCode: "",
    country: "US"
} as AddressType


export const TYPE_CARD = "card";
export const TYPE_BANK_ACCOUNT = "bank-account";
export const TYPE_TERMS_ACCOUNT = "terms-account";

export interface PaymentMethod {
    type: string;
    id: string;
    warnings: string;
    errors: string;
    nickname: string;
    isDefault: boolean;
}

export type CardWithNickname = PaymentMethod & {
    cardType: string,
    expYear: string, 
    expMonth: string,
    name: string,
    cvc: string, 
    number: string,
    address: AddressType
}

export type BankAccountWithNickname = PaymentMethod & {
    name: string,
    accountNumber: string,
    inputType: string,
    phone: string,
    accountType: string,
    routingNumber: string,
}