import React, {useContext, useEffect, useState} from 'react';
import {Container} from '@mui/material';
import AccountBalanceCard from '../guiComponents/payments/AccountBalanceCard';
import {
    NEW_BANK_ACCOUNT,
    NEW_CARD,
    PAY_ACCOUNT_BALANCE,
    PAY_OTHER_AMOUNT,
    PAY_SPECIFIC_INVOICES
} from "../guiComponents/payments/PaymentCardConstants";
import {StylesContext} from "../../providers/StylesProvider";
import NewCreditCard from "../guiComponents/payments/NewCreditCard";
import AccountsReceivableInfo, {RACHEL_SHOCKEY} from "../guiComponents/payments/AccountsReceivableSpecialist";
import PaymentSummaryCard from "../guiComponents/payments/PaymentSummaryCard";
import PayOtherAmountCard from "../guiComponents/payments/PayOtherAmountCard";
import PaySpecificInvoicesCard, {
    AccountEvent,
    TYPE_CREDIT_MEMO,
    TYPE_INVOICE
} from "../guiComponents/payments/PaySpecificInvoicesCard";
import {
    BankAccountWithNickname,
    CardWithNickname,
    PaymentMethod,
} from "../../types/CardDataType";
import {CustomerContext} from "../../providers/CustomerProvider";
import {AuthContext} from "../../providers/AuthProvider";
import {useHistory} from "react-router";
import {PaymentReceipt} from "../guiComponents/payments/ThankYouPage";
import {useLocation} from "react-router-dom";
import UnsyncedPaymentsAlert from "../guiComponents/payments/PaymentSyncWarning";
import NewBankAccount from "../guiComponents/payments/NewBankAccount";
import PaymentOptionRadioButtons from "../guiComponents/payments/PaymentOptionRadioButtons";
import PaymentMethodDropDown from "../guiComponents/payments/PaymentMethodDropDown";
import {PaddedCard} from "../LoggedOut/PaddedCard";
import Alert from "@mui/material/Alert";
import usePaymentsAPI from "../../hooks/usePaymentsAPI";


const PaymentPage: React.FC = () => {
    //High level flow
    const [paymentOption, setPaymentOption] = useState<string>(PAY_ACCOUNT_BALANCE);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({...NEW_CARD, id: "Loading"});

    //state to use when submitting the payment - farmed out to the children
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [selectedAccountEvents, setSelectedAccountEvents] = useState<AccountEvent[]>([]);
    const [accountTotalDue, setAccountTotalDue  ] = useState<number>(0);
    const [applyToOldestFirst, setApplyToOldestFirst] = useState<boolean>(true);
    const [savePayMethod, setSavePayMethod] = useState<boolean>(false);

    //local state
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [savingCard, setSavingCard] = useState<boolean>(false);
    const [savingPayment, setSavingPayment] = useState<boolean>(false);
    const [defaultsInitialized, setDefaultsInitialized] = useState<boolean>(false);

    const { makePaymentRequest } = usePaymentsAPI();
    const { navBarHeight, isDesktop } = useContext(StylesContext);
    const { savePaymentMethod } = useContext(CustomerContext);
    const { isConfiguredUser } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const hint = queryParams.get('hint');
    const configuredUser = isConfiguredUser ? isConfiguredUser() : false; //if the isConfiguredUser function is not defined, assume the user is not configured

    const defaultStateBasedOn = (hint: string) => {
        if(!defaultsInitialized) {
            setPaymentOption(PAY_SPECIFIC_INVOICES);
            setDefaultsInitialized(true);
        }
    }

    if (hint) {
        defaultStateBasedOn(hint);
    }

    useEffect(() => {
        if(paymentOption !== PAY_SPECIFIC_INVOICES) {
            setSelectedAccountEvents([]); //clear selected invoices when user changes payment option
        }

        if(paymentOption === PAY_ACCOUNT_BALANCE) {
            setPaymentAmount(accountTotalDue);
        } else {
            setPaymentAmount(0); //clear amount selected until user makes a choice
        }

        if(paymentOption === PAY_SPECIFIC_INVOICES) {
            setApplyToOldestFirst(false);
        } else {
            setApplyToOldestFirst(true);
        }
    }, [paymentOption, accountTotalDue]); //accountTotalDue is in here so that we run this logic after the AccountBalanceCard has loaded

    useEffect(() => {
        if(paymentMethod.id !== NEW_CARD.id) {
            setSavePayMethod(false);
        }
    }, [paymentMethod.id]);

    const handleSubmit = (recaptchaToken: string, totalAmount: number, processingFee: number, paymentNotes: string) => {
        setSavingPayment(true);

        makePaymentRequest(paymentMethod, recaptchaToken, totalAmount, processingFee, paymentNotes, applyToOldestFirst, selectedAccountEvents, setErrorMessage)
            .then((data) => {
                //on success, redirect to thank you page
                if(data as PaymentReceipt) {
                    history.push({
                        pathname: '/payments/thank-you',
                        state: { data: data as PaymentReceipt }
                    });
                }
            })
            .finally(() => {
                setSavingPayment(false);
            });

        if(savePayMethod && savePaymentMethod) {
            setSavingCard(true);
            savePaymentMethod(paymentMethod)
                .then(() => {
                    setSavingCard(false);
                });
        }
    }

    const getSubtotal = () => {
        if(paymentOption !== PAY_SPECIFIC_INVOICES) {
            return paymentAmount;
        } else {
            return selectedAccountEvents.filter((x) => x.type === TYPE_INVOICE)
                                        .reduce((acc, x) => acc + x.balanceRemaining, 0);
        }
    }

    const getCreditMemoTotal = () => {
        if (paymentOption !== PAY_SPECIFIC_INVOICES) {
            return 0;
        } else {
            return selectedAccountEvents.filter((x) => x.type === TYPE_CREDIT_MEMO)
                                        .reduce((acc, x) => acc + x.balanceRemaining, 0)  * -1;
        }
    }

    const renderDesktop = () => {
        return (
            <Container>
                <div style={{paddingTop: navBarHeight, marginBottom: 30}}>
                    <AccountBalanceCard showPaymentLink={false} setAccountTotalDue={setAccountTotalDue}/>

                    <UnsyncedPaymentsAlert />

                    <div style={{display: "flex", flexDirection: "row", alignItems: "stretch"}}>
                        <div style={{width: "50%"}}>
                            <PaddedCard style={{ height: "90%"}}>
                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginLeft: "10px", marginRight: "10px", marginBottom: "10px"}}>
                                    <PaymentOptionRadioButtons paymentOption={paymentOption} setPaymentOption={setPaymentOption} accountTotalDue={accountTotalDue} />
                                    <PaymentMethodDropDown paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
                                </div>
                            </PaddedCard>
                        </div>
                        <div style={{width: "50%"}}>
                            <AccountsReceivableInfo arSpecialist={RACHEL_SHOCKEY} title={"Need Help? Ask Me!"}/>
                        </div>
                    </div>

                    {paymentOption === PAY_OTHER_AMOUNT ? <PayOtherAmountCard paymentAmount={paymentAmount}
                                                                              setPaymentAmount={setPaymentAmount}
                                                                              applyToOldestFirst={applyToOldestFirst}
                                                                              setApplyToOldestFirst={setApplyToOldestFirst} /> : ""}

                    {paymentOption === PAY_SPECIFIC_INVOICES ? <PaySpecificInvoicesCard paymentAmount={paymentAmount}
                                                                                        setSelectedAccountEvents={setSelectedAccountEvents}
                                                                                        selectedInvoiceHint={hint} /> : ""}

                    {paymentMethod.id === NEW_CARD.id ? <NewCreditCard setPaymentMethod={setPaymentMethod}
                                                                       paymentMethod={paymentMethod as CardWithNickname}
                                                                       saveCard={savePayMethod}
                                                                       setSaveCard={setSavePayMethod} /> : ""}

                    {paymentMethod.id === NEW_BANK_ACCOUNT.id ? <NewBankAccount setPaymentMethod={setPaymentMethod}
                                                                       paymentMethod={paymentMethod as BankAccountWithNickname}
                                                                       saveAccount={savePayMethod}
                                                                       setSaveAccount={setSavePayMethod} /> : ""}

                    <PaymentSummaryCard subtotal={getSubtotal()}
                                        credits={getCreditMemoTotal()}
                                        paymentMethod={paymentMethod}
                                        submitPayment={handleSubmit}
                                        errorMessage={errorMessage}
                                        setErrorMessage={setErrorMessage}
                                        loading={savingPayment || savingCard} />
                </div>
            </Container>
        );
    }

    const renderMobile = () => {
        return (
                <div style={{paddingTop: navBarHeight, marginBottom: 30}}>
                    <AccountBalanceCard showPaymentLink={false} setAccountTotalDue={setAccountTotalDue}/>

                    <UnsyncedPaymentsAlert />

                    <div style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>

                        <AccountsReceivableInfo arSpecialist={RACHEL_SHOCKEY} title={"Need Help? Ask Me!"}/>

                        <PaddedCard style={{ height: "90%", marginTop: 20}}>
                            <PaymentOptionRadioButtons paymentOption={paymentOption} setPaymentOption={setPaymentOption} accountTotalDue={accountTotalDue} />
                        </PaddedCard>

                        {paymentOption === PAY_OTHER_AMOUNT ? <PayOtherAmountCard paymentAmount={paymentAmount}
                                                                                  setPaymentAmount={setPaymentAmount}
                                                                                  applyToOldestFirst={applyToOldestFirst}
                                                                                  setApplyToOldestFirst={setApplyToOldestFirst} /> : ""}

                        {paymentOption === PAY_SPECIFIC_INVOICES ? <PaySpecificInvoicesCard paymentAmount={paymentAmount}
                                                                                            // setPaymentAmount={setPaymentAmount}
                                                                                            setSelectedAccountEvents={setSelectedAccountEvents}
                                                                                            selectedInvoiceHint={hint} /> : ""}

                        <PaddedCard style={{ height: "90%"}}>
                            <PaymentMethodDropDown paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
                        </PaddedCard>

                        {paymentMethod.id === NEW_CARD.id ? <NewCreditCard setPaymentMethod={setPaymentMethod}
                                                                           paymentMethod={paymentMethod as CardWithNickname}
                                                                           saveCard={savePayMethod}
                                                                           setSaveCard={setSavePayMethod} /> : ""}

                        {paymentMethod.id === NEW_BANK_ACCOUNT.id ? <NewBankAccount setPaymentMethod={setPaymentMethod}
                                                                                    paymentMethod={paymentMethod as BankAccountWithNickname}
                                                                                    saveAccount={savePayMethod}
                                                                                    setSaveAccount={setSavePayMethod} /> : ""}
                    </div>

                    <PaymentSummaryCard subtotal={getSubtotal()}
                                        credits={getCreditMemoTotal()}
                                        paymentMethod={paymentMethod}
                                        submitPayment={handleSubmit}
                                        errorMessage={errorMessage}
                                        setErrorMessage={setErrorMessage}
                                        loading={savingPayment || savingCard} />
                </div>
        );
    }

    if(!configuredUser) {
        return <Container >
                <div style={{paddingTop: navBarHeight}}>
                    <h1>Payments</h1>
                    <Alert severity={"warning"}>We're double checking a few things on our end and
                        will link you to the correct customer shortly. Please check back tomorrow to make a payment.</Alert>
                    <br/>
                </div>
            </Container>;
    } else if (isDesktop) {
        return renderDesktop();
    } else {
        return renderMobile();
    }
};

export default PaymentPage;