import { CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { red } from '@mui/material/colors';
import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../providers/AuthProvider";
import {useHistory} from "react-router";
import {StylesContext} from "../../../providers/StylesProvider";


interface AccountBalance {
    current: number
    oneToThirty: number;
    thirtyOneToSixty: number;
    sixtyOneToNinety: number;
    ninetyOnePlus: number;
    totalBalance: number;
    pastDue: number;
}

const DEFAULT_ACCOUNT_BALANCE: AccountBalance = {
    current: 0,
    oneToThirty: 0,
    thirtyOneToSixty: 0,
    sixtyOneToNinety: 0,
    ninetyOnePlus: 0,
    totalBalance: 0,
    pastDue: 0
}

type AccountBalanceCardProps = {
    showPaymentLink: boolean;
    setAccountTotalDue?: (amount: number) => void; //? denotes optional
}

const AccountBalanceCard: React.FC<AccountBalanceCardProps> = (props: AccountBalanceCardProps) => {
    const [accountBalance, setAccountBalance] = useState<AccountBalance>(DEFAULT_ACCOUNT_BALANCE)
    const [accountCredits, setAccountCredits] = useState<number | undefined>(undefined)

    //navigation
    const history = useHistory();

    //GUI Control
    const isDesktop = useContext(StylesContext).isDesktop;
    const auth = useContext(AuthContext);
    const terms = auth.getSelectedCustomerTerms!();
    const customerName = auth.user?.customer?.fullName ?? "";
    const hasPastDue = accountBalance.pastDue > 0;

    useEffect(() => {
        loadAccountBalance(customerName);
    }, [customerName]);

    useEffect(() => {
        if(props.setAccountTotalDue && accountBalance.totalBalance && accountCredits !== undefined) {
            props.setAccountTotalDue(accountBalance.totalBalance - accountCredits);
        }
    }, [accountBalance, accountCredits, props]);

    const loadAccountBalance = async (customerName: string) => {
        const encodedCustomerName = encodeURIComponent(customerName);

        fetch(`/api/customer/${encodedCustomerName}/balance`, {
            headers: {
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        })
        .then((res) => {
            if (!res.ok) {
                throw new Error("Network response was not ok");
            }
            return res.json();
        })
        .then((data) => {
            setAccountBalance(data);
        })
        .catch((error) => {
            console.error(
                "There has been a problem with your fetch operation:",
                error
            );
        });

        fetch(`/api/customer/${encodedCustomerName}/credits`, {
            headers: {
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        }).then((res) => {
            if (!res.ok) {
                throw new Error("Network response was not ok");
            }
            return res.json();
        }).then((data) => {
            setAccountCredits(data.totalCredits);
        }).catch((error) => {
            console.error(
                "There has been a problem with your fetch operation:",
                error
            );
        });
    }

    const asUSD = (value: number | undefined) => {
        if (!value) {
            return "$0.00";
        }
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    }

    const gotoPayments = () => {
        return () => {
            history.push("/payments")
        }
    }

    const payNowButton = () => {
        return (<Button style={{marginBottom: 10, marginLeft: 5}} variant="contained" color="primary" onClick={gotoPayments()}>
                    Pay Now
                </Button>)
    }

    const renderDesktop = () => {
        return (
            <PaddedCard style={{height: 250}}>
                <CardContent>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <Typography style={{marginBottom: 10}} variant="h5" component="div" hidden={!hasPastDue} color={hasPastDue ? red[500] : 'inherit'}>
                                Past Due: {asUSD(accountBalance.pastDue)}
                            </Typography>
                            <Typography variant="h6" component="div" style={{fontSize: hasPastDue ? 'inherit' : '1.5em'}}>
                                Total Balance: {asUSD(accountBalance.totalBalance)}
                            </Typography>
                            <Typography variant="h6" component="div" style={{fontSize: 'inherit'}}>
                                Total Credits: {asUSD(accountCredits)}
                            </Typography>
                        </div>
                        <div>
                            {props.showPaymentLink ? payNowButton() : ""}
                            <Typography variant="body1" component="div" style={{fontSize: props.showPaymentLink ? 'inherit' : '1.5em'}}>
                                Terms: {terms}
                            </Typography>
                        </div>
                    </div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Current</TableCell>
                                    <TableCell>1 - 30 days </TableCell>
                                    <TableCell>31 - 60 days</TableCell>
                                    <TableCell>61 - 90 days </TableCell>
                                    <TableCell>90+ days</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{asUSD(accountBalance.current)}</TableCell>
                                    <TableCell>{asUSD(accountBalance.oneToThirty)}</TableCell>
                                    <TableCell>{asUSD(accountBalance.thirtyOneToSixty)}</TableCell>
                                    <TableCell>{asUSD(accountBalance.sixtyOneToNinety)}</TableCell>
                                    <TableCell>{asUSD(accountBalance.ninetyOnePlus)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </PaddedCard>
        );
    }

    const renderMobile = () => {
        return (
            <PaddedCard style={{height: "90%"}}>
                <CardContent>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <Typography style={{marginBottom: 10}} variant="h5" component="div" hidden={!hasPastDue} color={hasPastDue ? red[500] : 'inherit'}>
                                Past Due: {asUSD(accountBalance.pastDue)}
                            </Typography>
                            <Typography variant="h6" component="div" style={{fontSize: hasPastDue ? 'inherit' : '1.5em'}}>
                                Total Balance: {asUSD(accountBalance.totalBalance)}
                            </Typography>
                            <Typography variant="h6" component="div" style={{fontSize: 'inherit'}}>
                                Total Credits: {asUSD(accountCredits)}
                            </Typography>
                            <Typography variant="h6" component="div" style={{fontSize: 'inherit' }}>
                                Terms: {terms}
                            </Typography>
                        </div>
                        <div>
                            {props.showPaymentLink ? payNowButton() : ""}
                        </div>
                    </div>

                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Current</TableCell>
                                    <TableCell>{asUSD(accountBalance.current)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>1 - 30 days</TableCell>
                                    <TableCell>{asUSD(accountBalance.oneToThirty)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>31 - 60 days</TableCell>
                                    <TableCell>{asUSD(accountBalance.thirtyOneToSixty)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>61 - 90 days</TableCell>
                                    <TableCell>{asUSD(accountBalance.sixtyOneToNinety)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>90+ days</TableCell>
                                    <TableCell>{asUSD(accountBalance.ninetyOnePlus)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </PaddedCard>
        );
    }


    return <>
        {isDesktop ? renderDesktop() : renderMobile()}
    </>
};

export default AccountBalanceCard;