import React, {useCallback, useEffect, useState, useMemo, useContext} from "react";
import { OrderForAdd, NEW_ORDER } from "../types/OrderForAdd";
import useOrderAPI from "../hooks/useOrderAPI";
import {CustomerContext} from "./CustomerProvider";
import {AuthContext} from "./AuthProvider";

export type OrderPropertiesType = {
    allAddToOptions: any[];
    selectedShipmentForAdd: OrderForAdd;
    setSelectedShipmentForAdd: React.Dispatch<React.SetStateAction<OrderForAdd>>;
    removeOrderFromAddToOptions: (orderId: number) => void;
    setAllAddToOptions: React.Dispatch<React.SetStateAction<OrderForAdd[]>>;
    updateOrderJobName: (orderId: number, jobName: string) => void;
};

export const OrderContext = React.createContext<OrderPropertiesType>({
    allAddToOptions: [],
    setAllAddToOptions: () => { },
    selectedShipmentForAdd: NEW_ORDER,
    setSelectedShipmentForAdd: () => { },
    updateOrderJobName: () => { },
    removeOrderFromAddToOptions: () => { },
});

export const OrderProvider = (props: any) => {
    const {customerName} = useContext(CustomerContext);
    const { isConfiguredUser } = useContext(AuthContext);

    const [allAddToOptions, setAllAddToOptions] = useState<OrderForAdd[]>([NEW_ORDER]);
    const [selectedShipmentForAdd, setSelectedShipmentForAdd] = useState<OrderForAdd>(NEW_ORDER);
    const orderAPI = useOrderAPI();


    useEffect(() => {
        if(!customerName || !orderAPI || (isConfiguredUser && !isConfiguredUser())) {
            return; //wait for dependencies before making request
        }

        let encodedCustomerName = encodeURIComponent(customerName as string);
        orderAPI.getOpenOrders(encodedCustomerName, setAllAddToOptions, setSelectedShipmentForAdd);
    }, [customerName, orderAPI, isConfiguredUser]);

    const updateOrderJobName = useCallback((orderId: number, jobName: string) => {
        const storedOrder = allAddToOptions.find(e => e.orderId === orderId);

        if(storedOrder) {
            storedOrder.orderName = jobName;
            storedOrder.label = `${storedOrder.orderId}: ${storedOrder.orderName}`;
        }
    }, [allAddToOptions]);

    const removeOrderFromAddToOptions = useCallback((orderId: number) => {
        setAllAddToOptions(allAddToOptions.filter(e => e.orderId !== orderId));
        setSelectedShipmentForAdd(NEW_ORDER);
    }, [allAddToOptions, setAllAddToOptions]);

    //end context
    const orderOptions = useMemo(() => {
        return {
            setSelectedShipmentForAdd,
            selectedShipmentForAdd,
            removeOrderFromAddToOptions,
            allAddToOptions,
            setAllAddToOptions,
            updateOrderJobName,
        };
    }, [setSelectedShipmentForAdd, selectedShipmentForAdd, allAddToOptions, updateOrderJobName, removeOrderFromAddToOptions]);

    return (
        <OrderContext.Provider value={orderOptions}>
            {props.children}
        </OrderContext.Provider>
    );
};