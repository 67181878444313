import {Button, Tooltip} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import BetaTag from "../../guiComponents/BetaTag";
import {datadogLogs} from "@datadog/browser-logs";
import {CustomerContext} from "../../../providers/CustomerProvider";
import {PictureAsPdf} from "@mui/icons-material";
import {CUSTOMER_NAME} from "../../../constants/Constants";
import usePDFDownloader from "../../../hooks/usePDFDownloader";

export type ShipmentHeaderProps = {
    orderId: string;
    shipmentId: string;
}

const ShipmentHeader = (props: ShipmentHeaderProps) => {
    const { orderId, shipmentId } = props;
    const { customerName } = useContext(CustomerContext);
    const { downloadPDF } = usePDFDownloader();
    const history = useHistory();

    const [paymentStatus, setPaymentStatus] = useState(""); //valid options: NOT_INVOICED, SYNCING, BALANCE_DUE, PAID

    const getPaymentStatus = useCallback(() => {
        if(!customerName) {
            return; //wait until deps load
        }

        const encodedCustomerName = encodeURIComponent(customerName as string);
        fetch(`/api/orders/${orderId}/shipment/${shipmentId}/payment-status?customerName=${encodedCustomerName}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: localStorage.getItem("token") ?? undefined,
            } as HeadersInit,
        })
        .then(response => {
            if(response.ok) {
                return response.json();
            } else {
                datadogLogs.logger.error("Failed to retrieve payment status for shipment", {orderId, shipmentId});
                return {status: ""}
            }
        })
        .then(data => {
            setPaymentStatus(data.status);
        });
    }, [orderId, shipmentId, customerName])


    const getTooltip = (paymentStatus: string) => {
        if(paymentStatus === "PAID") {
            return "This shipment has been paid for.";
        } else if (paymentStatus === "BALANCE_DUE") {
            return "";
        } else if (paymentStatus === "SYNCING") {
            return "This shipment is currently syncing with our accounting system. Please check back later.";
        } else if (paymentStatus === "NOT_INVOICED") {
            return "This shipment has not been invoiced. No balance is due.";
        } else {
            return "";
        }
    }

    const paymentTooltip = getTooltip(paymentStatus);
    const formattedId = orderId + "-" + shipmentId;

    useEffect(() => {
        getPaymentStatus();
    }, [getPaymentStatus]);

    const handlePDFButtonClick = () => {
        const encodedCustomerName = encodeURIComponent(localStorage.getItem(CUSTOMER_NAME) as string);
        const url = `/api/orders/${orderId}/shipment/${shipmentId}?customerName=${encodedCustomerName}`;
        const refId = `${orderId}-${shipmentId}`;

        downloadPDF(url, refId);
    }

    return <>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <h2>
                Shipment #{orderId}-{shipmentId} <BetaTag/>
            </h2>

            <div style={{alignContent: "center"}}>
                <Tooltip title={paymentTooltip} >
                    <span>
                        <Button style={{marginRight: 15}}
                                variant={"contained"}
                                color={"success"} disabled={paymentTooltip.length > 0}
                                onClick={() => history.push("/payments?hint=" + formattedId)}>
                            Make Payment
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title={""} >
                    <span>
                        <Button variant={"contained"} color="primary"  style={{marginRight: 15}} onClick={handlePDFButtonClick} startIcon={<PictureAsPdf />}>
                        Download PDF
                    </Button>
                    </span>
                </Tooltip>
                <Button variant={"contained"} onClick={() => history.push(`/order-management/order/${orderId}`)}>
                    Go to order
                </Button>
            </div>
        </div>
    </>
}

export default ShipmentHeader;