import {WebsiteAvailability, WebsiteAvailabilityGroup} from "../../types/ProductType";
import React from "react";
import {PaddedCard} from "./PaddedCard";
import {KeyValue} from "../KeyValue";
import { CardContent, CardHeader} from "@mui/material";
import {formatter, NO_PRICE_DISPLAY} from "../../constants/Constants";
import AddToOrderForm from "../LoggedIn/orders/AddToOrderForm";
import {StylesContext} from "../../providers/StylesProvider";

export type ProductInfoProps = {
    selectedProduct: WebsiteAvailabilityGroup;
    defaultBranch: string;
    isLoggedOUt: boolean;
    pageReady: boolean;
}

export const ProductInfo = (props: ProductInfoProps) => {
    const { isDesktop } = React.useContext(StylesContext);

    const product = props.selectedProduct;
    const localAvail = product.websiteItemAvailabilities.find((x: WebsiteAvailability) => x.companyName === props.defaultBranch)

    const conditionalRender = (label: string, value: string | number | undefined) => {
        if(value) {
            if(typeof value === "string") {
                const capitalized = value.charAt(0).toUpperCase() + value.slice(1);

                return KeyValue(label + " ", "" + capitalized);
            }

            return KeyValue(label + " ", "" + value);
        } else {
            return "";
        }
    }

    const renderPlantInfo = () => {
        return  <PaddedCard style={{width: isDesktop ? 550 : "auto"}}>
                    <CardHeader title="Product Information" />
                    <CardContent>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>
                                {KeyValue("Shopping At:", props.defaultBranch)}
                                {KeyValue("Local Quantity:", localAvail?.quantity ?? "0")}
                                {KeyValue("Shippable Quantity:", localAvail?.systemAvailability ?? "0")}
                                {KeyValue("In Production:", product.quantityOnPO ?? "0")}
                                {KeyValue("Price:", product.priceFloor ? formatter.format(product.priceFloor) : NO_PRICE_DISPLAY)}
                            </div>
                            {isDesktop && <AddToOrderForm product={product} defaultBranch={props.defaultBranch}/>}
                        </div>

                        <hr/>

                        {KeyValue("Common Name:", product?.commonName)}
                        {KeyValue("Latin Name:", product?.botanicalName)}
                        {conditionalRender("Category:", product?.category)}
                        {conditionalRender("Leaf Type: ", product?.leafType)}
                        {conditionalRender("Root ball:", product?.rootBall)}
                        {conditionalRender("Growth Rate: ", product?.growthRate)}
                        {conditionalRender("Min Zone: ", product?.zoneMin)}
                        {conditionalRender("Max Zone: ", product?.zoneMax)}
                        {conditionalRender("Mature Height (ft): ", product?.matureHeightInFeet)}
                        {conditionalRender("Mature Spread (ft): ", product?.matureSpreadInFeet)}
                        {conditionalRender("Bloom Start Month: ", product?.bloomStartDate)}
                        {conditionalRender("Bloom End Month: ", product?.bloomEndDate)}
                        {conditionalRender("Bloom Description: ", product?.bloomDesc)}
                        {conditionalRender("Sun: ", product?.sun)}
                        {conditionalRender("Water: ", product?.water)}
                        {conditionalRender("Maintenance: ", product?.maintenance)}
                        {conditionalRender("Suggested Use: ", product?.suggestedUse)}
                        {conditionalRender("Color: ", product?.color)}
                    </CardContent>
                </PaddedCard>
    }

    const renderProductCard = () => {
        return <>
            {renderPlantInfo()}
        </>
    }

    return props.pageReady ? renderProductCard() : <></>
}