import {Button, Tooltip} from "@mui/material";
import React, {useContext} from "react";
import {useHistory} from "react-router";
import CancelOrderDialog from "./CancelOrderDialog";
import BetaTag from "../../guiComponents/BetaTag";
import {OrderContext} from "../../../providers/OrderProvider";
import {PictureAsPdf} from "@mui/icons-material";
import {CUSTOMER_NAME} from "../../../constants/Constants";
import usePDFDownloader from "../../../hooks/usePDFDownloader";

export type OrderHeaderProps = {
    orderId: string;
    cancelDisabled: boolean;
    checkoutDisabled: boolean;
}

const OrderHeader = (props: OrderHeaderProps) => {
    const { orderId } = props;
    const { removeOrderFromAddToOptions } = useContext(OrderContext);
    const { downloadPDF } = usePDFDownloader();
    const history = useHistory();

    const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
    const cancelTooltip = props.cancelDisabled ? "This order can no longer be canceled from the website. Please work with your sales rep to cancel it." : "";
    const checkoutTooltip = props.checkoutDisabled ? "There are no open deliveries to commit. Thank you for your business!" : "";

    const handleOrderPDFButtonClick = () => {
        const encodedCustomerName = encodeURIComponent(localStorage.getItem(CUSTOMER_NAME) as string);
        const url = `/api/orders/${orderId}?customerName=${encodedCustomerName}`;
        const refId = `Estimate_${orderId}`;

        downloadPDF(url, refId);
    }

    return <>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <h2>
                Order #{orderId} <BetaTag/>
            </h2>

            <div style={{alignContent: "center"}}>
                <Tooltip title={cancelTooltip}>
                    <span>
                        <Button style={{background: "grey", marginRight: 15}} variant={"contained"}
                                disabled={props.cancelDisabled}
                                onClick={() => setCancelModalOpen(true)}>
                            Cancel Order
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title={""} >
                    <span>
                        <Button variant={"contained"} color="primary"  style={{marginRight: 15}} onClick={handleOrderPDFButtonClick} startIcon={<PictureAsPdf />}>
                        Generate Quote
                    </Button>
                    </span>
                </Tooltip>
                <Tooltip title={checkoutTooltip}>
                    <span>
                        <Button variant={"contained"}
                                color={"success"}
                                disabled={props.checkoutDisabled}
                                onClick={() => history.push(`/checkout/order/${orderId}`)}>
                        Checkout
                    </Button>
                    </span>
                </Tooltip>
            </div>

            <CancelOrderDialog orderId={Number(orderId)} open={cancelModalOpen}
                               onClose={() => setCancelModalOpen(false)}
                               onConfirm={() => {
                                   removeOrderFromAddToOptions(Number(orderId));
                                   history.push("/order-management")
                               }}/>
        </div>
    </>
}

export default OrderHeader;