import React, {useEffect, useState} from 'react';
import {
    Container,
    Paper,
    Typography,
    Box,
} from '@mui/material';
import { OrderContext } from '../../../providers/OrderProvider';
import {StylesContext} from "../../../providers/StylesProvider";
import OrderLineItemTable from "./OrderLineItemTable";
import {useParams, useLocation, useHistory} from "react-router";
import OrderInformation from "./OrderInformation";
import ShipmentInformation from "./ShipmentInformation";
import {SpecificOrderProvider} from '../../../providers/SpecificOrderContext';
import SpecificOrderSnackbar from "./SpecificOrderSnackbar";
import {OrderLineItemType} from "../../../types/OrderLineItemType";
import ShipmentHeader from "./ShipmentHeader";
import OrderHeader from "./OrderHeader";
import ShipmentEO from "./ShipmentEO";
import TreeSourceLoad from "../../Loaders/TreeSourceLoad";
import {CustomerContext} from "../../../providers/CustomerProvider";
import {AuthContext} from "../../../providers/AuthProvider";
import {Customer} from "../../../types/UserType";

export type OrderParams = {
    orderId: string,
    shipmentId: string,
}

const SpecificOrder = () => {
    const { navBarHeight, isDesktop } = React.useContext(StylesContext);
    const { userCustomers, user, setUser, setSelectedCustomer } = React.useContext(AuthContext);
    const { parentCustomerName, getParentCustomerName } = React.useContext(CustomerContext);
    const { allAddToOptions, selectedShipmentForAdd, setSelectedShipmentForAdd } = React.useContext(OrderContext);
    const history = useHistory();

    const { orderId, shipmentId } = useParams<OrderParams>()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isShipmentView = shipmentId !== undefined;

    //a sacrifice of encapsulation so that I can update in-memory state across direct children =/
    const [deliveries, setDeliveries] = useState<ShipmentEO[]>([]);
    const [orderLineItems, setOrderLineItems] = useState<OrderLineItemType[]>([]);
    const [shipmentTotals, setShipmentTotals] = useState<{[key: string]: number}>({});

    const removeLineItemsInDelivery = (deliveryId: number) => {
        setOrderLineItems(orderLineItems.filter((item) => item.deliveryId !== deliveryId));
    }

    //here's the thing... the order line items already have the max status for their logistical path.
    //When calculating if the cancel button should be enabled, we need to look at the max status for the logistical path
    //of each line item in the delivery
    const getMaxStatusForLineItems = (deliveryId: number | undefined) => {
        const statusToProgress: { [key: string]: number } =  {
            "OPEN": 1,
            "NO_AUTO_ADD": 2,
            "COMMITTED": 3,
            "PULLING": 4,
            "SHIPPED": 5,
            "INVOICED": 6,
        }

        var maxStatus = "OPEN";
        for(const item of orderLineItems) {
            if(item.deliveryId === deliveryId || deliveryId === undefined) {
                if(statusToProgress[item.status] > statusToProgress[maxStatus]) {
                    maxStatus = item.status;
                }
            }
        }

        return maxStatus;
    }

    const orderHasInProgressLineItems = () => {
        const maxStatus = getMaxStatusForLineItems(undefined);

        return maxStatus !== "OPEN" && maxStatus !== "NO_AUTO_ADD";
    }

    const hasUncommittedDeliveries = () => {
        return deliveries.some((delivery) => delivery.status === "OPEN" || delivery.status === "NO_AUTO_ADD");
    }

    //so we sometimes send links out with order ids, shipment ids, and customer names.
    //If a user follows one of these links we want to try and load that order for that customer name.
    //this method deals with setting us up to load the order (if we have permissions).
    const userAndCustomerProperlyInitialized = () => {
        const requestedCustomerName = queryParams.get("customerName") ?? "";
        const requestedParentCustomerName = getParentCustomerName!(requestedCustomerName ?? "");
        const lowerCaseRequestedParentCustomerName = requestedParentCustomerName.toLowerCase();

        const lowerCaseParentCustomerName = (parentCustomerName ?? "").toLowerCase();

        if(!requestedCustomerName) {
            //no customer name hint, no cares! proceed to load the order as normal
            return true;
        } else if(lowerCaseRequestedParentCustomerName === lowerCaseParentCustomerName) {
            //the customer name hint's parent matches the parent customer name, proceed to load the order as normal
            return true;
        } else if(userCustomers) {
            const matchingCustomer = userCustomers.find((customer: Customer) => {
                const lowerCaseParent = getParentCustomerName!(customer?.fullName ?? "").toLowerCase();

                return lowerCaseParent === lowerCaseRequestedParentCustomerName;
            });

            if(!matchingCustomer) {
                history.push("/not-found")
            }

            if(matchingCustomer && setUser && setSelectedCustomer) {
                setSelectedCustomer(matchingCustomer);
                let customerEdit = {
                    ...matchingCustomer,
                    customerName: matchingCustomer?.companyName,
                };
                setUser(user ? { ...user, customer: customerEdit } : null);
            }

            return false;
        }
    }

    useEffect(() => {
        const totals = orderLineItems.reduce((accumulator, item) => {
            const shipmentId = item.deliveryId;
            const total = item.sourceItemQuantity * item.effectivePrice;

            if (!accumulator[shipmentId]) {
                accumulator[shipmentId] = 0;
            }

            accumulator[shipmentId] += total;
            return accumulator;
        }, {} as { [key: string]: number });

        setShipmentTotals(totals);
    }, [orderLineItems, setShipmentTotals]);

    useEffect(() => {
        if (Number(orderId) !== selectedShipmentForAdd.orderId) {
            const candidate = allAddToOptions.find((order) => order.orderId === Number(orderId));

            if (candidate) {
                setSelectedShipmentForAdd(candidate);
            }
        }
    }, [orderId, setSelectedShipmentForAdd, selectedShipmentForAdd, allAddToOptions]);

    const renderSpecificOrder = () => {
        return (
            <SpecificOrderProvider>
                <Container style={{marginTop: navBarHeight}}>
                    {isShipmentView ? <ShipmentHeader orderId={orderId} shipmentId={shipmentId} />
                        : <OrderHeader orderId={orderId}
                                       cancelDisabled={orderHasInProgressLineItems()}
                                       checkoutDisabled={!hasUncommittedDeliveries()} />}

                    <Box mt={0}>
                        <Paper elevation={3} style={{padding: 16}}>
                            {isShipmentView
                                ? <ShipmentInformation orderId={Number(orderId)} shipmentId={Number(shipmentId)} shipmentTotals={shipmentTotals}/>
                                : <OrderInformation orderId={Number(orderId)}
                                                    shipmentTotals={shipmentTotals}
                                                    removeLineItemsInDelivery={removeLineItemsInDelivery}
                                                    getMaxStatusForLineItemsInDelivery={getMaxStatusForLineItems}
                                                    setDeliveries={setDeliveries}
                                />
                            }
                        </Paper>
                    </Box>
                    <Box mt={3}>
                        <Paper elevation={3} style={{padding: 16}}>
                            <Typography variant="h6">Line Items</Typography>
                            <OrderLineItemTable orderId={Number(orderId)}
                                                shipmentId={Number(shipmentId)}
                                                orderLineItems={orderLineItems}
                                                setOrderLineItems={setOrderLineItems}/>
                        </Paper>
                    </Box>
                    <SpecificOrderSnackbar/>
                    <div style={{margin: isDesktop ? 15 : 40}}></div>
                </Container>
            </SpecificOrderProvider>
        );
    }

    const renderLoading = () => {
        return (
            <Container style={{marginTop: navBarHeight}}>
                <TreeSourceLoad message="Loading..."/>
            </Container>
        );
    }

    if(userAndCustomerProperlyInitialized()) {
        return renderSpecificOrder()
    } else {
        return renderLoading()
    }

};

export default SpecificOrder;