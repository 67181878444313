import * as React from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {CustomerContext} from "../../providers/CustomerProvider";
import {useContext, useEffect, useState} from "react";
import {JobLine} from "../authComponents/registration/validators/jobLineApplication/JobLine";
import {AuthContext} from "../../providers/AuthProvider";



const columns: GridColDef[] = [
    { field: 'jobName', headerName: 'Name', flex: 7 },
    { field: 'terms', headerName: 'Terms', flex: 2 },
    { field: 'totalBalance', headerName: 'Total Balance', flex: 2 },
    { field: 'creditLimit', headerName: 'Credit Limit', flex: 2 },
];

const JobLineData = () => {
    //context
    const { fetchJobLines } = useContext(CustomerContext);
    const auth = useContext(AuthContext);

    //local state
    const [jobLines, setJobLines] = useState<JobLine[]>([]);
    const customerName = auth.user?.customer?.fullName ?? "";



    useEffect(() => {
        if(!customerName || !fetchJobLines) {
            return
        }

        fetchJobLines(customerName)
            .then((data) => {
                setJobLines(data);
            })
    }, [customerName, fetchJobLines]);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGridPro rows={jobLines} columns={columns} />
        </div>
    );
};

export default JobLineData;