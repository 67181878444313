export const passwordString: string = "Password";
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const CUSTOMER_NAME = "customerName";
export const ENTITY_NAME = "entityName";
export const SHOW_ABOUT_US_PAGE = "aboutUsPage";
export const USER_ROLES = [
  "Sales",
  "Owner",
  "Accounts Payable",
  "Accounts Receivable",
  "Landscaper",
  "Office",
  "General Manager",
  "Purchaser",
  "Home Owner",
  "Other",
];

export const DEFAULT_BRANCH = "Rupert Farm";

export const NO_PRICE_DISPLAY: string = "Call for price.";

export const LOGISTICS_WILL_CALL = "WILL_CALL";
export const LOGISTICS_YARD_SHIP = "YARD_SHIP"; // This will match their displayed price and it will give us a chance to find a "discount" for taking direct ship ;)